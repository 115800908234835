/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {createPortal} from "react-dom";
import {Modal} from "react-bootstrap";
import clsx from "clsx";
import IPConfig from "../../../store/IPConfig";
import {useNavigate} from "react-router-dom";

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalAddJob = ({show, handleClose}: Props) => {
  const [name, setName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [failMessage, setFailMessage] = useState('');
  const [validationError, setValidationError] = useState('');

  const apiUrl = IPConfig()
  const navigate = useNavigate();

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const clearFormAndMessages = () => {
    setName('');
    setSuccessMessage('');
    setFailMessage('');
    setValidationError('');
  };

  const validateInputs = () => {
    if (!name.trim()) {
      setValidationError("All fields are required.");
      return false;
    }
    setValidationError('');
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFailMessage('');
    setSuccessMessage('');
    setValidationError('');
    if (validateInputs()) {
      addJob(name);
    }
  };

  const addJob = (name: string) => {
    const formData = new FormData();
    formData.append("name", name);

    fetch(`${apiUrl}/create-job`, {
      method: "POST",
      body: formData,
    })
        .then(response => response.json())
        .then(response => {
          if (response.status === "success"){
            setSuccessMessage("Job created successfully!");
            navigate("/jobs");
          } else {
            setFailMessage("Failed to create job.");
          }
        })
        .catch(error => {
          console.error(error);
          setFailMessage("Failed to create job.");
        });
  };

  return createPortal(
    <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={() => {
          handleClose();
          clearFormAndMessages();
        }}
        backdrop={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={() => {
          handleClose();
          clearFormAndMessages();
        }}>
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Add Job</h1>

          <div className='text-muted fw-bold fs-5'>
            Create a new job for users
          </div>
        </div>

        <form onSubmit={handleSubmit}>

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Job Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
                placeholder='Job name'
                type='text'
                value={name}
                onChange={handleNameChange}
                name='name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {validationError && <p className="form-field-feedback fail">{validationError}</p>}
          {successMessage && <p className="form-field-feedback success">{successMessage}</p>}
          {failMessage && <p className="form-field-feedback fail">{failMessage}</p>}

          <input type={"submit"} className='btn btn-light-primary fw-bolder w-100 mb-8' value={"Create Job"} />

        </form>

      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalAddJob}
