/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { KTSVG } from '../../../_metronic/helpers';

interface ChatPaginationProps {
  currentPage: number;
  totalPages: number;
  chatHistory: {
    data: any[];
    payload: {
      pagination: {
        total: number;
      };
    };
  };
  fetchChatHistory: (page: number) => void;
}

const ChatPagination: React.FC<ChatPaginationProps> = ({ currentPage, totalPages, chatHistory, fetchChatHistory }) => {
  const [pageElements, setPageElements] = useState<JSX.Element[]>([]);
  const entriesPerPage = 10; // Set the fixed number of entries per page

  useEffect(() => {
    const pages: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a
            className='page-link'
            href='#'
            onClick={(e) => {
              e.preventDefault();
              fetchChatHistory(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    setPageElements(pages);
  }, [currentPage, totalPages, fetchChatHistory]);

  // Calculate the range of entries being shown
  const startEntryIndex = (currentPage - 1) * entriesPerPage + 1;
  const endEntryIndex = Math.min(currentPage * entriesPerPage, chatHistory.payload.pagination.total);

  return (
    <div className='d-flex flex-stack flex-wrap pt-10'>
      <div className='fs-6 fw-bold text-gray-700'>
        Showing {startEntryIndex} to {endEntryIndex} of {chatHistory.payload.pagination.total} entries
      </div>
      <ul className='pagination'>
        <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            className='page-link'
            href='#'
            onClick={(e) => {
              e.preventDefault();
              if (currentPage > 1) fetchChatHistory(currentPage - 1);
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr074.svg' className='svg-icon-5 svg-icon-gray-500 me-1' />
            Previous
          </a>
        </li>
        {pageElements}
        <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a
            className='page-link'
            href='#'
            onClick={(e) => {
              e.preventDefault();
              if (currentPage < totalPages) fetchChatHistory(currentPage + 1);
            }}
          >
            Next
            <KTSVG path='/media/icons/duotune/arrows/arr071.svg' className='svg-icon-5 svg-icon-gray-500 ms-1' />
          </a>
        </li>
      </ul>
    </div>
  );
};

export { ChatPagination };
