import React, {FC, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers';
import {initialUser, User} from '../core/_models';
import clsx from 'clsx';
import {useListView} from '../core/ListViewProvider';
import {UsersListLoading} from '../components/loading/UsersListLoading';
import {updateUser} from '../core/_requests';
import {useQueryResponse} from '../core/QueryResponseProvider';
import {IRoleDetails} from "../../../../../models/RoleModel";
import IPConfig from "../../../../../store/IPConfig";
import AvatarUpload from '../../../../../partials/widgets/AvatarUpload'
import {useNavigate} from 'react-router-dom'

type Props = {
  isUserLoading: boolean
  user: User
}

const editUserSchema = Yup.object().shape({
  id: Yup.string()
    .required('ID is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const UserEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const [userRecord, setUserRecord]: any = useState<[]>([]);
  const [roleArray, setRoleArray] = useState<IRoleDetails[]>([]);
  const {setItemIdForUpdate} = useListView()

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  const userAvatarImg = toAbsoluteUrl('/media/avatars/blank.png');
  const [avatarImage, setAvatarImage] = useState<string | null>(userAvatarImg);
  const [avatarUpload, setAvatarUpload] = useState<string | null>(null);

  const {refetch} = useQueryResponse()
  const navigate = useNavigate();
  const apiUrl = IPConfig();

  const editUserValue: string | null = localStorage.getItem("userForEdit")

  const [userForEdit] = useState<User>({
    ...user,
    id: user.id || initialUser.id,
    avatar: user.avatar || initialUser.avatar,
    role: user.role || initialUser.role,
    location: user.location || initialUser.location,
    username: user.username || initialUser.username,
    password: user.password || initialUser.password,
    email: user.email || initialUser.email,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: {
      id: userRecord.id || '',
      avatar: avatarUpload || userAvatarImg,
      username: userRecord.username || '',
      email: userRecord.email || '',
      password: '',
      role: userRecord.role || '',
    },
    //validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        values.id = userRecord.id.toString();
        // @ts-ignore
        values.avatar = avatarUpload;
        await updateUser(values);
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
        cancel(true);
      }
      refetch();
      navigate("/apps/user-management/users")
    },
  });

  useEffect(() => {
    fetchRoles();
    fetchUser();
  }, []);

  const fetchUser = () => {
    fetch(`${apiUrl}/get-all-users?email=${editUserValue}`)
        .then(response => response.json())
        .then(data => {
          if (data.data) {
            setUserRecord(data.data[0]);
          }
        })
        .catch(function (err) {
          console.log("Unable to fetch -", err);
        });
  };

  const fetchRoles = () => {
    fetch(`${apiUrl}/get-role?all=true`)
        .then(response => response.json())
        .then(data => {
          if (data.data) {
            setRoleArray(data.data);
          }
        })
        .catch(function (err) {
          console.log("Unable to fetch -", err);
        });
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      uploadAvatar(file);
    }
  };

  const removeAvatar = () => {
    setAvatarImage(userAvatarImg);
    setAvatarUpload("");
    formik.values.avatar = "";
    userRecord.avatar = "";
  };

  const uploadAvatar = (avatarFile) => {
    const formData = new FormData();
    formData.append("avatar", avatarFile);

    fetch(`${apiUrl}/upload-avatar`, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "success") {
          setAvatarUpload(response['paths']['original']);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column me-n7 pe-7'
          id='kt_modal_add_user_scroll'
        >
          {/* begin::Input group */}
          <div className="fv-row mb-7">
            <AvatarUpload
              blankImg={blankImg}
              publicUrl={IPConfig('public')}
              avatarUpload={avatarUpload}
              currentAvatar={userRecord.avatar}
              avatarImage={avatarImage}
              handleImageChange={handleImageChange}
              removeAvatar={removeAvatar}
            />
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>

            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Username</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Username'
              {...formik.getFieldProps('username')}
              value={formik.values.username ? formik.values.username : userRecord.username}
              type='text'
              name='username'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.username && formik.errors.username},
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  {/*<span role='alert'>{formik.errors.username}</span>*/}
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Email'
              {...formik.getFieldProps('email')}
              value={formik.values.email ? formik.values.email : userRecord.email}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.email && formik.errors.email},
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
              type='email'
              name='email'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                {/*<span role='alert'>{formik.errors.email}</span>*/}
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Password</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Password'
              {...formik.getFieldProps('password')}
              value={formik.values.password}
              type='text'
              name='password'
              className='form-control form-control-solid mb-3 mb-lg-0'
              autoComplete='off'
              disabled={formik.isSubmitting || isUserLoading}
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='fw-bold fs-6 mb-5'>Role</label>
            {/* end::Label */}
            {/* begin::Roles */}

            <div className='separator separator-dashed my-5'></div>
            {roleArray.map((role, index) => (
              <div key={index}>
                <div className='d-flex fv-row'>
                  {/* begin::Radio */}
                  <div className='form-check form-check-custom form-check-solid'>
                    {/* begin::Input */}
                    <input
                      className='form-check-input me-3'
                      {...formik.getFieldProps('role')}
                      name='role'
                      type='radio'
                      value={role.id}
                      id={'kt_modal_update_role_option_' + index}
                      /*checked={formik.values.role?.name === role.name}*/
                      disabled={formik.isSubmitting || isUserLoading}
                    />

                    {/* end::Input */}
                    {/* begin::Label */}
                    <label className='form-check-label' htmlFor='kt_modal_update_role_option_0'>
                      <div className='fw-bolder text-gray-800 text-capitalize'>{role.name.replace("-", " ")}</div>
                      <div className='text-gray-600'>
                        Role description..
                      </div>
                    </label>
                    {/* end::Label */}
                  </div>
                  {/* end::Radio */}
                </div>
                <div className='separator separator-dashed my-5'></div>
              </div>
            ))}

            {/* end::Roles */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}
