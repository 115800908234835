import {KTIcon} from '../../../../../../../_metronic/helpers'
import {UsersListFilter} from './UsersListFilter'
import {UserAddModal} from "../../user-add-modal/UserAddModal";
import {useState} from "react";

const UsersListToolbar = () => {
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const openAddUserModal = () => {
      setAddUserModalOpen(true);
  };

  const closeAddUserModal = () => {
      setAddUserModalOpen(false);
  };

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <UsersListFilter />

      {/* begin::Add user */}
      <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
        <KTIcon iconName='plus' className='fs-2' />
        Add User
      </button>
      {/* end::Add user */}

      {isAddUserModalOpen && <UserAddModal onClose={closeAddUserModal} />}
    </div>
  )
}

export {UsersListToolbar}
