import React from 'react'

const TablePagination = ({pagination, setPagination}) => {
  const {page, total, itemsPerPage} = pagination

  const totalPages = Math.ceil(total / itemsPerPage)

  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPagination({...pagination, page: pageNumber})
    }
  }

  const renderPaginationLinks = () => {
    const links: any = []

    // "First" link
    links.push(
      <li key='first' className={`page-item ${page === 1 ? 'disabled' : ''}`}>
        <button className='page-link' onClick={() => goToPage(1)}>
          First
        </button>
      </li>
    )

    //Previous link
    links.push(
      <li key='previous' className={`page-item ${page === 1 && 'disabled'}`}>
        <button className='page-link' onClick={() => goToPage(page - 1)}>
          Previous
        </button>
      </li>
    )

    // Page numbers
    if (totalPages < 6) {
      for (let i = 1; i <= totalPages; i++) {
        links.push(
          <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
            <button className='page-link' onClick={() => goToPage(i)}>
              {i}
            </button>
          </li>
        )
      }
    } else if (page <= totalPages - 3 && page >= 3) {
      for (let i = 1; i <= totalPages; i++) {
        if (i > page - 3 && i < page + 3) {
          links.push(
            <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
              <button className='page-link' onClick={() => goToPage(i)}>
                {i}
              </button>
            </li>
          )
        }
      }
    } else if (page <= 3) {
      for (let i = 1; i <= totalPages; i++) {
        if (i < 6) {
          links.push(
            <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
              <button className='page-link' onClick={() => goToPage(i)}>
                {i}
              </button>
            </li>
          )
        }
      }
    } else if (page >= totalPages - 3) {
      for (let i = 1; i <= totalPages; i++) {
        if (i > totalPages - 5) {
          links.push(
            <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
              <button className='page-link' onClick={() => goToPage(i)}>
                {i}
              </button>
            </li>
          )
        }
      }
    }

    //Next link
    links.push(
      <li key='next' className={`page-item ${page === totalPages && 'disabled'}`}>
        <button className='page-link' onClick={() => goToPage(page + 1)}>
          Next
        </button>
      </li>
    )

    // "Last" link
    links.push(
      <li key='last' className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
        <button className='page-link' onClick={() => goToPage(totalPages)}>
          Last
        </button>
      </li>
    )

    return links
  }

  if (totalPages <= 1) {
    return null // No need to show pagination if there is only one page
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>{renderPaginationLinks()}</ul>
        </div>
      </div>
    </div>
  )
}

export {TablePagination}
