import React from 'react';

interface DocumentProps {
  documentName: string;
  documentId: number;
  companyId: number;
  publicUrl: string;
}

const EmbedPdfGenerator = {
  generateObjectTag: (publicUrl: string, companyId: number, documentId: number, documentName: string) => {
    const url = `${publicUrl}/uploaded_docs/company/0/${documentName}`;
    return (
      <object
        data={url}
        type="application/pdf"
        width="100%"
        height="800px"
      >
        <p>Your browser does not support PDFs. <a href={url}>Download the PDF</a> instead.</p>
      </object>
    );
  }
};

const DocumentViewer: React.FC<DocumentProps> = ({ publicUrl, companyId, documentId, documentName }) => {
  const objectTag = EmbedPdfGenerator.generateObjectTag(publicUrl, companyId, documentId, documentName);

  return (
    <div>
      {objectTag}
    </div>
  );
};

export default DocumentViewer;