/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import ReactModal from "react-modal";
import IPConfig from "../../../store/IPConfig";
import {ModalDeleteConfirm} from "../../modals/ModalDeleteConfirm";
import {KTIcon} from "../../../../_metronic/helpers";
import {Modal} from "react-bootstrap";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)"
    }
};

const CardDocument: FC = () => {
    const [selectedMenu, setSelectedMenu]: any = useState();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [fileArray, setFileArray]: any = useState([]);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteMessage, setDeleteMessage] = useState("");

    const apiUrl = IPConfig();

    useEffect(() => {
        if (selectedMenu) {
            openModal();
        }
    }, [selectedMenu]);

    const [selectedCategory, setSelectedCategory] = useState("");
    const [categoryArray, setCategoryArray]: any = useState([]);
    const [isUpload, setIsUpload] = useState(false);

    useEffect(() => {
        // Axios GET request for collections
        fetch(`${apiUrl}/getallcollections`)
            .then(response => response.json())
            .then(response => {
                setCategoryArray(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        // Axios GET request for template
        fetch(`${apiUrl}/getfilesbyuser?user_id=2000`)
            .then(response => response.json())
            .then(response => {
                const newArray: any = [];

                if (response.data) {
                    response.data.forEach(element => {
                        if (element.collection !== "") {
                            newArray.push(element);
                        }
                    });

                    setFileArray(newArray);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [apiUrl, isUpload]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsUpload(false);
        setIsOpen(false);
        setSelectedMenu("");
    }

    const [collectionDescription, setCollectionDescription] = useState("");

    function addCategory() {
        // Check if collectionDescription is not empty
        if (!collectionDescription.trim()) {
            setErrorMessage("Please insert a collection description.");
            return;
        }
        setErrorMessage("");

        if (selectedCategory) {
            // Replace spaces with underscores in selectedCategory
            const formattedCategory = selectedCategory.replace(/ /g, "_");

            const formData = new FormData();
            formData.append("collection_name", formattedCategory);
            formData.append("collection_description", collectionDescription);

            fetch(`${apiUrl}/addcollection`, {
                method: "POST",
                body: formData
            })
                .then(response => response.json())
                .then(response => {
                    categoryArray.push(response.data);
                    setCategoryArray(categoryArray);
                    closeModal();
                    openModal();
                    setSelectedCategory("");
                    setCollectionDescription(""); // Reset collection description after adding
                })
                .catch(error => {
                    console.error(error);
                });
        } else {
            alert("Please insert a category name.");
        }
    }

    //add files

    const [filesToAdd, setFilesToAdd]: any = useState([]);

    const handleFileSelect = e => {
        const files: any = Array.from(e.target.files);
        const validFiles: any = files.filter(file => {
            const fileType = file.type;
            const fileName = file.name.toLowerCase();
            return fileType === "text/plain" || fileName.endsWith(".pdf");
        });
        const newArray: any = filesToAdd;
        newArray.push(validFiles[0]);
        setFilesToAdd([...newArray]);
    };

    const saveFiles = () => {
        filesToAdd.forEach(file => {
            handleNewDocumentSubmit(file);
        });
        setFilesToAdd([]);
    };

    const removeFileToAdd = index => {
        const newArray = filesToAdd.slice(); // Create a shallow copy of the array
        newArray.splice(index, 1); // Remove the element at the specified index
        setFilesToAdd(newArray); // Update the state with the new array
    };

    // Function to handle file drop
    const handleFileDrop = e => {
        e.preventDefault();
        const droppedFiles: any = Array.from(e.dataTransfer.files);

        // Filter the dropped files to only include .txt and .pdf files
        const validFiles: any = droppedFiles.filter(file => {
            const fileType = file.type;
            const fileName = file.name.toLowerCase();
            return fileType === "text/plain" || fileName.endsWith(".pdf");
        });

        const newArray = filesToAdd;
        newArray.push(validFiles[0]);
        setFilesToAdd([...newArray]);
    };

    // Function to prevent the default behavior of file drop
    const handleDragOver = e => {
        e.preventDefault();
    };

    // Function to remove a file from the selected files
    const removeFile = (fileToRemove, id) => {
        const newFiles = selectedFiles.filter(file => file !== fileToRemove);
        setSelectedFiles(newFiles);

        fetch(`${apiUrl}/deletefilebyid?id=${id}`, {
            method: "DELETE"
        })
            .then(response => response.json())
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const [selectedUpload, setSelectedUpload] = useState("");
    const [selectedFiles, setSelectedFiles]: any = useState();

    const setUpload = e => {
        setIsUpload(true);
        setSelectedUpload(e.target.value);

        const newArray: any = [];

        fileArray.forEach(element => {
            if (element.collection === e.target.value) {
                newArray.push(element);
            }
        });

        setSelectedFiles(newArray);
    };

    const truncate = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        } else {
            return text;
        }
    };

    function bytesToSize(bytes) {
        const kilobyte = 1024;
        const megabyte = kilobyte * 1024;
        const gigabyte = megabyte * 1024;

        if (bytes < kilobyte) {
            return bytes + " Bytes";
        } else if (bytes < megabyte) {
            return (bytes / kilobyte).toFixed(2) + " KB";
        } else if (bytes < gigabyte) {
            return (bytes / megabyte).toFixed(2) + " MB";
        } else {
            return (bytes / gigabyte).toFixed(2) + " GB";
        }
    }

    const handleNewDocumentSubmit = file => {
        const formData = new FormData();
        formData.append("file_label", file.name);
        formData.append("file", file, file.name);
        formData.append("file_description", "test");
        formData.append("collection_name", selectedUpload);
        formData.append("user_id", "2000");

        fetch(`${apiUrl}/upload`, {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                getFiles();
            })
            .catch(error => {
                console.error(error);
            });

        const getFiles = () => {
            fetch(`${apiUrl}/getfilesbyuser?user_id=2000`)
                .then(response => response.json())
                .then(response => {
                    const newArray: any = [];

                    response.data.forEach(element => {
                        if (element.collection === selectedUpload) {
                            newArray.push(element);
                        }
                    });

                    setSelectedFiles(newArray);
                    setIsUpload(false);
                    setIsUpload(true);
                })
                .catch(error => {
                    console.error(error);
                });
        };
    };

    const deleteCategory = (fileToRemove, id) => {
        const formData = new FormData();
        formData.append("collection_id", fileToRemove);

        fetch(`${apiUrl}/deletecollection`, {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(response => {
                let newArray: any = [];
                categoryArray.forEach(element => {
                    if (element["id"] !== fileToRemove) {
                        newArray.push(element);
                    }
                    setCategoryArray(newArray);
                });
            })
            .catch(error => {
                console.error(error);
            });
    };

  return (
    <div className='card card-xxl-stretch mb-5 mb-xl-8'>
      <div className='card-body d-flex flex-column p-9'>
          <div className='min-height-container'>

              <main id="dms">
                  <section className="container templates">
                      <div className="template_head">
                          <div className="selectDocTemplate">
                              <select
                                  name=""
                                  id="docTemplateSelect"
                                  className={`form-select form-select-solid`}
                                  onChange={e => setSelectedMenu(e.target.value)}
                                  value={selectedMenu}
                              >
                                  <option value="0">Drop Down Menu</option>
                                  <option value="Knowledge Base">Knowledge Base</option>
                              </select>
                              <div>
                                  <Modal
                                      id='kt_modal_create_app'
                                      tabIndex={-1}
                                      aria-hidden='true'
                                      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
                                      show={modalIsOpen}
                                      onHide={closeModal}
                                      backdrop={true}
                                  >
                                      <div className='modal-header pb-0 border-0 justify-content-end'>
                                          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={closeModal}>
                                              <KTIcon iconName='cross' className='fs-1' />
                                          </div>
                                      </div>

                                      <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>

                                          <div className='text-center mb-13'>
                                              {!isUpload ? (
                                                  <>
                                                      {categoryArray &&
                                                          categoryArray.map(label => (
                                                              <div className="upload-delete" key={label["id"]}>
                                                                  <button
                                                                      value={label["collection_name"]}
                                                                      onClick={e => setUpload(e)}
                                                                      className="btn btn-primary me-3 mb-3 modal-button"
                                                                      key={label["collection_name"]}
                                                                  >
                                                                      {label["collection_name"]}
                                                                  </button>
                                                                  <button
                                                                      onClick={() => {
                                                                          setConfirmDeleteModalVisible(true);
                                                                          setDeleteItem({
                                                                              delete: "collection",
                                                                              data: [label["id"], label["collection_name"]]
                                                                          });
                                                                          setDeleteMessage(
                                                                              "Are you sure you want to delete the " +
                                                                              label["collection_name"] +
                                                                              " collection?"
                                                                          );
                                                                      }}
                                                                      className="btn btn-primary mb-3 modal-delete"
                                                                  >
                                                                      Delete
                                                                  </button>
                                                              </div>
                                                          ))}
                                                      <div>
                                                          <input
                                                              value={selectedCategory}
                                                              onChange={e => setSelectedCategory(e.target.value)}
                                                              className="form-control form-control-solid mt-3 mb-3 modal-input"
                                                              placeholder="Insert new collection name"
                                                          />
                                                          <textarea
                                                              value={collectionDescription}
                                                              onChange={e =>
                                                                  setCollectionDescription(e.target.value)
                                                              }
                                                              className="form-control form-control-solid mb-3 modal-textarea"
                                                              placeholder="Insert new collection description (Required)"
                                                          />
                                                          {errorMessage && (
                                                              <p className="error-message-modal">{errorMessage}</p>
                                                          )}
                                                          <button
                                                              className="btn btn-primary modal-add-plus"
                                                              onClick={() => {
                                                                  addCategory();
                                                              }}
                                                          >
                                                              Add+
                                                          </button>
                                                      </div>
                                                  </>
                                              ) : (
                                                  <>
                                                      <div className="modal-header">
                                                          <h2>Upload Document to {selectedUpload} Collection</h2>
                                                      </div>
                                                      <div className="file-uploader">
                                                          <div
                                                              className="dropzone drop-area mb-10"
                                                              onDrop={handleFileDrop}
                                                              onDragOver={handleDragOver}
                                                          >
                                                              <div className="back-flex">
                                                                  <input
                                                                      className="form-control form-control-solid choose-file"
                                                                      type="file"
                                                                      accept=".pdf, .txt"
                                                                      multiple
                                                                      onChange={handleFileSelect}
                                                                  />
                                                              </div>

                                                              <div className="drop-box mt-6">
                                                                  <span className="ki-solid ki-file-up fs-6x"></span>
                                                                  <p>Drag and Drop files</p>

                                                                  {filesToAdd[0] && <h3 className={`mt-6`}>Added files:</h3>}
                                                                  {filesToAdd.map((file, index) => (
                                                                      <>
                                                                          <div key={index}>
                                                                              {file.name} -{" "} {bytesToSize(file.size)}
                                                                          </div>{" "}
                                                                          <button
                                                                              className="btn btn-primary mt-3 remove-addition"
                                                                              onClick={() => {
                                                                                  setConfirmDeleteModalVisible(true);
                                                                                  setDeleteItem({
                                                                                      delete: "file-add",
                                                                                      data: index
                                                                                  });
                                                                                  setDeleteMessage(
                                                                                      "Are you sure you want to remove the file added?"
                                                                                  );
                                                                              }}
                                                                          >
                                                                              Remove
                                                                          </button>
                                                                      </>
                                                                  ))}
                                                              </div>
                                                          </div>
                                                          <div>
                                                              <h3>Saved Files:</h3>
                                                              <div>
                                                                  {selectedFiles.map(file => (
                                                                      <div className="selected-files" key={file.id}>
                                                                          <p className="file-name">
                                                                              {file.file_label}
                                                                          </p>
                                                                          <div className="column-flex">
                                                                              <button
                                                                                  className="btn btn-primary mb-6 modal-button-remove"
                                                                                  onClick={() => {
                                                                                      setConfirmDeleteModalVisible(true);
                                                                                      setDeleteItem({
                                                                                          delete: "file",
                                                                                          data: [file, file.id]
                                                                                      });
                                                                                      setDeleteMessage(
                                                                                          "Are you sure you want to delete the " +
                                                                                          file.file_label +
                                                                                          " file?"
                                                                                      );
                                                                                  }}
                                                                              >
                                                                                  Remove
                                                                              </button>
                                                                          </div>
                                                                      </div>
                                                                  ))}
                                                              </div>
                                                              <button
                                                                  onClick={() => setIsUpload(false)}
                                                                  className="btn btn-primary me-3 mt-3 modal-button-back"
                                                              >
                                                                  Back
                                                              </button>
                                                              <button
                                                                  onClick={() => filesToAdd.length > 0 && saveFiles()}
                                                                  className={`btn btn-primary mt-3 modal-button-save ${
                                                                      filesToAdd.length === 0
                                                                          ? "modal-button-disabled"
                                                                          : ""
                                                                  }`}
                                                              >
                                                                  Save
                                                              </button>
                                                          </div>
                                                      </div>
                                                      <div className="scroll-margin" />
                                                  </>
                                              )}
                                          </div>

                                      </div>
                                  </Modal>

                                  {confirmDeleteModalVisible && (
                                      <ModalDeleteConfirm
                                          show={confirmDeleteModalVisible}
                                          onClose={() => setConfirmDeleteModalVisible(false)}
                                          onConfirm={() => {
                                              setConfirmDeleteModalVisible(false);
                                              if (deleteItem["delete"] == "collection") {
                                                  deleteCategory(
                                                      deleteItem["data"][0],
                                                      deleteItem["data"][1]
                                                  );
                                              } else if (deleteItem["delete"] == "file") {
                                                  removeFile(
                                                      deleteItem["data"][0],
                                                      deleteItem["data"][1]
                                                  );
                                              } else if (deleteItem["delete"] == "file-add") {
                                                  removeFileToAdd(deleteItem["data"]);
                                              }
                                          }}
                                          message={deleteMessage}
                                      />
                                  )}
                              </div>
                          </div>
                      </div>
                      <div className="display_processed-doc-DMS"></div>
                  </section>
              </main>


          </div>
      </div>
    </div>
  )
}

export {CardDocument}
