/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import IPConfig from '../../../../../../store/IPConfig'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.avatar ? (
          <div className='symbol-label'>
            <img src={toAbsoluteUrl(IPConfig('public') + user.avatar)} alt={user.username} className='w-100' />
          </div>
        ) : (
          <div className="symbol-label">
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt={user.username} className="w-100" />
          </div>
        )}
      </a>
    </div>
    <div className="d-flex flex-column">
      <a href="#" className='text-gray-800 text-hover-primary mb-1 text-capitalize'>
        {user.username}
      </a>
      <span>{user.email}</span>
    </div>
  </div>
)

export {UserInfoCell}
