export interface ITaskDetails {
  id: string
  name: string
  permission: Array<Object|null>
}

export const taskDetailsInitValues: ITaskDetails = {
  id: '',
  name: '',
  permission: [],
}
