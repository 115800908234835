import React, {FC} from 'react'
import {Field, ErrorMessage} from 'formik'

const Step3: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-light'>Payment Successful</h2>

        <div className='text-gray-400 fw-bold fs-6 mt-6'>
          Thank you for becoming an alpha user and supporting AltSurge. Your report is currently being generated. In the meantime, you will receive an email with instructions to set up your account.
        </div>

        <div className='text-gray-400 fw-bold fs-6 mt-6'>
          If you encounter any issues or don't receive a report notification within 24 hours, please contact support@AltSurge.ai. Note that image-heavy pitch decks may take longer to process. We appreciate your patience.
        </div>
      </div>
    </div>
  )
}

export {Step3}
