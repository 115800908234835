/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import IPConfig from "../../../store/IPConfig";
import { KTIcon } from '../../../../_metronic/helpers'
import {taskDetailsInitValues as initialValues, ITaskDetails} from "../../../models/TaskModel";
import {TablePagination} from "../../../partials/tables/TablePagination";
import {TableLoading} from "../../../partials/tables/TableLoading";

type Props = {
  className: string
}

const TableTasks: React.FC<Props> = ({className}) => {
  const wsUrl = IPConfig('ws')
  const navigate = useNavigate();
  const [data, setData] = useState<ITaskDetails>(initialValues);
  const [taskArray, setTaskArray] = useState<ITaskDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [user, setUser] = useState<any>(null);
  const [websocket, setWebsocket]: any = useState(null);

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'));
    setUser(userUpdated);
  }, []);

  useEffect(() => {
    if (user) {
      establishWebSocketConnection();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      restartWebSocketConnection();
    }
  }, [pagination.page, searchTerm, sortBy, sortOrder, user]);

  const restartWebSocketConnection = () => {
    if (websocket) {
      websocket.close();
    }
    establishWebSocketConnection();
  }

  const establishWebSocketConnection = () => {
    try {
      let url = `${wsUrl}/websocket-user-task?all=true&user_role=${user.role.id}&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`;

      if (searchTerm) {
        url += `&search=${searchTerm}`;
      }

      if (sortBy && sortOrder) {
        url += `&sort=${sortBy}&order=${sortOrder}`;
      }

      const ws = new WebSocket(url);

      ws.onopen = () => console.log('WebSocket connection established for tasks.');
      ws.onmessage = (event: any) => {
        const response = JSON.parse(event.data);
        if (response.data) {
          let tasks: any = [];
          for (let i = 0; i < response.data.length; i++) {
            let task = response.data[i];
            if (task.user_role) {
              if (user.role.name === task.user_role.name) {
                tasks.push(task);
              }
            }
          }
          setTaskArray(tasks);
        }
        if (response.payload && response.payload.pagination) {
          setPagination((prev) => ({...prev, total: response.payload.pagination.total}));
        } else {
          setTaskArray([]);
        }
      };
      ws.onclose = () => console.log('WebSocket connection closed for tasks.');
      ws.onerror = (error) => console.error('WebSocket error:', error);

      setWebsocket(ws)
    } catch (e) {
      console.log(e)
    }
  };

  const saveTask = (index) => {
    if (taskArray[index]['status']['name'] === "pending") {
      localStorage.setItem("currentTask", JSON.stringify(taskArray[index]));

      const updatedData = Object.assign(data, taskArray[index])
      setData(updatedData)

      navigate("/tasks/view");
    }
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Tasks</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{taskArray.length} tasks listed</span>
        </h3>
        <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to search tasks'
        >
          <div className="me-3">
            <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-120px'>Workspace</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-120px'>Date Started</th>
                <th className='min-w-120px'>Date Completed</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>

              {taskArray && taskArray.map((task, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center" onClick={() => saveTask(index)}>
                      <div className="symbol symbol-50px me-5">
                        {task['status']['name'] === 'pending' && (
                          <span className="symbol-label bg-light-primary">
                            <KTIcon iconName="archive-tick" className="fs-2x text-primary" />
                          </span>
                        )}
                        {task['status']['name'] === 'processing' && (
                          <span className="symbol-label bg-light-info">
                            <KTIcon iconName="archive-tick" className="fs-2x text-info" />
                          </span>
                        )}
                        {task['status']['name'] === 'completed' && (
                          <span className="symbol-label bg-light-success">
                            <KTIcon iconName="archive-tick" className="fs-2x text-success" />
                          </span>
                        )}
                        {task['status']['name'] === 'failed' && (
                          <span className="symbol-label bg-light-danger">
                            <KTIcon iconName="archive-tick" className="fs-2x text-danger" />
                          </span>
                        )}
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6 text-capitalize">
                          {task['name']}
                        </a>
                        <span className="text-muted fw-semibold text-muted d-block fs-7">
                          Task
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveTask(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6 text text-capitalize">
                      {task['workspace_name']}
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveTask(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6 text text-capitalize">
                      {task['status']['name'] === 'pending' && (
                        <span className="badge badge-light-primary fs-7">{task['status']['name']}</span>
                      )}
                      {task['status']['name'] === 'processing' && (
                        <span className="badge badge-light-info fs-7">{task['status']['name']}</span>
                      )}
                      {task['status']['name'] === 'completed' && (
                        <span className="badge badge-light-success fs-7">{task['status']['name']}</span>
                      )}
                      {task['status']['name'] === 'failed' && (
                        <span className="badge badge-light-danger fs-7">{task['status']['name']}</span>
                      )}
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveTask(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6 text text-capitalize">
                      {new Date(task['date_started']).toLocaleDateString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }).replace(/,/g, '')}
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveTask(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6 text text-capitalize">
                      {task['date_completed'] ? new Date(task['date_completed']).toLocaleDateString('en-US', {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                      }).replace(/,/g, '') : 'N/A'}
                    </a>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end flex-shrink-0">
                      {task['status']['name'] === 'pending' && (
                        <a
                          href="#"
                          onClick={() => saveTask(index)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        >
                          <KTIcon iconName="eye" className="fs-3" />
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              ))}

              {!taskArray || taskArray.length === 0 && (
                <tr className="w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2">
                  <td colSpan={6}>
                    No matching records found
                  </td>
                </tr>
              )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {(pagination && pagination.total > 0 && taskArray && taskArray.length > 0) && (
          <TablePagination
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {isLoading && <TableLoading/>}
      </div>
      {/* begin::Body */}

    </div>
  )
}

export {TableTasks}
