/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TableTaskView} from "./components/TableTaskView";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Tasks',
    path: '/tasks',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TasksTable: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <TableTaskView className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const TaskOverview: FC = () => {
  const intl = useIntl()
  const currentTask =  JSON.parse(localStorage.getItem("currentTask") || '{}');
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{currentTask.name}</PageTitle>
      <TasksTable />
    </>
  )
}

export {TaskOverview}
