// @ts-nocheck
import clsx from 'clsx'
import React, {FC} from 'react'
import {Row} from 'react-table'
import {User} from '../../core/_models'

type Props = {
  row: Row<User>,
  isActive: boolean | undefined
}

const CustomRow: FC<Props> = ({row, isActive}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({' min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.column.id === 'role' && (
            <span className='badge badge-light-primary fs-7 mb-2'>{cell.render('Cell')}</span>
          )}
          {cell.column.id === 'status' && (
           isActive ? <span className='badge badge-light-success fs-7 mb-2'>active</span>
           : <span className='badge badge-light-danger fs-7 mb-2'>inactive</span>
          )}
          {(cell.column.id !== 'role' && cell.column.id !== 'active') && (
            cell.render('Cell')
          )}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
