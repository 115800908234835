/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import IPConfig from '../../../store/IPConfig'
import clsx from 'clsx'
import JSONEditorComponent from '../../../partials/JSONEditorComponent'
import {IPermissionDetails} from '../../../models/PermissionModel'
import MultiSelectDropdown from '../../../partials/widgets/MultiSelectDropdown'

type Props = {
  className: string
}

interface Task {
  task_name: string
  type: string
  agent_name?: string
  instruction?: string
  next_task?: string
  condition?: {next_task: string}[]
}

interface Workspace {
  name: string
  permission: string
  tasks: Task[]
}

interface Workflow {
  workspace: Workspace
}

interface ValidationResult {
  isValid: boolean
  errors?: string[]
}

const WorkspaceAddForm: React.FC<Props> = ({className}) => {
  const [activeTab, setActiveTab] = useState('workspace')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [timeout, setTimeout] = useState('')
  const [workflow, setWorkflow] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [failMessage, setFailMessage] = useState('')
  const [validationError, setValidationError] = useState('')
  const [user, setUser] = useState<any>(null)

  const [permissionArray, setPermissionArray] = useState<IPermissionDetails[]>([])
  const [permissionPushArray, setPermissionPushArray]: any = useState<[]>([])

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUser(userUpdated)
  }, [])

  const apiUrl = IPConfig()
  const navigate = useNavigate()

  useEffect(() => {
    setWorkflow(
      '{\n' +
        '  "workspace": {\n' +
        '    "name": "Workspace Name",\n' +
        '    "description": "OCR a PDF document and analyse it",\n' +
        '    "timeout": 600,\n' +
        '    "permission": "none yet",\n' +
        '    "tasks": [\n' +
        '      {\n' +
        '        "task_name": "start",\n' +
        '        "type": "script",\n' +
        '        "description": "OCR the pdf document",\n' +
        '        "input_documents": [\n' +
        '          "*.pdf"\n' +
        '        ],\n' +
        '        "output_document": "data_ocr.txt",\n' +
        '        "method": "start_ocr",\n' +
        '        "next_task": "analyse"\n' +
        '      },\n' +
        '      {\n' +
        '        "task_name": "analyse",\n' +
        '        "type": "ai_agent_task",\n' +
        '        "agent_name": "OpenAIAgent",\n' +
        '        "description": "Analyse the ocr\'d document and do a 0.5 Memo analysis",\n' +
        '        "instruction": "You are a investor and your job is to evaluate investment opportunities. Please evaluate the following pitch deck and tell me if its a worthwhile investment with reasoning. Please make sure your response is in markdown.",\n' +
        '        "input_documents": [\n' +
        '          "data_ocr.txt"\n' +
        '        ],\n' +
        '        "template": "{instruction} : {data_ocr.txt}",\n' +
        '        "output_document": "analysed_doc.txt",\n' +
        '        "next_task": "exit"\n' +
        '      }\n' +
        '    ]\n' +
        '  }\n' +
        '}'
    )
  }, [])

  useEffect(() => {
    fetchPermissions()
  }, [])

  const fetchPermissions = () => {
    fetch(`${apiUrl}/get-permission?all=true&items_per_page=100`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          setPermissionArray(data.data)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleTimeoutChange = (e) => {
    setTimeout(e.target.value)
  }

  const handleWorkflowChange = (updatedWorkflow: string) => {
    setWorkflow(updatedWorkflow)
  }

  const validateInputs = () => {
    try {
      if (!name.trim()) {
        setValidationError('Name field is required.')
        return false
      }
      if (!description.trim()) {
        setValidationError('Description field is required.')
        return false
      }
      if (!timeout) {
        setValidationError('Timeout field is required.')
        return false
      }
    } catch (e) {
      setValidationError('Some fields are invalid.')
      return false
    }
    setValidationError('')
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFailMessage('')
    setSuccessMessage('')
    setValidationError('')

    let validationResult: ValidationResult | null = null

    try {
      JSON.parse(workflow)
      validationResult = validateWorkflow(JSON.parse(workflow))
    } catch (e) {
      setValidationError('Invalid JSON in workflow.')
      return
    }

    if (validationResult && typeof validationResult !== 'undefined' && !validationResult.isValid) {
      setValidationError(validationResult.errors!.join(' '))
      return
    }

    if (validateInputs()) {
      addWorkspace(name, description, timeout, workflow)
    }
  }

  const addWorkspace = (
    name: string,
    description: string,
    timeout: string,
    workflow: string
  ) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    if (permissionPushArray.length > 0) {
      formData.append('permissions', JSON.stringify(permissionPushArray))
    } else {
      formData.append('permissions', '[]')
    }
    formData.append('timeout', timeout)
    formData.append('workflow', workflow)
    if (user && user.id) {
      formData.append('user_id', user.id)
    }

    fetch(`${apiUrl}/create-workspace`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          setSuccessMessage('Workspace created successfully!')
          navigate('/workspaces')
        } else {
          if (response.message) {
            setFailMessage('Failed to create workspace. ' + response.message)
          } else {
            setFailMessage('Failed to create workspace.')
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setFailMessage('Failed to create workspace. ' + error)
      })
  }

  function validateWorkflow(workflow: Workflow): ValidationResult {
    const errors: string[] = []
    const taskNames: Set<string> = new Set()

    // Validate workspace
    if (!workflow.workspace) {
      errors.push('Missing workspace object.')
    } else {
      const ws: Workspace = workflow.workspace

      // Required fields in workspace
      if (!ws.name) errors.push('Workspace name is required.')
      if (typeof ws.permission === 'undefined')
        errors.push('Workspace permission level is required.')
      if (!Array.isArray(ws.tasks) || ws.tasks.length === 0) {
        errors.push('Workspace must have at least one task.')
      } else {
        // Validate each task and collect task names
        ws.tasks.forEach((task: Task, index: number) => {
          if (!task.task_name) {
            errors.push(`Task ${index + 1} is missing a name.`)
          } else {
            // Check for unique task_name
            if (taskNames.has(task.task_name)) {
              errors.push(`Task name ${task.task_name} is not unique.`)
            } else {
              taskNames.add(task.task_name)
            }
          }

          if (!task.type) errors.push(`Task ${task.task_name || index + 1} is missing a type.`)
          if (task.type === 'ai_agent_task') {
            if (!task.agent_name)
              errors.push(`AI Agent Task ${task.task_name} requires an agent name.`)
            if (!task.instruction)
              errors.push(`AI Agent Task ${task.task_name} requires instructions.`)
          }

          // Add more validations as per the detailed schema requirements
        })
      }
    }

    // Validate next_task references
    if (taskNames.size > 0) {
      workflow.workspace.tasks.forEach((task: Task, index: number) => {
        if (task.next_task && !taskNames.has(task.next_task) && task.next_task !== 'exit') {
          errors.push(
            `Task ${task.task_name || index + 1} has an invalid next_task reference: ${
              task.next_task
            }`
          )
        }

        // Validate conditions if present
        if (task.condition) {
          task.condition.forEach((cond, condIndex) => {
            if (cond.next_task && !taskNames.has(cond.next_task) && cond.next_task !== 'exit') {
              errors.push(
                `Condition ${condIndex + 1} in task ${
                  task.task_name || index + 1
                } has an invalid next_task reference: ${cond.next_task}`
              )
            }
          })
        }
      })
    }

    // Return errors
    if (errors.length > 0) {
      return {isValid: false, errors}
    } else {
      return {isValid: true}
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Add Workspace</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Configure new workspace</span>
        </h3>

        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                data-bs-toggle='tab'
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {active: activeTab === 'workspace'}
                )}
                onClick={() => handleTabChange('workspace')}
              >
                Workspace Details
              </a>
            </li>

            <li className='nav-item'>
              <button
                data-bs-toggle='tab'
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {active: activeTab === 'workflow'}
                )}
                onClick={() => handleTabChange('workflow')}
              >
                Workflow
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pb-3 pt-6'>
        <form onSubmit={handleSubmit}>
          {activeTab === 'workspace' && (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace name'
                  type='text'
                  value={name}
                  onChange={handleNameChange}
                  name='name'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Description</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace description'
                  type='text'
                  value={description}
                  onChange={handleDescriptionChange}
                  name='description'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <MultiSelectDropdown
                items={permissionArray.map(permission => ({
                  name: permission.permission
                }))}
                selectedItems={permissionPushArray}
                onItemToggle={(itemName) => {
                  if (permissionPushArray.includes(itemName)) {
                    setPermissionPushArray(permissionPushArray.filter(p => p !== itemName));
                  } else {
                    setPermissionPushArray([...permissionPushArray, itemName]);
                  }
                }}
                placeholder="Select permissions"
                label="Permissions"
                isRequired={true}
                marginBottom={false}
              />
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2 mt-6'>Timeout</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace timeout'
                  type='text'
                  value={timeout}
                  onChange={handleTimeoutChange}
                  name='timeout'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          )}

          {activeTab === 'workflow' && (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Workflow</label>
                {/* end::Label */}

                <JSONEditorComponent jsonData={workflow} onUpdateJson={handleWorkflowChange} />
              </div>
              {/* end::Input group */}
            </>
          )}

          {validationError && (
            <p className='form-field-feedback text-danger fail'>{validationError}</p>
          )}
          {successMessage && (
            <p className='form-field-feedback text-success success'>{successMessage}</p>
          )}
          {failMessage && <p className='form-field-feedback text-danger fail'>{failMessage}</p>}

          <input
            type={'submit'}
            className='btn btn-light-primary fw-bolder w-100 mb-8 mt-3'
            value={'Create Workspace'}
          />
        </form>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {WorkspaceAddForm}
