import React from 'react';
import clsx from 'clsx'
import {KTIcon} from '../../../_metronic/helpers'

interface ChatButtonProps {
  id?: string
  className?: string
  onClick?: () => void;
}

const ChatButton: React.FC<ChatButtonProps> = ({ id, className, onClick }) => {
  return (
    <div id={id} className={clsx('floating-button', className)} onClick={onClick}>
      <KTIcon iconName='message-text' className='fs-1 text-white' />
    </div>
  );
};

export default ChatButton;