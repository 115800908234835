/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {TableQueueView} from "./components/TableQueueView";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Queues',
    path: '/queues',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const QueuesTable: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <TableQueueView className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const QueueOverview: FC = () => {
  const intl = useIntl()
  const currentQueue =  JSON.parse(localStorage.getItem("currentQueue") || '{}');
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>{currentQueue.name}</PageTitle>
      <QueuesTable />
    </>
  )
}

export {QueueOverview}
