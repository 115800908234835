import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {MemberQueryResponse, User, UsersQueryResponse} from './_models'
import {refreshUser} from '../../../../auth/core/_requests'

const API_MEMBERS = process.env.REACT_APP_API_URL_DMS
const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`
const GET_MEMBERS_URL = `${API_MEMBERS}/get-all-users`
const REGISTER_URL = `${API_MEMBERS}/register`
const UPDATE_URL = `${API_MEMBERS}/updateuser`

const getUsers = (query: string): Promise<MemberQueryResponse> => {
  return fetch(`${GET_MEMBERS_URL}?${query + "&check_delete=True"}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        throw error;
      });
};

const getUsersOld = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${GET_MEMBERS_URL}?id=${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

// @ts-ignore
const createUser = (user: User): Promise<User | undefined> => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("email", user.email);
    // @ts-ignore
    formData.append("password", user.password);
    // @ts-ignore
    formData.append("username", user.username);
    // @ts-ignore
    formData.append("role_id", user.role);

    fetch(`${REGISTER_URL}`, {
        method: "POST",
        body: formData,
    })
        .then(response => response.json())
        .then(response => {
            console.log(response);
        })
        .catch(error => {
            console.error(error);
        });
}

// @ts-ignore
const updateUser = (user: User): Promise<User | undefined> => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("user_id", user.id);
    validateInput(formData, "email", user.email);
    validateInput(formData, "password", user.password);
    validateInput(formData, "username", user.username);
    validateInput(formData, "role_id", user.role);
    validateInput(formData, "avatar", user.avatar);

    fetch(`${UPDATE_URL}`, {
        method: "POST",
        body: formData,
    })
        .then(response => response.json())
        .then(response => {
          refreshUser();
          window.location.reload();
        })
        .catch(error => {
            console.error(error);
        });
}

const validateInput = (formData: FormData, fieldName: string, field: any) => {
    if (typeof field !== "undefined" && field !== null && field !== "") {
        formData.append(fieldName, field);
    }
}

/*const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}*/

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser}
