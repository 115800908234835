/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const WizardLayoutAlt = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100 alt-bg-gunmetal'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 ps-10 pe-10 pb-10 order-1 order-lg-1'>

        {/* begin::Logo */}
        <div className={'fs-7qx'}>
          <Link to="/">
            <img alt="Logo" src={toAbsoluteUrl('/media/logos/alt-logo-dark.png')} style={{width: `160px`}} />
          </Link>
        </div>
        {/* end::Logo */}

        {/* begin::Form */}
        <div className="d-flex flex-center flex-column flex-lg-row-fluid mb-10 pb-20">

          <Outlet />

        </div>
        {/* end::Form */}

      </div>
      {/* end::Body */}

    </div>
  )
}

export {WizardLayoutAlt}
