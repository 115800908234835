/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import IPConfig from '../../../store/IPConfig'
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import AvatarUpload from '../../../partials/widgets/AvatarUpload'
import MultiSelectDropdown from '../../../partials/widgets/MultiSelectDropdown'

type Props = {
  className: string
}

const AgentAddForm: React.FC<Props> = ({className}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [avatar, setAvatar] = useState('')
  const [type, setType] = useState('')
  const [template, setTemplate] = useState('')
  const [chatbotType, setChatbotType] = useState('');
  const [chatbotTemplate, setChatbotTemplate] = useState('');
  const [typeArray, setTypeArray]: any = useState([])
  const [toolArray, setToolArray]: any = useState([])
  const [toolPushArray, setToolPushArray]: any = useState<[]>([])
  const [tagArray, setTagArray]: any = useState([]);
  const [tagPushArray, setTagPushArray]: any = useState<[]>([]);
  const [successMessage, setSuccessMessage] = useState('')
  const [failMessage, setFailMessage] = useState('')
  const [validationError, setValidationError] = useState('')
  const [activeTab, setActiveTab] = useState('configure');
  const [conversational, setConversational] = useState(false);
  const [memory, setMemory] = useState<boolean>(false);

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl('/media/avatars/blank.png')
  const [avatarImage, setAvatarImage] = useState<string | null>(userAvatarImg)
  const [avatarUpload, setAvatarUpload] = useState<string | null>(null)

  const apiUrl = IPConfig()
  const navigate = useNavigate()

  useEffect(() => {
    fetchTools()
    fetchTags();
    fetchTypes()
    setTemplate('My job is to ask questions about a pitch deck,\n' +
      '\n' +
      'Instructions: { instructions }\n' +
      'A pitch deck to analyze:\n' +
      '{ document }')
  }, [])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleTypeChange = (e) => {
    setType(e.target.value)
  }

  const handleTemplateChange = (e) => {
    setTemplate(e.target.value)
  }

  const handleChatbotTypeChange = (e) => {
    setChatbotType(e.target.value);
  };

  const handleChatbotTemplateChange = (e) => {
    setChatbotTemplate(e.target.value);
  };

  const validateInputs = () => {
    if (!name.trim()) {
      setValidationError('All fields are required.')
      return false
    }
    setValidationError('')
    return true
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setFailMessage('')
    setSuccessMessage('')
    setValidationError('')
    if (validateInputs()) {
      addAgent(name)
    }
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const addAgent = (name: string) => {
    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('type', type)
    formData.append('template', template)

    if (conversational) {
      formData.append("conversational", conversational.toString())
    }

    if (memory) {
      formData.append("memory", memory.toString())
    }

    if (chatbotType) {
      formData.append("chatbot_type", chatbotType);
    }

    if (chatbotTemplate) {
      formData.append("chatbot_template", chatbotTemplate);
    }

    if (avatarUpload) {
      formData.append('avatar', avatarUpload)
    }

    if (!avatarUpload && !avatar) {
      formData.append('avatar', '')
    }

    if (toolPushArray.length) {
      formData.append('tools', toolPushArray)
    }

    if (tagPushArray.length) {
      formData.append("tags", tagPushArray);
    }

    fetch(`${apiUrl}/create-agent`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          setSuccessMessage('Agent created successfully!')
          navigate('/agents')
        } else {
          if (response.message) {
            setFailMessage('Failed to create agent. ' + response.message)
          } else {
            setFailMessage('Failed to create agent. Please try again.')
          }
        }
      })
      .catch(error => {
        console.error(error)
        setFailMessage('Failed to create agent. ' + error)
      })
  }

  const fetchTags = () => {
    fetch(`${apiUrl}/get-tag?all=true`, {})
      .then(response => response.json())
      .then(response => {
        setTagArray(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const fetchTools = () => {
    fetch(`${apiUrl}/get-agent-tool?all=true`, {})
      .then(response => response.json())
      .then(response => {
        setToolArray(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const fetchTypes = () => {
    fetch(`${apiUrl}/get-agent-type?all=true`, {})
      .then(response => response.json())
      .then(response => {
        setTypeArray(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setAvatarImage(reader.result as string)
      }
      reader.readAsDataURL(file)
      uploadAvatar(file)
    }
  }

  const removeAvatar = () => {
    setAvatarImage(userAvatarImg)
    setAvatarUpload('')
    setAvatar('')
  }

  const uploadAvatar = (avatarFile) => {
    const formData = new FormData()
    formData.append('avatar', avatarFile)

    fetch(`${apiUrl}/upload-avatar`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === 'success') {
          setAvatarUpload(response['paths']['original'])
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">

        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Add Agent</span>
          <span className="text-muted mt-1 fw-semibold fs-7">Configure new agent</span>
        </h3>

        <div className="card-toolbar">
          <ul className="nav">

            <li className="nav-item">
              <a
                data-bs-toggle="tab"
                className={clsx('nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1', {active: activeTab === 'configure'})}
                onClick={() => handleTabChange('configure')}
              >
                Configuration
              </a>
            </li>
            <li className="nav-item">
              <button
                data-bs-toggle="tab"
                className={clsx('nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1', {active: activeTab === 'details'})}
                onClick={() => handleTabChange('details')}
              >
                Details
              </button>
            </li>

            {conversational && (
              <li className="nav-item">
                <a
                  data-bs-toggle="tab"
                  className={clsx('nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1', {active: activeTab === 'chatbot'})}
                  onClick={() => handleTabChange('chatbot')}
                >
                  Chatbot
                </a>
              </li>
            )}

          </ul>
        </div>

      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pb-3 pt-6">
        <form onSubmit={handleSubmit}>

          {activeTab === 'configure' && (
            <>

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Agent name"
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  name="name"
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete="off"
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Description</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Agent description"
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                  name="description"
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete="off"
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Type</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select name="type"
                  className="form-select form-select-solid form-select-lg"
                  value={type}
                  onChange={handleTypeChange}>
                  <option value="" hidden>Select a type</option>
                  {typeArray.map((type, index) => (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Template</label>
                {/* end::Label */}

                {/* begin::Input */}
                <textarea
                  rows={7}
                  placeholder="Agent template"
                  value={template}
                  onChange={handleTemplateChange}
                  name="template"
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete="off"
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              <MultiSelectDropdown
                items={toolArray}
                selectedItems={toolPushArray}
                onItemToggle={(toolName) => {
                  if (toolPushArray.includes(toolName)) {
                    setToolPushArray(toolPushArray.filter(name => name !== toolName));
                  } else {
                    setToolPushArray([...toolPushArray, toolName]);
                  }
                }}
                placeholder="Select tools"
                label="Tools"
              />

              <MultiSelectDropdown
                items={tagArray}
                selectedItems={tagPushArray}
                onItemToggle={(tagName) => {
                  if (tagPushArray.includes(tagName)) {
                    setTagPushArray(tagPushArray.filter(name => name !== tagName));
                  } else {
                    setTagPushArray([...tagPushArray, tagName]);
                  }
                }}
                placeholder="Select tags"
                label="Tags"
              />

            </>
          )}

          {activeTab === 'details' && (
            <>

              <div className="fv-row mb-7">
                <AvatarUpload
                  blankImg={blankImg}
                  publicUrl={IPConfig('public')}
                  avatarUpload={avatarUpload}
                  currentAvatar={avatar}
                  avatarImage={avatarImage}
                  handleImageChange={handleImageChange}
                  removeAvatar={removeAvatar}
                />
              </div>

              <div className="fv-row mb-7">
                <div className="d-flex align-items-center mb-8">
                  {/* begin::Bullet */}
                  <span className="bullet bullet-vertical h-40px bg-primary"></span>
                  {/* end::Bullet */}
                  {/* begin::Checkbox */}
                  <div className="form-check form-check-custom form-check-solid mx-5">
                    <input className="form-check-input" type="checkbox" value="" onChange={() => {
                      setConversational(!conversational);
                    }} />
                  </div>
                  {/* end::Checkbox */}
                  {/* begin::Description */}
                  <div className="flex-grow-1">
                    <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">
                      Conversational Agent
                    </a>
                    <span className="text-muted fw-semibold d-block">Agent used for Chatbot</span>
                  </div>
                  {/* end::Description */}
                  {/*<span className='badge badge-light-primary fs-8 fw-bold'>Agent Type</span>*/}
                </div>
              </div>

            </>
          )}

          {activeTab === 'chatbot' && (
            <>

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Chatbot Type</label>
                {/* end::Label */}

                {/* begin::Input */}
                <select name="chatbot_type"
                        className="form-select form-select-solid form-select-lg"
                        value={chatbotType}
                        onChange={handleChatbotTypeChange}>
                  <option value="" hidden>Select a type</option>
                  {typeArray.map((type, index) => (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </select>
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Chatbot Template</label>
                {/* end::Label */}

                {/* begin::Input */}
                <textarea
                  rows={7}
                  placeholder="Agent template"
                  value={chatbotTemplate}
                  onChange={handleChatbotTemplateChange}
                  name="chatbot_template"
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete="off"
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              <div className="fv-row mb-7">
                <div className="d-flex align-items-center mb-8">
                  {/* begin::Bullet */}
                  <span className="bullet bullet-vertical h-40px bg-primary"></span>
                  {/* end::Bullet */}
                  {/* begin::Checkbox */}
                  <div className="form-check form-check-custom form-check-solid mx-5">
                    <input className="form-check-input" type="checkbox" value="" onChange={() => {
                      setMemory(!memory);
                    }} />
                  </div>
                  {/* end::Checkbox */}
                  {/* begin::Description */}
                  <div className="flex-grow-1">
                    <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">
                      Memory
                    </a>
                    <span className="text-muted fw-semibold d-block">Memory enabled for Chatbot</span>
                  </div>
                  {/* end::Description */}
                  {/*<span className='badge badge-light-primary fs-8 fw-bold'>Agent Type</span>*/}
                </div>
              </div>

            </>
          )}

          {validationError && <p className="form-field-feedback text-danger fail">{validationError}</p>}
          {successMessage && <p className="form-field-feedback text-success success">{successMessage}</p>}
          {failMessage && <p className="form-field-feedback text-danger fail">{failMessage}</p>}

          <input type={'submit'} className="btn btn-light-primary fw-bolder w-100 mb-8" value={'Create Agent'} />

        </form>
      </div>
      {/* begin::Body */}

    </div>
  )
}

export {AgentAddForm}
