/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import IPConfig from '../../../store/IPConfig'
import {KTIcon} from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from '../../../partials/modals/ModalDeleteConfirm'
import {
  workspaceDetailsInitValues as initialValues,
  IWorkspaceDetails,
} from '../../../models/WorkspaceModel'
import {ModalAddWorkspace} from '../../../partials/modals/workspaces/ModalAddWorkspace'
import {TableLoading} from '../../../partials/tables/TableLoading'
import {TablePagination} from '../../../partials/tables/TablePagination'

type Props = {
  className: string
}

const TableWorkspaces: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<IWorkspaceDetails>(initialValues)
  const [workspaceArray, setWorkspaceArray] = useState<IWorkspaceDetails[]>([])
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [showAddWorkspaceModal, setShowAddWorkspaceModal] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [status, setStatus] = useState<string | undefined>()
  const [requestStatus, setRequestStatus] = useState<string | undefined>()
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUser(userUpdated)
  }, [])

  const navigate = useNavigate()
  const apiUrl = IPConfig()
  const defaultStatus = '10,8'

  const [failMessage, setFailMessage] = useState('')

  useEffect(() => {
    fetchWorkspaces()
  }, [pagination.page, searchTerm, sortBy, sortOrder, requestStatus, user])

  const fetchWorkspaces = () => {
    if (user) {
      setIsLoading(true)

      // Construct API URL based on pagination, search, and sorting parameters
      let url = `${apiUrl}/get-workspace?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`

      if (searchTerm) {
        url += `&search=${searchTerm}`
      }

      if (sortBy && sortOrder) {
        url += `&sort=${sortBy}&order=${sortOrder}`
      }

      if (requestStatus) {
        url += `&status=[${requestStatus}]`
      } else {
        url += `&status=[${defaultStatus}]`
      }

      if (user && user.id) {
        url += `&user_id=${user.id}`
      }

      fetch(url)
        .then((response) => response.json())
        .then((response) => {
          if (response.data) {
            setWorkspaceArray(response.data)
          }
          if (response.payload && response.payload.pagination) {
            setPagination((prev) => ({...prev, total: response.payload.pagination.total}))
          } else {
            setWorkspaceArray([])
          }
        })
        .catch(function (err) {
          console.log('Unable to fetch -', err)
        })

      setIsLoading(false)
    }
  }

  const deleteWorkspace = (workspaceId) => {
    const formData = new FormData()
    formData.append('id', workspaceId)

    fetch(`${apiUrl}/delete-workspace`, {
      method: 'DELETE',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'error') {
          setFailMessage(data.message)
        } else {
          fetchWorkspaces()
          setConfirmDeleteModalVisible(false)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const saveWorkspace = (index) => {
    localStorage.setItem('currentWorkspace', JSON.stringify(workspaceArray[index]))

    const updatedData = Object.assign(data, workspaceArray[index])
    setData(updatedData)

    navigate('/workspaces/edit')
  }

  const resetData = () => {
    setStatus(defaultStatus)
    setRequestStatus(defaultStatus)
  }

  const filterData = () => {
    setPagination({
      page: 1,
      total: 0,
      itemsPerPage: pagination.itemsPerPage,
    })
    setRequestStatus(status)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Workspaces</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {workspaceArray.length} workspaces listed
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a workspace'
        >
          <div className='me-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <a href='/workspaces/create' className='btn btn-light-primary me-3'>
            <KTIcon iconName='plus' className='fs-3' />
            Add Workspace
          </a>

          <div>
            {/* begin::Filter Button */}
            <button
              disabled={isLoading}
              type='button'
              className='btn btn-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTIcon iconName='filter' className='fs-2' />
              Filter
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>Status:</label>
                  <select
                    className='form-select form-select-solid fw-bolder text-capitalize'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option value='10,8,6'>All</option>
                    <option value='10,8' selected>
                      Production & Draft
                    </option>
                    <option value='10'>Production</option>
                    <option value='8'>Draft</option>
                    <option value='6'>Archived</option>
                  </select>
                </div>
                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    disabled={isLoading}
                    onClick={resetData}
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    Reset
                  </button>
                  <button
                    type='button'
                    disabled={isLoading}
                    onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Apply
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
            {/* end::SubMenu */}
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-140px'>Description</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-120px'>Timeout</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>
              {workspaceArray.map((workspace, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center' onClick={() => saveWorkspace(index)}>
                      <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-success'>
                          <KTIcon iconName='bookmark' className='fs-2x text-success' />
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {workspace['name']}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Workspace
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveWorkspace(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {workspace['description']}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveWorkspace(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {workspace['status']['name'] === 'production' && (
                        <span className='badge badge-light-success fs-7 mb-2'>
                          {workspace['status']['name']}
                        </span>
                      )}
                      {workspace['status']['name'] === 'draft' && (
                        <span className='badge badge-light-warning fs-7 mb-2'>
                          {workspace['status']['name']}
                        </span>
                      )}
                      {workspace['status']['name'] === 'archived' && (
                        <span className='badge badge-light-danger fs-7 mb-2'>
                          {workspace['status']['name']}
                        </span>
                      )}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveWorkspace(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {workspace['timeout']}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        onClick={() => saveWorkspace(index)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        onClick={() => {
                          setConfirmDeleteModalVisible(true)
                          setDeleteItem([
                            // @ts-ignore
                            workspace['name'],
                            // @ts-ignore
                            workspace['id'],
                          ])
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {!workspaceArray ||
                (workspaceArray.length === 0 && (
                  <tr className='w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2'>
                    <td colSpan={6}>No matching records found</td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {pagination && pagination.total > 0 && workspaceArray && workspaceArray.length > 0 && (
          <TablePagination pagination={pagination} setPagination={setPagination} />
        )}
        {isLoading && <TableLoading />}
      </div>
      {/* begin::Body */}

      <ModalAddWorkspace
        show={showAddWorkspaceModal}
        handleClose={() => setShowAddWorkspaceModal(false)}
      />

      {confirmDeleteModalVisible && (
        <ModalDeleteConfirm
          show={confirmDeleteModalVisible}
          onClose={() => setConfirmDeleteModalVisible(false)}
          onConfirm={() => {
            deleteWorkspace(deleteItem[1])
          }}
          message={
            failMessage ? failMessage : 'Are you sure you want to delete ' + deleteItem[0] + '?'
          }
        />
      )}
    </div>
  )
}

export {TableWorkspaces}
