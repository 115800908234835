import React from 'react';

interface ComponentProps {
  type: string;
  props?: { [key: string]: any };
  children?: ComponentProps[];
  content?: string;
}

interface DynamicInputProps {
  component: ComponentProps;
  formData: Record<string, any>;
  onChange: (name: string, value: any) => void;
  validationErrors: Record<string, string>;
}

const DynamicInput: React.FC<DynamicInputProps> = ({ component, formData, onChange, validationErrors }) => {
  const { type, props = {}, children, content } = component;
  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    // @ts-ignore
    const value = type === 'checkbox' ? e.target?.checked : e.target.value;
    onChange(props.name, value);
  };
  const inputContainerStyle = {
    display: 'inline-block',
    marginRight: '10px',
  };
  const errorStyle = {
    color: 'red',
    fontSize: '0.8em',
    marginTop: '5px',
  };

  if (type === 'label') {
    return <label {...props}>{content}</label>;
  }

  const isRequired = props.required === true;
  const labelContent = isRequired ? (
    <span>
      {content}
      <span style={{ color: 'red' }}>*</span>
    </span>
  ) : content;

  if (['Wrapper', 'section', 'header', 'nav', 'main', 'aside', 'footer', 'div', 'ul', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'select', 'option', 'img', 'a', 'audio', 'video', 'fieldset', 'legend', 'details', 'summary'].includes(type)) {
    const CustomTag = type === 'Wrapper' ? 'div' : type as keyof JSX.IntrinsicElements;
    return (
      <CustomTag {...props} style={props.style}>
        {labelContent}
        {Array.isArray(children) &&
          children.map((child, index) => (
            <DynamicInput
              key={`${type}_${props.id || ''}_${index}`}
              component={child}
              formData={formData}
              onChange={onChange}
              validationErrors={validationErrors}
            />
          ))}
      </CustomTag>
    );
  }

  switch (type) {
    case 'a':
      return <a {...props} href={props.href} target={props.target} rel={props.rel}>{content}</a>;
    case 'checkbox':
      return (
        <label style={inputContainerStyle}>
          <input
            type="checkbox"
            {...props}
            checked={formData[props.name] || false}
            onChange={handleValueChange}
            className={'form-control form-control-solid'}
          />
          {labelContent}
          {validationErrors[props.name] && <div style={errorStyle}>{validationErrors[props.name]}</div>}
        </label>
      );
    case 'textarea':
      return (
        <div style={inputContainerStyle}>
          <textarea {...props} value={formData[props.name] || ''} onChange={handleValueChange} className={'form-control form-control-solid'} />
          {labelContent}
          {validationErrors[props.name] && <div style={errorStyle}>{validationErrors[props.name]}</div>}
        </div>
      );
    case 'radio':
      return (
        <div>
          {props.options.map((option, index) => (
            <label key={`${props.name}_${index}`} className={'me-3 radio-label'}>
              <input
                type="radio"
                name={props.name}
                value={option.value}
                checked={formData[props.name] === option.value}
                onChange={handleValueChange}
                className={'me-2'}
              />
              <div>{option.label}</div>
            </label>
          ))}
          {validationErrors[props.name] && <div style={errorStyle}>{validationErrors[props.name]}</div>}
        </div>
      );
    case 'input':
      return (
        <div style={inputContainerStyle}>
          <input
            {...props}
            value={formData[props.name] || ''}
            onChange={handleValueChange}
            required={props.required}
            className={'form-control form-control-solid'}
          />
          {labelContent}
          {validationErrors[props.name] && <div style={errorStyle}>{validationErrors[props.name]}</div>}
        </div>
      );
    case 'select':
      return (
        <div style={inputContainerStyle}>
          <select {...props} value={formData[props.name] || ''} onChange={handleValueChange} className={'form-control form-control-solid'}>
            {children?.map((option, index) => (
              <option key={`${props.name}_option_${index}`} value={option.props?.value}>
                {option.content}
              </option>
            ))}
          </select>
          {labelContent}
          {validationErrors[props.name] && <div style={errorStyle}>{validationErrors[props.name]}</div>}
        </div>
      );
    default:
      return null;
  }
};

export { DynamicInput };