/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'

type Props = {
  show: boolean
  onClose: () => void
  onConfirm: () => void
  message: string
  isActive: boolean
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalDeactivateConfirm = ({show, onClose, onConfirm, message, isActive}: Props) => {
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={onClose}
      backdrop={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div
          className='btn btn-sm btn-icon btn-active-color-primary'
          data-bs-dismiss='modal'
          onClick={onClose}
        >
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Confirm {isActive ? "Deactivation" : 'Activation'}</h1>

          <div className='text-muted fw-bold fs-5'>{message}</div>
        </div>

        <button
          onClick={onConfirm}
          type='button'
          className='btn btn-light-primary fw-bolder w-100 mb-8'
          value={'Delete'}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </button>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalDeactivateConfirm}
