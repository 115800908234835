import React from 'react';

interface AvatarProps {
  blankImg: string;
  publicUrl: string;
  avatarUpload: string | null;
  currentAvatar: string | null;
  avatarImage: string | null;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeAvatar: () => void;
}

const AvatarUpload: React.FC<AvatarProps> = ({
  blankImg,
  publicUrl,
  avatarUpload,
  currentAvatar,
  avatarImage,
  handleImageChange,
  removeAvatar,
}) => {
  return (
    <div className="image-input image-input-outline w-125px" data-kt-image-input="true" style={{ backgroundImage: `url('${blankImg}')` }}>
      {/* no uploaded avatar && company avatar */}
      {!avatarUpload && currentAvatar && (
        <div className="symbol-label">
          <img
            src={publicUrl + currentAvatar}
            alt="Company Avatar"
            className="w-100 rounded-1"
          />
        </div>
      )}

      {/* uploaded avatar && no company avatar */}
      {avatarUpload && !currentAvatar && (
        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${avatarImage})` }}></div>
      )}

      {/* uploaded avatar && company avatar */}
      {avatarUpload && currentAvatar && (
        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${avatarImage})` }}></div>
      )}

      {/* no uploaded avatar && no company avatar */}
      {!avatarUpload && !currentAvatar && (
        <div className="image-input-wrapper w-125px h-125px" style={{ backgroundImage: `url(${blankImg})` }}></div>
      )}

      {/* change avatar */}
      <label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
        <i className="bi bi-pencil-fill fs-7"></i>
        <input type="file" name="avatar" onChange={handleImageChange} />
        <input type="hidden" name="avatar_remove" />
      </label>

      {/* cancel upload */}
      <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
        <i className="bi bi-x fs-2"></i>
      </span>

      {/* remove avatar */}
      <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar" onClick={removeAvatar}>
        <i className="bi bi-x fs-2"></i>
      </span>
    </div>
  );
};

export default AvatarUpload;
