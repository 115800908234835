export interface IWorkspaceDetails {
  id: string
  name: string
  permission: Array<Object|null>
}

export const workspaceDetailsInitValues: IWorkspaceDetails = {
  id: '',
  name: '',
  permission: [],
}
