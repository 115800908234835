/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers'
import {useIntl} from 'react-intl'
import {ModalFeedback} from '../../../../app/partials/modals/feedback/ModalFeedback'
import {useState} from 'react'

const SidebarFooter = () => {
  const intl = useIntl()
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)

  return (
    <>
      <div>
        <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
          <a
            href="#"
            className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            data-bs-dismiss-="click"
            onClick={() => setShowFeedbackModal(true)}
            title={intl.formatMessage({id: 'MENU.FEEDBACK'})}
          >
            <span className="btn-label">{intl.formatMessage({id: 'MENU.FEEDBACK'})}</span>
            <KTIcon iconName="messages" className="btn-icon fs-2 ms-1" />
          </a>
        </div>
        <ModalFeedback show={showFeedbackModal} handleClose={() => setShowFeedbackModal(false)} />
      </div>
    </>
  )
}

export {SidebarFooter}
