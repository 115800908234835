import React, {FC, useEffect, useRef, useState} from 'react'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {KTIcon} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Form, Formik, FormikValues} from 'formik'
import {createAccountSchemas, ICreateAccount, inits} from './CreateAccountWizardHelper'

const Horizontal: FC = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(createAccountSchemas[0])
  const [initValues] = useState<ICreateAccount>(inits)
  const [isSubmitButton, setSubmitButton] = useState(false)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = (values: ICreateAccount, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }

    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

    setCurrentSchema(createAccountSchemas[stepper.current.currentStepIndex - 1])
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-links d-flex flex-column'
      id='kt_create_account_stepper'
    >
      <div className='stepper-nav mb-5'>

        <div className='stepper-item current' data-kt-stepper-element='nav'>
          <h3 className='stepper-title text-light'>step 1: choose a persona</h3>
        </div>

        <div className='stepper-item' data-kt-stepper-element='nav'>
          <h3 className='stepper-title text-light'>step 2: upload pitch deck</h3>
        </div>

        <div className='stepper-item' data-kt-stepper-element='nav'>
          <h3 className='stepper-title text-light'>step 3: make payment</h3>
        </div>

      </div>

      <Formik initialValues={initValues} onSubmit={submitStep}>
        {() => (
          <Form className='mx-auto w-100 pt-15 pb-10' id='kt_create_account_form'>
            <div className='current' data-kt-stepper-element='content'>
              <Step1 />
            </div>

            <div data-kt-stepper-element='content'>
              <Step2 />
            </div>

            <div data-kt-stepper-element='content'>
              <Step3 />
            </div>

            <div className='d-flex flex-stack pt-15'>
              <div className='mr-2'>
                <button
                  onClick={prevStep}
                  type='button'
                  className='btn btn-lg btn-secondary me-3'
                  data-kt-stepper-action='previous'
                >
                  <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                  Back
                </button>
              </div>

              <div>
                <button type='submit' className='btn btn-lg btn-secondary me-3'>
                  <span className='indicator-label'>
                    {!isSubmitButton && 'Continue'}
                    {isSubmitButton && 'Submit'}
                    <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export {Horizontal}
