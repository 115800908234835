/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-light'>
          Select your Persona
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Select the entrepreneur persona that you want to review your pitch deck below. Whoever you chose shall embody that persona it has been trained on full and provide insightful feedback on your business proposal.
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='col-lg-2'>
            <Field
              type='radio'
              className='btn-check'
              name='accountType'
              value='personal'
              id='kt_create_account_form_account_type_personal'
            />
            <label
              className='btn p-7 d-flex align-items-center mb-10'
              htmlFor='kt_create_account_form_account_type_personal'
            >
              <KTIcon iconName='people' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-light fw-bolder d-block fs-4 mb-2'>Alt Surge</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  AI Persona
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-2'>
            <Field
              type='radio'
              className='btn-check'
              name='accountType'
              value='corporate'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTIcon iconName='people' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-light fw-bolder d-block fs-4 mb-2'>Warren Buffet</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Business Man, Investor
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-2'>
            <Field
              type='radio'
              className='btn-check'
              name='accountType'
              value='corporate'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTIcon iconName='people' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-light fw-bolder d-block fs-4 mb-2'>Elon Musk</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Tesla, Billionaire
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-2'>
            <Field
              type='radio'
              className='btn-check'
              name='accountType'
              value='corporate'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTIcon iconName='people' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-light fw-bolder d-block fs-4 mb-2'>Bill Gates</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Business Man, Investor
                </span>
              </span>
            </label>
          </div>

          <div className='col-lg-2'>
            <Field
              type='radio'
              className='btn-check'
              name='accountType'
              value='corporate'
              id='kt_create_account_form_account_type_corporate'
            />
            <label
              className='btn p-7 d-flex align-items-center'
              htmlFor='kt_create_account_form_account_type_corporate'
            >
              <KTIcon iconName='people' className='fs-3x me-5' />

              <span className='d-block fw-bold text-start'>
                <span className='text-light fw-bolder d-block fs-4 mb-2'>Jeff Bezos</span>
                <span className='text-gray-400 fw-bold fs-6'>
                  Business Man, Investor
                </span>
              </span>
            </label>
          </div>

          <div className='text-danger mt-2'>
            <ErrorMessage name='accountType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
