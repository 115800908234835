/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'

const Workflow: FC = () => {
  return (
      <div className='card card-xxl-stretch mb-5 mb-xl-8'>
        <div className='card-body d-flex flex-column p-9'>
          <div className='h-650px'>
              <h3 className='fw-bolder'>
                  Under construction
              </h3>
          </div>
        </div>
      </div>
  )
}

export {Workflow}
