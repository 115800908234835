/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {ID, KTIcon, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import IPConfig from '../../../../../../store/IPConfig'
import { ModalDeactivateConfirm } from '../../../../../../partials/modals/ModalDeactivateConfirm'

type Props = {
  id: ID
  userID: string
  isActive: boolean
}

const UserActionsCell: FC<Props> = ({id, userID, isActive}) => {
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [failMessage, setFailMessage] = useState('')
  const {setItemIdForUpdate} = useListView()
  /* const {query} = useQueryResponse()
  const queryClient = useQueryClient() */

  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUser(userUpdated)
  }, [])

  const apiUrl = IPConfig()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
    if (typeof id !== 'undefined' && id !== null) {
      localStorage.setItem('userForEdit', id.toString())
    }
  }

  const deactivateUser = () => {
    const formData = new FormData()
    // @ts-ignore
    formData.append('user_id', userID)
    if (isActive) {
      formData.append('isActive', 'false')
    } else if (!isActive) {
      formData.append('isActive', 'true')
    }

    fetch(`${apiUrl}/updateuser`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'error') {
          setFailMessage(data.message)
        } else {
          setFailMessage('')
          setConfirmDeleteModalVisible(false)
          window.location.reload()
        }
      })
      .catch((error) => {
        console.error(error)
        setFailMessage('An unexpected error has occurred.')
      })
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTIcon iconName='down' className='fs-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
       
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='delete_row'
              onClick={() => {
                setFailMessage('')
                setConfirmDeleteModalVisible(true)
              }}
            >
              {isActive ? "Deactivate" : "Activate"}
            </a>
          </div>
        
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
      {confirmDeleteModalVisible && (
        <ModalDeactivateConfirm
          show={confirmDeleteModalVisible}
          onClose={() => setConfirmDeleteModalVisible(false)}
          onConfirm={() => {
            deactivateUser()
          }}
          message={failMessage ? failMessage : `Are you sure you want to ${isActive ? 'deactivate ' : 'activate ' }` + id + '?'}
          isActive={isActive}
        />
      )}
    </>
  )
}

export {UserActionsCell}
