/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {WorkspaceEditForm} from "./components/WorkspaceEditForm";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Workspaces',
    path: '/workspaces',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const WorkspaceFormWrapper: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <WorkspaceEditForm className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const WorkspaceFormEdit: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Update Workspace</PageTitle>
      <WorkspaceFormWrapper />
    </>
  )
}

export {WorkspaceFormEdit}
