/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  title: string
  description: string
  setConfirmDeleteModalVisible: any
  setDeleteItem: any
  file: any
}

const CardDocument: FC<Props> = ({title, description, setConfirmDeleteModalVisible, setDeleteItem, file}) => {
  return (
    <div className='card h-100'>

      <div className="d-flex justify-content-end flex-shrink-0">
        <a
          href="#"
          onClick={() => {
            setConfirmDeleteModalVisible(true)
            setDeleteItem([file.file_label, file.id])
          }}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3 mt-3 position-absolute"
        >
          <KTIcon iconName="trash" className="fs-3" />
        </a>
      </div>

      <div className='card-body d-flex justify-content-center text-center flex-column p-8'>
        <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
          <div className='symbol symbol-75px mb-6'>

            <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5 0C13.3687 0 11.625 1.74375 11.625 3.875V58.125C11.625 60.2562 13.3687 62 15.5 62H54.25C56.3812 62 58.125 60.2562 58.125 58.125V15.5L42.625 0H15.5Z"
                fill="#E2E5E7" />
              <path d="M46.5 15.5H58.125L42.625 0V11.625C42.625 13.7563 44.3687 15.5 46.5 15.5Z" fill="#B0B7BD" />
              <path d="M58.125 27.125L46.5 15.5H58.125V27.125Z" fill="#CAD1D8" />
              <path
                d="M50.375 50.375C50.375 51.4406 49.5031 52.3125 48.4375 52.3125H5.8125C4.74687 52.3125 3.875 51.4406 3.875 50.375V31C3.875 29.9344 4.74687 29.0625 5.8125 29.0625H48.4375C49.5031 29.0625 50.375 29.9344 50.375 31V50.375Z"
                fill={
                  file.file_label.split('.').pop().toUpperCase() === 'PDF' ?
                    '#F15642' : (file.file_label.split('.').pop().toUpperCase() === 'TXT' ? '#A066AA' : '#50BEE8')
                } />
              <path
                d="M48.4375 52.3125H11.625V54.25H48.4375C49.5031 54.25 50.375 53.3781 50.375 52.3125V50.375C50.375 51.4406 49.5031 52.3125 48.4375 52.3125Z"
                fill="#CAD1D8" />
              <text x="31" y="45" textAnchor="middle" fill="white" fontSize="12" fontWeight="bold">
                {file.file_label.split('.').pop().toUpperCase()}
              </text>
            </svg>

          </div>
          <div className='fs-5 fw-bolder mb-2'>{title}</div>
        </a>
        <div className='fs-7 fw-bold text-gray-400 mt-auto'>
          {new Date(description).toLocaleDateString('en-US', {
            weekday: 'short',
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: "numeric",
            minute: "numeric",
          })
          .replace(/,/g, '')}
        </div>
      </div>

    </div>
  )
}

export {CardDocument}
