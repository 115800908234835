export default function IPConfig(apiName: string = "dms") {
    const apiConfig = {
        dms: process.env.REACT_APP_API_URL_DMS,
        analyse: process.env.REACT_APP_API_URL_ANALYZE,
        ws: process.env.REACT_APP_API_URL_WS,
        metis: process.env.REACT_APP_API_URL_METIS,
        public: process.env.REACT_APP_PUBLIC_URL,
    };

    return apiConfig[apiName] || apiConfig.dms;
}