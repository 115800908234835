export interface IPermissionDetails {
  id: string
  permission: string
  roles: Array<Object>
}

export const permissionDetailsInitValues: IPermissionDetails = {
  id: '',
  permission: '',
  roles: [],
}
