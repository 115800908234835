/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from '../../../partials/modals/ModalDeleteConfirm'
import {
  companyDetailsInitValues as initialValues,
  ICompanyDetails,
} from '../../../models/CompanyModel'
import {TablePagination} from '../../../partials/tables/TablePagination'
import {TableLoading} from '../../../partials/tables/TableLoading'
import IPConfig from '../../../store/IPConfig'

type Props = {
  className: string
}

const TableCompanies: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<ICompanyDetails>(initialValues)
  const [isLoading, setIsLoading] = useState(false)
  const [companyArray, setCompanyArray] = useState([])
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [deleteItem, setDeleteItem] = useState([])
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [status, setStatus] = useState<string | undefined>()
  const [requestStatus, setRequestStatus] = useState<string | undefined>()

  const navigate = useNavigate()
  const apiUrl = IPConfig()
  const defaultStatus = '5'

  const [failMessage, setFailMessage] = useState('')

  useEffect(() => {
    fetchCompanies()
  }, [pagination.page, searchTerm, sortBy, sortOrder, requestStatus])

  const fetchCompanies = () => {
    setIsLoading(true)

    let url = `${apiUrl}/getallcompanies?page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`

    if (searchTerm) {
      url += `&search=${searchTerm}`
    }

    if (sortBy && sortOrder) {
      url += `&sort=${sortBy}&order=${sortOrder}`
    }

    if (requestStatus) {
      url += `&status=[${requestStatus}]`
    } else {
      url += `&status=[${defaultStatus}]`
    }

    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          const formattedData = response.data.map((company) => {
            const date = new Date(company.foundingDate)
            const formattedDate = date.toLocaleString('en-US', {
              month: 'short',
              year: 'numeric',
            })
            return {...company, displayFoundingDate: formattedDate}
          })

          setCompanyArray(formattedData)
        }

        if (response.payload && response.payload.pagination) {
          setPagination((prev) => ({...prev, total: response.payload.pagination.total}))
        } else {
          setCompanyArray([])
        }
      })
      .catch((err) => {
        console.log('Unable to fetch -', err)
      })

    setIsLoading(false)
  }

  const deleteCompany = (companyId) => {
    const formData = new FormData()
    formData.append('company_id', companyId)
    formData.append('status', '6')

    fetch(`${apiUrl}/updatecompany`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'error') {
          setFailMessage(data.message)
        } else {
          fetchCompanies()
          setConfirmDeleteModalVisible(false)
        }
      })
      .catch((error) => {
        console.error(error)
        setFailMessage('An unexpected error has occurred.')
      })
  }

  const addCompany = () => {
    localStorage.setItem('currentCompany', '{}')
    navigate('/companies/create')
  }

  const saveCompany = (index) => {
    localStorage.setItem('currentCompany', JSON.stringify(companyArray[index]))

    const updatedData = Object.assign(data, companyArray[index])
    setData(updatedData)

    navigate('/companies/overview')
  }

  const resetData = () => {
    setStatus(defaultStatus)
    setRequestStatus(defaultStatus)
  }

  const filterData = () => {
    setPagination({
      page: 1,
      total: 0,
      itemsPerPage: pagination.itemsPerPage,
    })
    setRequestStatus(status)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Companies</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {companyArray.length} companies listed
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a company'
        >
          <div className='me-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <a href='#' className='btn btn-light-primary me-3' onClick={addCompany}>
            <KTIcon iconName='plus' className='fs-3' />
            Add Company
          </a>

          <div>
            {/* begin::Filter Button */}
            <button
              disabled={isLoading}
              type='button'
              className='btn btn-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
            >
              <KTIcon iconName='filter' className='fs-2' />
              Filter
            </button>
            {/* end::Filter Button */}
            {/* begin::SubMenu */}
            <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
              {/* begin::Header */}
              <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
              </div>
              {/* end::Header */}

              {/* begin::Separator */}
              <div className='separator border-gray-200'></div>
              {/* end::Separator */}

              {/* begin::Content */}
              <div className='px-7 py-5' data-kt-user-table-filter='form'>
                {/* begin::Input group */}
                <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>Status:</label>
                  <select
                    className='form-select form-select-solid fw-bolder text-capitalize'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='role'
                    data-hide-search='true'
                    onChange={(e) => setStatus(e.target.value)}
                    value={status}
                  >
                    <option value='5,6'>All</option>
                    <option value='5' selected>
                      Active
                    </option>
                    <option value='6'>Archived</option>
                  </select>
                </div>
                {/* end::Input group */}

                {/* begin::Actions */}
                <div className='d-flex justify-content-end'>
                  <button
                    type='button'
                    disabled={isLoading}
                    onClick={resetData}
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    Reset
                  </button>
                  <button
                    type='button'
                    disabled={isLoading}
                    onClick={filterData}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Apply
                  </button>
                </div>
                {/* end::Actions */}
              </div>
              {/* end::Content */}
            </div>
            {/* end::SubMenu */}
          </div>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* Add search input */}

        {/* Add sorting controls */}
        {/*<div className="mb-3">
            <select
                className="form-select"
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value)}
            >
                <option value="">Sort By</option>
                <option value="companyName">Name</option>
                <option value="industrySector">Industry Sector</option>
                <option value="location">Location</option>
                <option value="foundingDate">Founding Date</option>
                <option value="registrationNumber">Registration Number</option>
            </select>
            <select
                className="form-select ms-2"
                value={sortOrder}
                onChange={(e) => setSortOrder(e.target.value)}
            >
                <option value="">Sort Order</option>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
            </select>
        </div>*/}
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Name</th>
                <th className='min-w-140px'>Industry Sector</th>
                <th className='min-w-120px'>Location</th>
                <th className='min-w-120px'>Founding Date</th>
                <th className='min-w-120px'>Registration Number</th>
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {companyArray.map((content, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center' onClick={() => saveCompany(index)}>
                      <div className='symbol symbol-50px overflow-hidden me-3'>
                        <a href='#'>
                          {content['avatar'] ? (
                            <div className='symbol-label'>
                              <img
                                src={toAbsoluteUrl(IPConfig('public') + content['avatar'])}
                                alt={content['companyName']}
                                className='w-100'
                              />
                            </div>
                          ) : (
                            <span className='symbol-label bg-light-success'>
                              <KTIcon iconName='office-bag' className='fs-2x text-success' />
                            </span>
                          )}
                        </a>
                      </div>

                      <div className='d-flex justify-content-start flex-column'>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary mb-1 fs-6 text-capitalize'
                        >
                          {content['companyName']}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Company
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveCompany(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {content['industrySector']}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveCompany(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {content['location']}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveCompany(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {content['displayFoundingDate']}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveCompany(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {content['registrationNumber']}
                    </a>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveCompany(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6'
                    >
                      {content['status']['name'] === 'active' && (
                        <span className='badge badge-light-success fs-7 mb-2'>
                          {content['status']['name']}
                        </span>
                      )}
                      {content['status']['name'] === 'archived' && (
                        <span className='badge badge-light-danger fs-7 mb-2'>
                          {content['status']['name']}
                        </span>
                      )}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        onClick={() => saveCompany(index)}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        onClick={() => {
                          setFailMessage('')
                          setConfirmDeleteModalVisible(true)
                          setDeleteItem([
                            // @ts-ignore
                            content['companyName'],
                            // @ts-ignore
                            content.id,
                          ])
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {!companyArray ||
                (companyArray.length === 0 && (
                  <tr className='w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2'>
                    <td colSpan={6}>No matching records found</td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
        {pagination && pagination.total > 0 && companyArray && companyArray.length > 0 && (
          <TablePagination pagination={pagination} setPagination={setPagination} />
        )}
        {isLoading && <TableLoading />}
      </div>
      {/* begin::Body */}
      {confirmDeleteModalVisible && (
        <ModalDeleteConfirm
          show={confirmDeleteModalVisible}
          onClose={() => setConfirmDeleteModalVisible(false)}
          onConfirm={() => {
            deleteCompany(deleteItem[1])
          }}
          message={
            failMessage ? failMessage : 'Are you sure you want to archive ' + deleteItem[0] + '?'
          }
        />
      )}
    </div>
  )
}

export {TableCompanies}
