import React, {FC} from 'react'
import {ErrorMessage, Field} from 'formik'
import {KTIcon} from '../../../../../_metronic/helpers'

const Step2: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-light'>Upload your Document Securely</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          Please upload the pitch deck you wish a report on.
        </div>
      </div>

      <div className='mb-10 fv-row'>

        {/* begin::Form group */}
        <div className='mb-8'>
          <label className='form-check form-check-inline text-light' htmlFor='kt_login_toc_agree'>
            <input
              className='form-check-input'
              type='checkbox'
              id='kt_login_toc_agree'
            />
            <span>
            I agree to the{' '}
              <a
                href='/terms-and-conditions'
                target='_blank'
                className='ms-1 alt-white fw-bold'
              >
              terms and conditions
            </a>
            .
          </span>
          </label>
        </div>
        {/* end::Form group */}

        <button type='submit' className='btn btn-lg alt-bg-purple alt-white me-3 float-start'>
          <span className='indicator-label'>
            Upload Document
          </span>
        </button>

        <div className='alt-white fw-bold mt-6 pt-4'>
          <span>
            <KTIcon iconName='information' className='d-block float-start fs-3 ms-2 mt-0 me-1' />
            Guideline for document upload
          </span>
        </div>

      </div>

    </div>
  )
}

export {Step2}
