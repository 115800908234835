/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {createPortal} from "react-dom";
import {Modal} from "react-bootstrap";
import clsx from "clsx";
import IPConfig from "../../../store/IPConfig";

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ModalFeedback = ({show, handleClose}: Props) => {
  const [feedback, setFeedback] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [failMessage, setFailMessage] = useState('');
  const [validationError, setValidationError] = useState('');

  const apiUrl = IPConfig()

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleSurnameChange = (e) => {
    setSurname(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const clearFormAndMessages = () => {
    setFeedback('');
    setName('');
    setSurname('');
    setEmail('');
    setSuccessMessage('');
    setFailMessage('');
    setValidationError('');
  };

  const validateInputs = () => {
    if (!name.trim() || !surname.trim() || !email.trim() || !feedback.trim()) {
      setValidationError("All fields are required.");
      return false;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setValidationError("Please enter a valid email address.");
      return false;
    }
    setValidationError('');
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFailMessage('');
    setSuccessMessage('');
    setValidationError('');
    if (validateInputs()) {
      sendFeedback(name, surname, email, feedback);
    }
  };

  const sendFeedback = (name, surname, email, message) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("surname", surname);
    formData.append("email", email);
    formData.append("message", message);

    fetch(`${apiUrl}/feedback`, {
      method: "POST",
      body: formData,
    })
        .then(response => response.json())
        .then(response => {
          if (response.status === "success"){
            setSuccessMessage("Feedback submitted successfully!");
          } else {
            setFailMessage("Failed to submit feedback.");
          }
        })
        .catch(error => {
          console.error(error);
          setFailMessage("Failed to submit feedback.");
        });
  };

  return createPortal(
    <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={show}
        onHide={() => {
          handleClose();
          clearFormAndMessages();
        }}
        backdrop={true}
    >
      <div className='modal-header pb-0 border-0 justify-content-end'>
        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={() => {
          handleClose();
          clearFormAndMessages();
        }}>
          <KTIcon iconName='cross' className='fs-1' />
        </div>
      </div>

      <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>Feedback</h1>

          <div className='text-muted fw-bold fs-5'>
            Let us know how we can improve
          </div>
        </div>

        <form onSubmit={handleSubmit}>

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>First Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
                placeholder='First name'
                type='text'
                value={name}
                onChange={handleNameChange}
                name='name'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Last Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
                placeholder='Last name'
                type='text'
                value={surname}
                onChange={handleSurnameChange}
                name='surname'
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Email Address</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
                placeholder='Email address'
                type='email'
                name='email'
                value={email}
                onChange={handleEmailChange}
                className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                autoComplete='off'
            />
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Message</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
                className='form-control form-control-solid mb-3 mb-lg-0'
                rows={3}
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder='Type your feedback here'
            ></textarea>
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {validationError && <p className="form-field-feedback fail">{validationError}</p>}
          {successMessage && <p className="form-field-feedback success">{successMessage}</p>}
          {failMessage && <p className="form-field-feedback fail">{failMessage}</p>}

          <input type={"submit"} className='btn btn-light-primary fw-bolder w-100 mb-8' value={"Send Feedback"} />

        </form>

      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalFeedback}
