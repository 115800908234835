// @ts-nocheck
import {FC} from 'react'
import {ColumnInstance} from 'react-table'
import {User} from '../../core/_models'

type Props = {
  column: ColumnInstance<User>
  key: string
}

const CustomHeaderColumn: FC<Props> = ({column, key}) => (
  <>
    {column.Header && column.Header !== 'string'? (
      <th {...column.getHeaderProps()} className='mb-0 pb-0 mt-0 pt-0 border-0'>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {CustomHeaderColumn}
