/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import IPConfig from '../../../store/IPConfig'
import {KTIcon} from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from '../../../partials/modals/ModalDeleteConfirm'
import {
  documentDetailsInitValues as initialValues,
  IDocumentDetails,
} from '../../../models/DocumentModel'
import {TableLoading} from '../../../partials/tables/TableLoading'
import {TablePagination} from '../../../partials/tables/TablePagination'
import {Modal} from 'react-bootstrap'
import MultiSelectDropdown from '../../../partials/widgets/MultiSelectDropdown'

type Props = {
  className: string
}

const TableCollections: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<IDocumentDetails>(initialValues)
  const [documentArray, setDocumentArray]: any = useState<[]>([])
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false)
  const [editModalName, setEditModalName] = useState('')
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [showAddDocumentModal, setShowAddDocumentModal] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState([])
  const [editItem, setEditItem] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [collectionDescription, setCollectionDescription] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [categoryArray, setCategoryArray]: any = useState([])
  const [vectorized, setVectorized] = useState<boolean>(false);

  const [editErrorMessage, setEditErrorMessage] = useState('')
  const [editDescription, setEditDescription] = useState('')
  const [permissions, setPermissions]: any = useState([])

  const [newCollectionPermissions, setNewCollectionPermissions] = useState<string[]>([])
  const [selectPermissions, setSelectPermissions]: any = useState([])
  const [user, setUser]: any = useState()

  const navigate = useNavigate()
  const apiUrl = IPConfig()

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUser(userUpdated)
  }, [])

  useEffect(() => {
    fetchDocuments()
  }, [pagination.page, searchTerm, sortBy, sortOrder, user])

  const fetchDocuments = () => {
    if (user) {
      setIsLoading(true)

      // Construct API URL based on pagination, search, and sorting parameters
      let url = `${apiUrl}/getallcollections?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`

      if (searchTerm) {
        url += `&search=${searchTerm}`
      }

      if (sortBy && sortOrder) {
        url += `&sort=${sortBy}&order=${sortOrder}`
      }

      if (user && user.id) {
        url += `&user_id=${user.id}`
      }

      fetch(url)
        .then((response) => response.json())
        .then((response) => {
          if (response.data) {
            setDocumentArray(response.data)
          }
          if (response.payload && response.payload.pagination) {
            setPagination((prev) => ({...prev, total: response.payload.pagination.total}))
          } else {
            setDocumentArray([])
          }
        })
        .catch(function (err) {
          console.log('Unable to fetch -', err)
        })

      setIsLoading(false)
    }
  }

  const deleteDocument = (documentId) => {
    const formData = new FormData()
    formData.append('collection_id', documentId)

    fetch(`${apiUrl}/deletecollection`, {
      method: 'DELETE',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        fetchDocuments()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const fetchPermissions = () => {
    fetch(`${apiUrl}/get-permission?all=true&items_per_page=100`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          setPermissions(data.data)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const saveDocument = (index) => {
    localStorage.setItem('currentDocument', JSON.stringify(documentArray[index]))

    const updatedData = Object.assign(data, documentArray[index])
    setData(updatedData)

    navigate('/documents/view')
  }

  function validateString(str) {
    if (!str) {
      setErrorMessage('Please enter a collection name.')
    }

    if (str.length < 3 || str.length > 63) {
      setErrorMessage('Collection name must be a minimum of 3 characters and a maximum of 63.')
      return false
    }

    if (!/^[a-zA-Z0-9].*[a-zA-Z0-9]$/.test(str)) {
      setErrorMessage(
        'Invalid collection name. Collection name may only start and end with alphanumeric characters.'
      )
      return false
    }

    if (!/^[a-zA-Z0-9_-]+$/.test(str)) {
      setErrorMessage(
        'Invalid collection name. Collection name may only contain alphanumeric characters, underscores or hyphens.'
      )
      return false
    }

    return true
  }

  function addCategory() {
    if (!validateString(selectedCategory.trim())) {
      return
    }

    if (!collectionDescription.trim()) {
      setErrorMessage('Please insert a collection description.')
      return
    }

    setErrorMessage('')

    if (selectedCategory) {
      const formData = new FormData()
      formData.append('collection_name', selectedCategory)
      formData.append('collection_description', collectionDescription)
      formData.append('vectorized', vectorized.toString())
      formData.append('permissions', JSON.stringify(newCollectionPermissions))

      fetch(`${apiUrl}/addcollection`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 'fail' || response.status === 'error') {
            setErrorMessage('An error has occurred: ' + response.message)
          } else {
            categoryArray.push(response.data)
            setCategoryArray(categoryArray)
            setSelectedCategory('')
            setCollectionDescription('')
            setNewCollectionPermissions([])
            fetchDocuments()
            setShowAddDocumentModal(false)
          }
        })
        .catch((error) => {
          setErrorMessage('An unexpected error has occurred')
          console.error(error)
        })
    } else {
      alert('Please insert a category name.')
    }
  }

  useEffect(() => {
    if (editModalVisible) {
      fetchPermissions()
      setEditDescription(editItem[2])
      setSelectPermissions([...editItem[3]])
    } else if (showAddDocumentModal) {
      fetchPermissions()
    } else {
      setEditDescription('')
      setSelectPermissions('')
      setEditItem([])
      setEditErrorMessage('')
    }
  }, [editModalVisible, showAddDocumentModal])

  const editKnowledgeBase = () => {
    fetch(
      `${apiUrl}/update-collection?id=${editItem[1]}&permissions=${JSON.stringify(
        selectPermissions
      )}&collection_description=${editDescription}`,
      {
        method: 'POST',
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          setEditModalVisible(false)
          fetchDocuments()
        } else {
          setEditErrorMessage('An error has occurred: ' + response.message)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Knowledge Base</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            {documentArray.length} documents listed
          </span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a document'
        >
          <div className='me-3'>
            <input
              type='text'
              className='form-control'
              placeholder='Search...'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <a
            href='#'
            className='btn btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_add_document'
            onClick={() => setShowAddDocumentModal(true)}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Collection
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-140px'>Description</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>
              {documentArray.map((document, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center' onClick={() => saveDocument(index)}>
                      <div className='symbol symbol-50px me-5'>
                        <span className='symbol-label bg-light-success'>
                          <KTIcon iconName='document' className='fs-2x text-success' />
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary mb-1 fs-6 text-capitalize'
                        >
                          {document['collection_name'].replace('_', ' ')}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Document
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a
                      href='#'
                      onClick={() => saveDocument(index)}
                      className='text-dark fw-bold text-hover-primary d-block fs-6 text'
                    >
                      {document['collection_description']}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        href='#'
                        onClick={() => {
                          setEditModalName(document['collection_name'])
                          setEditItem([
                            // @ts-ignore
                            document['collection_name'],
                            // @ts-ignore
                            document['id'],
                            // @ts-ignore
                            document['collection_description'],
                            // @ts-ignore
                            document['permissions'],
                          ])
                          setEditModalVisible(true)
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        onClick={() => saveDocument(index)}
                        className='btn btn-icon btn-bg-light btn-sm me-1'
                      >
                        <KTIcon iconName='eye' className='fs-3' />
                      </a>
                      <a
                        href='#'
                        onClick={() => {
                          setConfirmDeleteModalVisible(true)
                          setDeleteItem([
                            // @ts-ignore
                            document['collection_name'],
                            // @ts-ignore
                            document['id'],
                          ])
                        }}
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {!documentArray ||
                (documentArray.length === 0 && (
                  <tr className='w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2'>
                    <td colSpan={6}>No matching records found</td>
                  </tr>
                ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {pagination && pagination.total > 0 && documentArray && documentArray.length > 0 && (
          <TablePagination pagination={pagination} setPagination={setPagination} />
        )}
        {isLoading && <TableLoading />}
      </div>
      {/* begin::Body */}

      <div>
        <Modal
          id='kt_modal_create_app'
          tabIndex={-1}
          aria-hidden='true'
          dialogClassName='modal-dialog modal-dialog-centered mw-900px'
          show={showAddDocumentModal}
          onHide={() => setShowAddDocumentModal(false)}
          backdrop={true}
        >
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div
              className='btn btn-sm btn-icon btn-active-color-primary'
              data-bs-dismiss='modal'
              onClick={() => setShowAddDocumentModal(false)}
            >
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Add Collection</h1>

              <div className='text-muted fw-bold fs-5'>Create a new document collection</div>
            </div>

            <div className='mb-13'>
              <div>
                <div className="fv-row mb-7">
                  {/* begin::Label */}
                  <label className="fw-bold fs-6 mb-2 required">Name:</label>
                  {/* end::Label */}
                  <input
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="form-control form-control-solid mt-3 mb-3 modal-input"
                    placeholder="Enter collection name"
                  />
                </div>

                <div className="fv-row mb-7">
                  {/* begin::Label */}
                  <label className="fw-bold fs-6 mb-2 required">Description:</label>
                  {/* end::Label */}
                  <textarea
                    value={collectionDescription}
                    onChange={(e) => setCollectionDescription(e.target.value)}
                    className="form-control form-control-solid mb-3 modal-textarea"
                    placeholder="Enter collection description"
                  />
                </div>

                <MultiSelectDropdown
                  items={permissions.map(p => ({name: p.permission}))}
                  selectedItems={newCollectionPermissions}
                  onItemToggle={(permissionName) => {
                    if (newCollectionPermissions.includes(permissionName)) {
                      setNewCollectionPermissions(newCollectionPermissions.filter(name => name !== permissionName))
                    } else {
                      setNewCollectionPermissions([...newCollectionPermissions, permissionName])
                    }
                  }}
                  placeholder="Select permissions"
                  label="Permissions"
                  isRequired={false}
                />

                <div className="fv-row mb-7">
                  <div className="d-flex align-items-center mb-8">
                    {/* begin::Bullet */}
                    <span className="bullet bullet-vertical h-40px bg-primary"></span>
                    {/* end::Bullet */}
                    {/* begin::Checkbox */}
                    <div className="form-check form-check-custom form-check-solid mx-5">
                      <input className="form-check-input" type="checkbox" value="" checked={vectorized}
                             onChange={() => setVectorized(!vectorized)} />
                    </div>
                    {/* end::Checkbox */}
                    {/* begin::Description */}
                    <div className="flex-grow-1">
                      <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">
                        Searchable / Vectorized
                      </a>
                      <span
                        className="text-muted fw-semibold d-block">Searchable / Vectorized enabled for Chatbot</span>
                    </div>
                    {/* end::Description */}
                  </div>
                </div>

                <div className="fv-row">
                  {errorMessage && (
                    <p className="form-field-feedback text-danger fail">{errorMessage}</p>
                  )}

                  <input
                    type={'button'}
                    className="btn btn-light-primary fw-bolder w-100 mb-8"
                    value={'Add Collection'}
                    onClick={() => {
                      addCategory()
                    }}
                  />
                </div>

              </div>
            </div>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          id="kt_modal_create_app"
          tabIndex={-1}
          aria-hidden="true"
          dialogClassName="modal-dialog modal-dialog-centered mw-900px"
          show={editModalVisible}
          onHide={() => setEditModalVisible(false)}
          backdrop={true}
        >
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => setEditModalVisible(false)}
            >
              <KTIcon iconName="cross" className="fs-1" />
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
            <div className="text-center mb-13">
              <h1 className="mb-3">Edit {editModalName.replaceAll('_', ' ')}</h1>

              <div className="text-muted fw-bold fs-5">Modifying {editModalName.replaceAll('_', ' ')} Collection</div>
            </div>

            <div className="mb-13">

              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="fw-bold fs-6 mb-2 required">Description:</label>
                {/* end::Label */}
                <textarea
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                  className="form-control form-control-solid mb-3 modal-textarea"
                  placeholder="Insert new collection description (Required)"
                />
              </div>

              <div className="fv-row mb-7">
                <MultiSelectDropdown
                  items={permissions.map(p => ({ name: p.permission }))}
                  selectedItems={selectPermissions}
                  onItemToggle={(permissionName) => {
                    if (selectPermissions.includes(permissionName)) {
                      setSelectPermissions(selectPermissions.filter(name => name !== permissionName))
                    } else {
                      setSelectPermissions([...selectPermissions, permissionName])
                    }
                  }}
                  placeholder="Select permissions"
                  label="Permissions"
                  isRequired={false}
                />
              </div>

              <div className="fv-row mb-7">
                <div className="d-flex align-items-center mb-8">
                  {/* begin::Bullet */}
                  <span className="bullet bullet-vertical h-40px bg-primary"></span>
                  {/* end::Bullet */}
                  {/* begin::Checkbox */}
                  <div className="form-check form-check-custom form-check-solid mx-5">
                    <input className="form-check-input" type="checkbox" value="" checked={vectorized} onChange={() => setVectorized(!vectorized)} />
                  </div>
                  {/* end::Checkbox */}
                  {/* begin::Description */}
                  <div className="flex-grow-1">
                    <a href="#" className="text-gray-800 text-hover-primary fw-bold fs-6">
                      Searchable / Vectorized
                    </a>
                    <span className="text-muted fw-semibold d-block">Searchable / Vectorized enabled for Chatbot</span>
                  </div>
                  {/* end::Description */}
                </div>
              </div>

              <div className="fv-row">
                {editErrorMessage && (
                  <p className="form-field-feedback text-danger fail mt-8">{editErrorMessage}</p>
                )}
                <input
                  type={'button'}
                  className="btn btn-light-primary fw-bolder w-100 mb-8 mt-3"
                  value={'Update Collection'}
                  onClick={() => {
                    editKnowledgeBase()
                  }}
                />
              </div>

            </div>
          </div>
        </Modal>
      </div>

      {confirmDeleteModalVisible && (
        <ModalDeleteConfirm
          show={confirmDeleteModalVisible}
          onClose={() => setConfirmDeleteModalVisible(false)}
          onConfirm={() => {
            setConfirmDeleteModalVisible(false)
            deleteDocument(deleteItem[1])
          }}
          message={'Are you sure you want to delete ' + deleteItem[0] + '?'}
        />
      )}
    </div>
  )
}

export {TableCollections}
