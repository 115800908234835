/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import IPConfig from "../../../store/IPConfig";
import { KTIcon } from '../../../../_metronic/helpers'
import {queueDetailsInitValues as initialValues, IQueueDetails} from "../../../models/QueueModel";
import {TablePagination} from "../../../partials/tables/TablePagination";
import {TableLoading} from "../../../partials/tables/TableLoading";

type Props = {
  className: string
}

const TableQueues: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<IQueueDetails>(initialValues)
  const [queueArray, setQueueArray] = useState<IQueueDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const navigate = useNavigate();
  const apiUrl = IPConfig();

  useEffect(() => {
    fetchQueues();
  }, [pagination.page, searchTerm, sortBy, sortOrder]);

  const fetchQueues = () => {
    setIsLoading(true);

    // Construct API URL based on pagination, search, and sorting parameters
    let url = `${apiUrl}/get-unique-queue?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`;

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (sortBy && sortOrder) {
      url += `&sort=${sortBy}&order=${sortOrder}`;
    }

    fetch(url)
        .then(response => response.json())
        .then(response => {
          if (response.data) {
            setQueueArray(response.data);
          }
          if (response.payload && response.payload.pagination) {
            setPagination((prev) => ({...prev, total: response.payload.pagination.total}));
          } else {
            setQueueArray([]);
          }
        })
        .catch(function (err) {
          console.log("Unable to fetch -", err);
        });

    setIsLoading(false);
  };

  const saveQueue = (index) => {
    localStorage.setItem("currentQueue", JSON.stringify(queueArray[index]));

    const updatedData = Object.assign(data, queueArray[index])
    setData(updatedData)

    navigate("/queues/view");
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Queues</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{queueArray.length} queues listed</span>
        </h3>
        <div
            className='card-toolbar'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Click to search queues'
        >
          <div className="me-3">
            <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
            <tr className="fw-bold text-muted">
              <th className="min-w-140px">Name</th>
              <th className="min-w-120px">Jobs Pending</th>
              <th className="min-w-120px">Jobs Processing</th>
              <th className="min-w-120px">Jobs Failed</th>
              <th className="min-w-120px">Jobs Completed</th>
              <th className="min-w-120px">Jobs Archived</th>
              <th className="min-w-100px text-end">Actions</th>
            </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>

              {queueArray.map((queue, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center" onClick={() => saveQueue(index)}>
                      <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-light-success">
                          <KTIcon iconName="setting" className="fs-2x text-success" />
                        </span>
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6">
                          {queue['name']}
                        </a>
                        <span className="text-muted fw-semibold text-muted d-block fs-7">
                          Queue
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveQueue(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      <span
                        className="badge badge-light-primary fs-7">{queue['total_pending'] ? queue['total_pending'] : 0}</span>
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveQueue(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      <span
                        className="badge badge-light-info fs-7">{queue['total_processing'] ? queue['total_processing'] : 0}</span>
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveQueue(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      <span
                        className="badge badge-light-danger fs-7">{queue['total_failed'] ? queue['total_failed'] : 0}</span>
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveQueue(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      <span
                        className="badge badge-light-success fs-7">{queue['total_completed'] ? queue['total_completed'] : 0}</span>
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => saveQueue(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      <span
                        className="badge badge-light-success fs-7">{queue['total_archived'] ? queue['total_archived'] : 0}</span>
                    </a>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end flex-shrink-0">
                      <a
                        href="#"
                        onClick={() => saveQueue(index)}
                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      >
                        <KTIcon iconName="eye" className="fs-3" />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {!queueArray || queueArray.length === 0 && (
                <tr className="w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2">
                  <td colSpan={6}>
                    No matching records found
                  </td>
                </tr>
              )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {(pagination && pagination.total > 0 && queueArray && queueArray.length > 0) && (
          <TablePagination
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {isLoading && <TableLoading />}
      </div>
      {/* begin::Body */}

    </div>
  )
}

export {TableQueues}
