import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

interface Item {
  name: string;
  [key: string]: any;
}

interface MultiSelectDropdownProps {
  items: Item[];
  selectedItems: string[];
  onItemToggle: (itemName: string) => void;
  placeholder: string;
  label: string;
  isRequired?: boolean;
  marginBottom?: boolean;
}

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  items,
  selectedItems,
  onItemToggle,
  placeholder,
  label,
  isRequired = false,
  marginBottom = true
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredItems = items.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const toggleDropdown = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSearchTerm('');
    }
  };

  const handleItemClick = (itemName: string) => {
    onItemToggle(itemName);
  };

  return (
    <div className={clsx('fv-row', {'mb-7': marginBottom})}>
      <label className={clsx('fw-bold fs-6 mb-2', {'required': isRequired})}>{label}</label>

      <div className='custom-dropdown' ref={dropdownRef}>
        <input
          type="text"
          className='text-left form-control form-control-solid modal-textarea text-muted'
          placeholder={selectedItems.length === 0 ? placeholder : ''}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClick={toggleDropdown}
        />

        {selectedItems.length > 0 && (
          <div className='d-flex flex-wrap gap-2 mt-2'>
            {selectedItems.map((item) => (
              <div className='badge badge-primary z-3' key={item}>
                {item}
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    handleItemClick(item);
                  }}
                  className='ms-1 cursor-pointer'
                >
                  x
                </div>
              </div>
            ))}
          </div>
        )}

        {isOpen && (
          <div
            className={clsx('multi-select-dropdown text-light position-absolute form-control theme-light-bg-body theme-dark-bg-body custom-dropdown-menu p-4 shadow-sm rounded', {'show': isOpen})}
          >
            {filteredItems.map((item) => (
              <div className="form-control form-control-solid text-light custom-checkbox mt-2 mb-2" key={item.name}>
                <input
                  type='checkbox'
                  id={`item_${item.name}`}
                  checked={selectedItems.includes(item.name)}
                  onChange={() => handleItemClick(item.name)}
                  className='mutli-select-checkbox'
                />
                <label htmlFor={`item_${item.name}`} className="ms-2 text-dark">{item.name}</label>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelectDropdown;