/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ComponentProps, useEffect, useState} from 'react';
import IPConfig from "../../../store/IPConfig";
import {DynamicInput} from "../../../partials/ReactEngineComponent";
import {useNavigate} from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {KTIcon} from '../../../../_metronic/helpers'
import DocumentViewer from './DocumentViewer'

type Props = {
  className: string
}

interface FormConfig {
  // @ts-ignore
  components: ComponentProps[];
  submitUrl?: string;
  validationUrl?: string;
  submitButtonText?: string;
}

const TableTaskView: React.FC<Props> = ({className}) => {
  const currentTask =  JSON.parse(localStorage.getItem("currentTask") || '{}');
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [documentArray, setDocumentArray] = useState<[object]>([{}]);
  const [markdownArray, setMarkdownArray]: any = useState([]);
  const [expandedCards, setExpandedCards] = useState<Record<number, boolean>>({});
  const [editModes, setEditModes] = useState<Record<number, boolean>>({});
  const [currentEdit, setCurrentEdit] = useState<string>('');

  const navigate = useNavigate();
  const apiUrl = IPConfig();

  useEffect(() => {
    setFormConfig(currentTask.json_to_render);
    setDocumentArray(currentTask.source_documents);

    for (let i = 0; i < currentTask.source_documents.length; i++) {
      const documentId = currentTask.source_documents[i].id;
      fetchFile(documentId);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    formData.job_id = currentTask.job_id;
    formData.task_name = currentTask.name;
    formData.response = currentEdit;

    if (formConfig && !formConfig.components) {

      const submitResponse = await fetch(`${apiUrl}/add-react-engine-json-response`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const newFormConfig = await submitResponse.json();
      if (newFormConfig.status === "success") {
        navigate('/tasks')
        //setFormConfig(newFormConfig);
        setFormData({});
        setValidationErrors({});
      }

    } else {

      let allFieldsValid = true;
      let errors = {};
      if (formConfig) {
        for (const component of formConfig.components) {
          if (component.props?.required && !formData[component.props.name]) {
            errors[component.props.name] = 'This field is required';
            allFieldsValid = false;
          }
        }
      }

      setValidationErrors(errors);

      if (!allFieldsValid) {
        return;
      }

      // Check if a validationUrl exists in the form configuration
      if (formConfig && formConfig.validationUrl) {
        try {
          // Perform validation using validationUrl
          const validationResponse = await fetch(formConfig.validationUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });

          // Parse validation response as JSON
          const validationData = await validationResponse.json();

          if (validationData.status === 'ok') {
            // Validation is successful, fetch the next form using submitUrl
            // @ts-ignore
            const submitResponse = await fetch(formConfig.submitUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            });

            // Update formConfig with the next form configuration
            const newFormConfig = await submitResponse.json();
            if (newFormConfig.status === "success") {
              navigate('/tasks')
              //setFormConfig(newFormConfig);
              setFormData({});
              setValidationErrors({});
            }
          } else if (validationData.messages) {
            // Validation failed, display the error messages to the user
            setValidationErrors({ validationErrors: validationData.messages });
          }
        } catch (error) {
          console.error('Validation or submission failed', error);
        }

      } else {

        // No validationUrl, proceed to fetch the next form using submitUrl
        try {
          if (formConfig) {
            // @ts-ignore
            const submitResponse = await fetch(formConfig.submitUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(formData),
            });
            const newFormConfig = await submitResponse.json();
            if (newFormConfig.status === "success") {
              navigate('/tasks')
              //setFormConfig(newFormConfig);
              setFormData({});
              setValidationErrors({});
            }
          }
        } catch (error) {
          console.error('Submission failed', error);
        }

      }

    }

  };

  const handleToggleCard = (index: number) => {
    setExpandedCards(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
    if (validationErrors[name]) {
      setValidationErrors({ ...validationErrors, [name]: '' });
    }
  };

  const handleButtonClick = async (buttonValue) => {
    try {
      // @ts-ignore
      const response = await fetch(formConfig.submitUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ buttonId: buttonValue }),
      });
      const newFormConfig = await response.json();
      setFormConfig(newFormConfig);
    } catch (error) {
      console.error('Button submission failed', error);
    }
  };

  const toggleEditMode = (documentId: number) => {
    setEditModes(prevEditModes => ({
      ...prevEditModes,
      [documentId]: !prevEditModes[documentId]
    }));
  };

  const fetchFile = (documentId: number | string) => {
    fetch(`${apiUrl}/getfilecontent?id=` + documentId)
      .then(response => response.json())
      .then(response => {
        if (response.data) {
          setMarkdownArray(prevState => ({
            ...prevState,
            [documentId]: response.data
          }));
        }
      })
      .catch(function (err) {
        console.log("Unable to fetch -", err);
      });
  };

  const downloadDocument = async (documentId: number | string) => {
    try {
      const url = `${apiUrl}/download?fileid=${documentId}`;
      const response = await fetch(url);
      const blob = await response.blob();

      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      // @ts-ignore
      link.setAttribute('download', documentId + '.txt');
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      alert('Failed to download document');
    }
  };

  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 text-capitalize'>{currentTask.name}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{currentTask.workspace_name}</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3 mb-6'>
          <div>
            {currentTask.user_instructions}
          </div>
        </div>
        {/* end::Body */}
      </div>

      <div className='row'>

        {documentArray.map((document: any, index) => (

          <div className={documentArray.length === 1 ? 'col-lg-12' : 'col-lg-12'}>
            <div className='card mb-5 mb-xl-10'>
              <div
                className="card-header border-0 cursor-pointer"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target={`#kt_task_document_overview_${index}`}
                aria-expanded={expandedCards[index] ? "true" : "false"}
                aria-controls={`kt_task_document_overview_${index}`}
                onClick={() => handleToggleCard(index)}
              >
                <div className="card-title m-0">
                  <h3 className="fw-bolder m-0">Document Overview: {document.display_name}</h3>
                </div>

                <div
                  className="card-toolbar"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-trigger="hover"
                  title="Click to add a job"
                >
                  <div>
                    <KTIcon
                      iconName={expandedCards[index] ? 'up' : 'down'}
                      className="fs-2tx text-primary"
                    />
                  </div>
                </div>

              </div>

              <div id={`kt_task_document_overview_${index}`} className="collapse show">
                <div className="card-body border-top p-9">

                  <div className="row mb-6">
                    <div className="col-lg-12 fv-row">
                      {editModes[document.id] ? (
                        <textarea
                          value={markdownArray[document.id]}
                          onChange={(e) => {
                            const value = e.target.value;
                            setMarkdownArray(prevState => ({
                              ...prevState,
                              [document.id]: value
                            }));
                            setCurrentEdit(value);
                          }}
                          rows={30}
                          className="form-control"
                        />
                      ) : (
                        <div>
                          {document.name && !document.name.includes('.pdf') ? (
                            <Markdown remarkPlugins={[remarkGfm]}>{markdownArray[document.id]}</Markdown>
                          ) : (
                            <DocumentViewer documentId={document.id} documentName={document.name} companyId={document.company_id} publicUrl={IPConfig('public')} />
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                </div>

                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  {(document.type === 'primary' && currentTask.status.name === 'pending') && (
                    <button
                      className="btn btn-sm btn-light-primary me-3"
                      onClick={(e) => {
                        e.preventDefault()
                        toggleEditMode(document.id)
                      }}
                    >
                      <KTIcon iconName={editModes[document.id] ? 'save' : 'file'} className="fs-3" />
                      {editModes[document.id] ? 'Save Document' : 'Edit Document'}
                    </button>
                  )}
                  {/*<button className='btn btn-light btn-active-light-primary me-2' onClick={() => downloadDocument(1)}>Reprocess</button>*/}
                  <button type="submit" className="btn btn-primary" onClick={() => downloadDocument(document.id)}>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>

        ))}

      </div>

      {(currentTask.status.name === "pending" && formConfig) && (

        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Finalise Action</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>Confirm you are satisfied</span>
            </h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body py-1 mb-6'>
            {formConfig.components && formConfig.components.length && (
              <div>
                {formConfig.components.map((component, index) => (
                  component.type === 'button' ? (
                    <button
                      key={index}
                      {...component.props}
                      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        e.preventDefault();
                        handleButtonClick(component.props.value);
                      }}
                    >
                      {component.content}
                    </button>
                  ) : (
                    <DynamicInput
                      key={`${component.type}_${index}`}
                      component={component}
                      formData={formData}
                      onChange={handleInputChange}
                      validationErrors={validationErrors}
                    />
                  )
                ))}

                {validationErrors.validationErrors && (
                  <div style={{color: 'red'}}>
                    {Object.entries(validationErrors).map(([key, error], index) => (
                      <div key={`error_${index}`}>{error}</div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          {/* end::Body */}

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
              {formConfig.submitButtonText || 'Submit'}
            </button>
          </div>
        </div>
      )}

    </div>
  );
}

export {TableTaskView}
