export interface IDocumentDetails {
  id: string
  document: string
  roles: Array<Object>
}

export const documentDetailsInitValues: IDocumentDetails = {
  id: '',
  document: '',
  roles: [],
}
