export interface IQueueDetails {
  id: string
  name: string
  permission: Array<Object|null>
}

export const queueDetailsInitValues: IQueueDetails = {
  id: '',
  name: '',
  permission: [],
}
