/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import IPConfig from '../../store/IPConfig'
import {KTIcon} from '../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'
import {IWorkspaceDetails} from '../../models/WorkspaceModel'
import {getUpdatedUser} from '../auth/core/_requests'
import JSONEditorComponent from '../../partials/JSONEditorComponent'

const CompanyDocument: FC = () => {
  const [documentType, setDocumentType] = useState<string>("markdown")
  const [documentUrl, setDocumentUrl] = useState<string>("")
  const [documentFileName, setDocumentFileName]: any = useState([])
  const [renderedMarkdown, setRenderedMarkdown]: any = useState()
  const [isOpenWizard, setIsOpenWizard] = useState(false)
  const [workspaceArray, setWorkspaceArray] = useState<IWorkspaceDetails[]>([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [userID, setUserID] = useState('')

  const user: any = getUpdatedUser()
  const apiUrl = IPConfig()
  const publicUrl = IPConfig('public')

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'))
    setUserID(userUpdated.id)
  }, [])

  useEffect(() => {
    fetchFile()
  }, [])

  useEffect(() => {
    const documentInvestorName = localStorage.getItem('documentInvestorName')
    setDocumentFileName(documentInvestorName)
  }, [])

  useEffect(() => {
    fetchWorkspaces()
  }, [userID])

  const fetchWorkspaces = () => {
    if (userID) {
      fetch(`${apiUrl}/get-workspace?all=true&items_per_page=100&status=[10,8]&user_id=${userID}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.data) {
            setWorkspaceArray(data.data)
          }
        })
        .catch(function (err) {
          console.log('Unable to fetch -', err)
        })
    }
  }

  const fetchFile = () => {
    const documentInvestorId = localStorage.getItem('documentInvestorId')

    fetch(`${apiUrl}/getfilecontent?id=` + documentInvestorId)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          let trimmedData = response.data.trim();
          if (trimmedData.endsWith(".json") || (trimmedData.startsWith('{') && trimmedData.endsWith('}')) || (trimmedData.startsWith('[') && trimmedData.endsWith(']'))) {
            // Likely JSON
            try {
              const parsedJSON = JSON.parse(trimmedData);
              setDocumentType("json");
              setRenderedMarkdown(trimmedData);
            } catch (e) {
              console.error("Data looks like JSON but failed to parse:", e);
              setDocumentType("markdown");
              setRenderedMarkdown(trimmedData);
            }
          } else if (trimmedData.endsWith(".pdf")) {
            setDocumentType("pdf");
            setDocumentUrl(`${publicUrl}/${trimmedData}`);
          } else {
            setDocumentType("markdown");
            setRenderedMarkdown(trimmedData);
          }
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const downloadDocument = async () => {
    try {
      const documentInvestorName = localStorage.getItem('documentInvestorName')
      const documentInvestorId = localStorage.getItem('documentInvestorId')

      const url = `${apiUrl}/download?fileid=${documentInvestorId}`
      const response = await fetch(url)
      const blob = await response.blob()

      const blobUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = blobUrl
      // @ts-ignore
      link.setAttribute('download', documentInvestorName)
      document.body.appendChild(link)
      link.click()
      if (link.parentNode) {
        link.parentNode.removeChild(link)
      }
    } catch (error) {
      console.error('Error downloading document:', error)
      alert('Failed to download document')
    }
  }

  const addJobToQueue = () => {
    const currentCompanyId = localStorage.getItem('currentCompanyId')
    const documentInvestorId = localStorage.getItem('documentInvestorId')

    const formData = new FormData()
    formData.append('workspace_id', selectedCategory)
    formData.append('user_id', user.id)
    formData.append('source_document_ids', '[' + documentInvestorId + ']')
    if (currentCompanyId) {
      formData.append('company_id', currentCompanyId)
    }

    fetch(`${apiUrl}/start-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        closeWizard()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function openWizard() {
    setIsOpenWizard(true)
  }

  function closeWizard() {
    setIsOpenWizard(false)
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value)
  }

  const handleJSONChange = (event) => {
    // continue
  }

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Viewing Document</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>{documentFileName}</span>
              </h3>
              <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to download document'
              >
                <a href='#' className='btn btn-sm btn-light-primary me-3' onClick={openWizard}>
                  <KTIcon iconName='file' className='fs-3' />
                  Reprocess Document
                </a>
                <a href='#' className='btn btn-sm btn-light-primary' onClick={downloadDocument}>
                  <KTIcon iconName='arrow-down' className='fs-3' />
                  Download Document
                </a>
              </div>
            </div>
            {/* end::Header */}

            <div className='card-body py-3 pt-8'>
              {documentType === "pdf" && (
                <object
                  data={documentUrl}
                  type="application/pdf"
                  width="100%"
                  height="800px"
                >
                  <p>Your browser does not support PDFs. <a href={documentUrl}>Download the PDF</a> instead.</p>
                </object>
              )}

              {documentType === 'markdown' && (
                <Markdown remarkPlugins={[remarkGfm]}>{renderedMarkdown}</Markdown>
              )}

              {documentType === 'json' && (
                <JSONEditorComponent jsonData={renderedMarkdown} onUpdateJson={handleJSONChange} />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px'
        show={isOpenWizard}
        onHide={() => {
          closeWizard()
        }}
        backdrop={true}
      >
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={() => {
              closeWizard()
            }}
          >
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        </div>

        <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
          <div className='text-center mb-13'>
            <h1 className='mb-3'>Reprocess Document</h1>

            <div className='text-muted fw-bold fs-5'>
              Reprocess the current document in a workspace
            </div>
          </div>

          <div className='modal-wizard_main mb-6'>
            <label className='mt-3 fw-semibold'>Workspace Selection</label>
            <select
              className='form-control form-control-solid mt-3'
              onChange={handleCategoryChange}
            >
              <option value='' selected disabled hidden>
                Select a workspace
              </option>
              {workspaceArray.map((workspace, index) => (
                <option value={workspace.id}>{workspace.name}</option>
              ))}
            </select>
          </div>

          <div className='modal-wizard_main'>
            <button className='btn btn-primary back' onClick={() => closeWizard()}>
              Back
            </button>
            <button className='btn btn-primary ms-3 next' onClick={() => addJobToQueue()}>
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const DocumentOverview: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Document Overview</PageTitle>
      <CompanyDocument />
    </>
  )
}

export {DocumentOverview}
