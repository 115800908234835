// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Username' className='min-w-125px' />,
    id: 'username',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    id: 'role',
    accessor: 'role.name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />,
    id: 'status',
    accessor: 'isActive',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className=' min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell isActive={props.data[props.row.index].isActive} userID={props.data[props.row.index].id} id={props.data[props.row.index].email} />,
  },
]

export {usersColumns}
