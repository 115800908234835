/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import IPConfig from "../../../store/IPConfig";
import { KTIcon } from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from "../../../partials/modals/ModalDeleteConfirm";
import {permissionDetailsInitValues as initialValues, IPermissionDetails} from "../../../models/PermissionModel";
import {ModalAddPermission} from "../../../partials/modals/permissions/ModalAddPermission";
import {TableLoading} from "../../../partials/tables/TableLoading";
import {TablePagination} from "../../../partials/tables/TablePagination";

type Props = {
  className: string
}

const TablePermissions: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<IPermissionDetails>(initialValues)
  const [permissionArray, setPermissionArray] = useState<IPermissionDetails[]>([]);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [showAddPermissionModal, setShowAddPermissionModal] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('');

  const navigate = useNavigate();
  const apiUrl = IPConfig();

  useEffect(() => {
    fetchPermissions();
  }, [pagination.page, searchTerm, sortBy, sortOrder]);

  const fetchPermissions = () => {
    setIsLoading(true);

    // Construct API URL based on pagination, search, and sorting parameters
    let url = `${apiUrl}/get-permission?all=true&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`;

    if (searchTerm) {
      url += `&search=${searchTerm}`;
    }

    if (sortBy && sortOrder) {
      url += `&sort=${sortBy}&order=${sortOrder}`;
    }

    fetch(url)
        .then(response => response.json())
        .then(response => {
          if (response.data) {
            setPermissionArray(response.data);
          }
          if (response.payload && response.payload.pagination) {
            setPagination((prev) => ({...prev, total: response.payload.pagination.total}));
          } else {
            setPermissionArray([]);
          }
        })
        .catch(function (err) {
          console.log("Unable to fetch -", err);
        });

    setIsLoading(false);
  };

  const deletePermission = permissionId => {
    const formData = new FormData();
    formData.append("id", permissionId);

    fetch(`${apiUrl}/delete-permission`, {
      method: "DELETE",
      body: formData,
    })
        .then(response => response.json())
        .then(data => {
          fetchPermissions();
        })
        .catch(error => {
          console.error(error);
        });
  };

  const savePermission = index => {
    localStorage.setItem("currentPermission", JSON.stringify(permissionArray[index]));

    const updatedData = Object.assign(data, permissionArray[index])
    setData(updatedData)

    navigate("/permissions/edit?name=#");
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Permissions</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{permissionArray.length} permissions listed</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a permission'
        >
          <div className="me-3">
            <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <a
            href='#'
            className='btn btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_add_permission'
            onClick={() => setShowAddPermissionModal(true)}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Permission
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Permission</th>
                <th className='min-w-140px'>Value</th>
                <th className='min-w-120px'>Roles</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>

              {permissionArray.map((permission, index) => (
                <tr key={index}>
                  <td>
                    <div className="d-flex align-items-center" onClick={() => savePermission(index)}>
                      <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-light-success">
                          <KTIcon iconName="shield-tick" className="fs-2x text-success" />
                        </span>
                      </div>
                      <div className="d-flex justify-content-start flex-column">
                        <a href="#" className="text-dark fw-bold text-hover-primary mb-1 fs-6 text-capitalize">
                          {permission['permission'].replace('-', ' ')}
                        </a>
                        <span className="text-muted fw-semibold text-muted d-block fs-7">
                          Permission
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href="#" onClick={() => savePermission(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6 text">
                      {permission['permission']}
                    </a>
                  </td>
                  <td>
                    <a href="#" onClick={() => savePermission(index)}
                       className="text-dark fw-bold text-hover-primary d-block fs-6">
                      {permission['roles'].map((role, index) => (
                        <span key={index} className="badge badge-light-primary fs-7 me-2">{role.toString()}</span>
                      ))}
                    </a>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end flex-shrink-0">
                      <a
                        href="#"
                          onClick={() => savePermission(index)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                          href='#'
                          onClick={() => {
                            setConfirmDeleteModalVisible(true);
                            setDeleteItem([
                              // @ts-ignore
                              permission["permission"],
                              // @ts-ignore
                              permission["id"]
                            ]);
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

              {!permissionArray || permissionArray.length === 0 && (
                <tr className="w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2">
                  <td colSpan={6}>
                    No matching records found
                  </td>
                </tr>
              )}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        {(pagination && pagination.total > 0 && permissionArray && permissionArray.length > 0) && (
          <TablePagination
            pagination={pagination}
            setPagination={setPagination}
          />
        )}
        {isLoading && <TableLoading/>}
      </div>
      {/* begin::Body */}

      <ModalAddPermission show={showAddPermissionModal} handleClose={() => setShowAddPermissionModal(false)}/>

      {confirmDeleteModalVisible && (
          <ModalDeleteConfirm
              show={confirmDeleteModalVisible}
              onClose={() => setConfirmDeleteModalVisible(false)}
              onConfirm={() => {
                setConfirmDeleteModalVisible(false);
                deletePermission(deleteItem[1]);
              }}
              message={
                  "Are you sure you want to delete " + deleteItem[0] + "?"
              }
          />
      )}
    </div>
  )
}

export {TablePermissions}
