import React, {useState, useEffect} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {FilterTasks} from './FilterTasks'
import {Dropdown} from 'bootstrap'
import IPConfig from '../../../store/IPConfig'

type Props = {
  jobs: any
  className: string
  refreshJobs: () => void
}

const CardJobs: React.FC<Props> = ({jobs, className, refreshJobs}) => {
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelNotes, setCancelNotes] = useState('');
  const [jobToCancel, setJobToCancel]: any = useState(null);
  const apiUrl = IPConfig()

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'completed': return 'success'
      case 'failed': return 'danger'
      case 'processing': return 'primary'
      case 'pending': return 'warning'
      case 'waiting for user': return 'info'
      case 'archived': return 'dark'
      case 'cancelled': return 'danger'
      case 'stopped by user': return 'warning'
      case 'draft': return 'light'
      default: return 'secondary'
    }
  }

  const rerunJob = (id: string) => {
    const formData = new FormData()
    formData.append('job_id', id)

    fetch(`${apiUrl}/resume-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error rerunning job:', error)
      })
  }

  const restartJob = (id: string) => {
    const formData = new FormData()
    formData.append('job_id', id)

    fetch(`${apiUrl}/restart-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error restarting job:', error)
      })
  }

  const stopJob = (id: string) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('cancelled', 'false')

    fetch(`${apiUrl}/stop-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error stopping job:', error)
      })
  }

  const archiveJob = (id: string) => {
    const formData = new FormData()
    formData.append('id', id)

    fetch(`${apiUrl}/archive-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error archiving job:', error)
      })
  }

  const unarchiveJob = (id: string) => {
    const formData = new FormData()
    formData.append('id', id)

    fetch(`${apiUrl}/unarchive-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error unarchiving job:', error)
      })
  }

  const cancelJob = (id: string) => {
    setJobToCancel(id);
    setShowCancelPopup(true);
  }

  const confirmCancelJob = () => {
    const formData = new FormData()
    formData.append('id', jobToCancel)
    formData.append('cancelled', 'true')
    formData.append('log', cancelNotes)

    fetch(`${apiUrl}/stop-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        setShowCancelPopup(false)
        setCancelNotes('')
        setJobToCancel(null)
        refreshJobs()
      })
      .catch((error) => {
        console.error('Error cancelling job:', error)
      })
  }

  useEffect(() => {
    // Initialize dropdowns
    const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    dropdownElementList.forEach((dropdownToggleEl) => {
      new Dropdown(dropdownToggleEl);
    });
  }, [jobs])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Latest Jobs</h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <FilterTasks />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-2'>
        {jobs.map((job, index) => (
          <div key={job.id} className={`d-flex align-items-center ${index !== jobs.length - 1 ? 'mb-8' : ''}`}>
            {/* begin::Bullet */}
            <span className={`bullet bullet-vertical h-40px bg-${getStatusColor(job.status.name)}`}></span>
            {/* end::Bullet */}

            {/* begin::Spacing */}
            <div className='mx-3'></div>
            {/* end::Spacing */}

            {/* begin::Description */}
            <div className='flex-grow-1'>
              <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                {job.name}
              </a>
              <span className='text-muted fw-semibold d-block'>
                {new Date(job.date_started).toLocaleString()}
              </span>
            </div>
            {/* end::Description */}

            <span className={`badge badge-light-${getStatusColor(job.status.name)} fs-8 fw-bold me-3`}>
              {job.status.name}
            </span>

            {/* begin::Actions dropdown */}
            <div className="dropdown ms-2">
              <button
                className="btn btn-sm btn-light btn-active-light-primary"
                type="button"
                id={`dropdownMenuButton-${job.id}`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Actions
                <KTIcon iconName="down" className="fs-5 m-0" />
              </button>
              <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${job.id}`}>
                {(job.status.name === 'failed' || job.status.name === 'stopped by user') && (
                  <li><a className="dropdown-item" onClick={() => rerunJob(job.id)}>Rerun Last Task</a></li>
                )}
                {(job.status.name === 'failed' || job.status.name === 'cancelled' || job.status.name === 'stopped by user' || job.status.name === 'completed') && (
                  <li><a className="dropdown-item" onClick={() => restartJob(job.id)}>Rerun Job</a></li>
                )}
                {(job.status.name === 'waiting for user' || job.status.name === 'processing') && (
                  <li><a className="dropdown-item" onClick={() => stopJob(job.id)}>Pause Job</a></li>
                )}
                {(job.status.name === 'pending' || job.status.name === 'failed' || job.status.name === 'draft' || job.status.name === 'cancelled' || job.status.name === 'stopped by user' || job.status.name === 'completed') && (
                  <li><a className="dropdown-item" onClick={() => archiveJob(job.id)}>Archive Job</a></li>
                )}
                {(job.status.name === 'archived') && (
                  <li><a className="dropdown-item" onClick={() => unarchiveJob(job.id)}>Unarchive Job</a></li>
                )}
                {(job.status.name === 'pending' || job.status.name === 'processing' || job.status.name === 'waiting for user' || job.status.name === 'draft') && (
                  <li><a className="dropdown-item" onClick={() => cancelJob(job.id)}>Cancel Job</a></li>
                )}
              </ul>
            </div>
            {/* end::Actions dropdown */}
          </div>
        ))}
      </div>
      {/* end::Body */}

      {/* Cancel Job Popup */}
      {showCancelPopup && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Cancel Job</h5>
                <button type="button" className="close" onClick={() => setShowCancelPopup(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="cancelNotes">Notes</label>
                  <textarea
                    className="form-control"
                    id="cancelNotes"
                    rows={3}
                    value={cancelNotes}
                    onChange={(e) => setCancelNotes(e.target.value)}
                    placeholder="Explain to the Client why their report is being cancelled."
                  ></textarea>
                  <small className="form-text text-muted italic">
                    *Explain to the Client why their report is being cancelled.
                  </small>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowCancelPopup(false)}>Close</button>
                <button type="button" className="btn btn-primary" onClick={confirmCancelJob}>Confirm Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {CardJobs}