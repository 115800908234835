import React, { FC, useEffect, useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { CardStatus, CardRecords, CardJobs, CardStatistics } from '../../partials/widgets/dashboard'
import { IJobDetails } from '../../models/JobModel'
import { useNavigate } from 'react-router-dom'
import IPConfig from '../../store/IPConfig'

const fetchData = async (url) => {
  const response = await fetch(url)
  if (!response.ok) throw new Error('Network response was not ok')
  return response.json()
}

const DashboardPage: FC = () => {
  const apiUrl = IPConfig()
  const navigate = useNavigate()

  const [user, setUser] = useState<any>(null)
  const [statistics, setStatistics] = useState({
    jobsTotal: 0,
    jobsPending: 0,
    jobsProcessing: 0,
    jobsCompleted: 0,
    tasksTotal: 0,
    tasksPending: 0,
    tasksProcessing: 0,
    tasksCompleted: 0,
    users: 0,
    companies: 0,
  })

  const [jobArray, setJobArray] = useState<IJobDetails[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 6,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')

  useEffect(() => {
    const userUpdated = JSON.parse(window.localStorage.getItem('userUpdated') || '{}')
    setUser(userUpdated)
  }, [])

  const buildUrl = useCallback(
    (endpoint, status = '') => {
      const queryParams = new URLSearchParams({
        all: 'true',
        page: pagination.page.toString(),
        items_per_page: pagination.itemsPerPage.toString(),
        user_role: user?.role?.id || '',
        status,
        search: searchTerm,
        sort: sortBy,
        order: sortOrder,
      })
      return `${apiUrl}/${endpoint}?${queryParams}`
    },
    [apiUrl, pagination.page, pagination.itemsPerPage, user?.role?.id, searchTerm, sortBy, sortOrder]
  )

  const fetchTasks = useCallback(async () => {
    setIsLoading(true)
    try {
      const [totalTasks, pendingTasks, completedTasks] = await Promise.all([
        fetchData(buildUrl('get-user-task')),
        fetchData(buildUrl('get-user-task', '1')),
        fetchData(buildUrl('get-user-task', '3')),
      ])

      setStatistics((prev) => ({
        ...prev,
        tasksTotal: totalTasks.status === 'success' ? totalTasks.payload.pagination.total : 0,
        tasksPending: pendingTasks.status === 'success' ? pendingTasks.payload.pagination.total : 0,
        tasksCompleted: completedTasks.status === 'success' ? completedTasks.payload.pagination.total : 0,
      }))
    } catch (error) {
      console.error('Error fetching tasks:', error)
    } finally {
      setIsLoading(false)
    }
  }, [buildUrl])

  const fetchJobs = useCallback(async () => {
    setIsLoading(true)
    try {
      const [totalJobs, pendingJobs, processingJobs, completedJobs] = await Promise.all([
        fetchData(buildUrl('get-job')),
        fetchData(buildUrl('get-job', '1')),
        fetchData(buildUrl('get-job', '2')),
        fetchData(buildUrl('get-job', '3')),
      ])

      setStatistics((prev) => ({
        ...prev,
        jobsTotal: totalJobs.status === 'success' ? totalJobs.payload.pagination.total : 0,
        jobsPending: pendingJobs.status === 'success' ? pendingJobs.payload.pagination.total : 0,
        jobsProcessing: processingJobs.status === 'success' ? processingJobs.payload.pagination.total : 0,
        jobsCompleted: completedJobs.status === 'success' ? completedJobs.payload.pagination.total : 0,
      }))

      setJobArray(totalJobs.data || [])
      setPagination((prev) => ({
        ...prev,
        total: totalJobs.payload?.pagination.total || 0,
      }))
    } catch (error) {
      console.error('Error fetching jobs:', error)
      setJobArray([])
    } finally {
      setIsLoading(false)
    }
  }, [buildUrl])

  useEffect(() => {
    if (user) {
      fetchTasks()
      fetchJobs()
    }
  }, [user, fetchTasks, fetchJobs])

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-4'>
              <CardStatistics
                title='Stripe Statistics'
                description='Recent sales statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='primary'
                chartHeight='150px'
              />
            </div>
            <div className='col-xl-4'>
              <CardStatistics
                title='AI Model Statistics'
                description='Token usage statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='success'
                chartHeight='150px'
              />
            </div>
            <div className='col-xl-4'>
              <CardStatistics
                title='Google Analytics'
                description='Analytical statistics'
                className='card-xl-stretch mb-xl-8'
                chartColor='danger'
                chartHeight='150px'
              />
            </div>
          </div>

          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardStatus
                className='h-md-50 mb-5 mb-xl-10'
                description='Completed Tasks'
                color='#F1416C'
                stats={statistics}
                type='tasks'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
              <CardRecords
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Users'
                icon={false}
                stats={statistics.users}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
              <CardStatus
                className='h-md-50 mb-5 mb-xl-10'
                description='Completed Jobs'
                color='#F1416C'
                stats={statistics}
                type='jobs'
                img={toAbsoluteUrl('/media/patterns/vector-1.png')}
              />
              <CardRecords
                className='h-md-50 mb-5 mb-xl-10'
                description='Total Companies'
                icon={false}
                stats={statistics.companies}
                labelColor='dark'
                textColor='gray-300'
              />
            </div>
            <div className='col-xxl-6'>
              <CardJobs
                jobs={jobArray}
                className="card-xxl-stretch mb-xl-3 h-md-100"
                refreshJobs={fetchJobs}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
