import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AccountSetup} from './components/AccountSetup'
import {ResetPassword} from './components/ResetPassword'
import {AuthLayout} from './AuthLayout'
import {ComingSoon} from './components/ComingSoon'
import {AuthLayoutAlt} from './AuthLayoutAlt'
import {WizardLayoutAlt} from './WizardLayoutAlt'
import {Horizontal} from '../wizards/components/Horizontal'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='account-setup' element={<AccountSetup />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
    <Route element={<AuthLayoutAlt />}>
      <Route path='coming-soon' element={<ComingSoon />} />
    </Route>
    <Route element={<WizardLayoutAlt />}>
      <Route path='persona' element={<Horizontal />} />
    </Route>
  </Routes>
)

export {AuthPage}
