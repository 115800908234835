import React, {FC, useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import IPConfig from '../../../app/store/IPConfig'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {toAbsoluteUrl, MessageModel} from '../../helpers'
import {useLocation} from 'react-router-dom'

type Props = {
  apiUrl?: string
  isDrawer?: boolean
  selectedAgent?: any
}

const ChatInner: FC<Props> = ({
  apiUrl = IPConfig(),
  isDrawer = false,
  selectedAgent
}) => {
  const location = useLocation()
  const scrollRef = useRef<HTMLDivElement>(null)
  const publicUrl = IPConfig('public')

  const [userId, setUserId] = useState<any>(null)
  const [userName, setUserName] = useState<string>('You')
  const [message, setMessage] = useState<string>('')
  const [chatType, setChatType] = useState<string>('chat')
  const [messages, setMessages] = useState<MessageModel[]>([])
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)

  const [selectedJobId, setSelectedJobId] = useState<string | null>(null)
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | null>(null)
  const [selectedCollectionId, setSelectedCollectionId] = useState<string | null>(null)

  const isCompanyPage =
    location.pathname.includes('companies/overview') ||
    location.pathname.includes('companies/original') ||
    location.pathname.includes('companies/investor') ||
    location.pathname.includes('companies/document/view');
  const isCollectionPage = location.pathname.includes('documents/view')

  useEffect(() => {
    const userUpdated = JSON.parse(localStorage.getItem('userUpdated') || '{}')
    setUserId(userUpdated.id)
  }, [])

  useEffect(() => {
    setMessages([])
    setMessage('')
    setSelectedJobId(null)
    setSelectedCompanyId(null)
    setSelectedCollectionId(null)
  }, [])

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'selectedJobId') {
        setSelectedJobId(event.newValue || null)
      } else if (event.key === 'selectedCompanyId') {
        setSelectedCompanyId(event.newValue || null)
      } else if (event.key === 'selectedCollectionId') {
        setSelectedCollectionId(event.newValue || null)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (selectedJobId) {
      setChatType('chat');
    } else if (isCompanyPage && selectedCompanyId) {
      setChatType('company');
    } else if (isCollectionPage && selectedCollectionId) {
      setChatType('knowledge');
    } else {
      setMessages([])
    }

    if (selectedAgent) {
      setMessages([])
      fetchChatHistory()
    }
  }, [selectedJobId, selectedCompanyId, selectedCollectionId, isCompanyPage, isCollectionPage, selectedAgent])

  useEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      const lastMessage = document.getElementById(`message-${messages.length - 1}`)
      if (lastMessage && messages[messages.length - 1].sender === 'bot') {
        lastMessage.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
    }
  }, [messages])

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault()

    if (message.trim() === '') return

    const formData = new FormData()
    let endpoint = `${apiUrl}/chat-${chatType}`

    if (chatType === 'chat') {
      formData.append('job_id', selectedJobId || '')
      formData.append('user_message', message)
      if (selectedAgent) formData.append('agent_id', selectedAgent.id)
    } else if (chatType === 'company') {
      formData.append('company_id', selectedCompanyId || '')
      formData.append('user_id', userId)
      formData.append('message', message)
      if (selectedAgent) formData.append('agent_id', selectedAgent.id)
    } else if (chatType === 'knowledge') {
      formData.append('collection_id', selectedCollectionId || '')
      formData.append('user_id', userId)
      formData.append('message', message)
      if (selectedAgent) formData.append('agent_id', selectedAgent.id)
    }

    const userMessage: MessageModel = {
      text: message,
      time: new Date().toISOString(),
      sender: 'user',
    }
    setIsLoadingMessage(true)
    setMessages((prevMessages) => [...prevMessages, userMessage])

    fetch(endpoint, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        const botMessage: MessageModel = {
          text: response.message,
          time: new Date().toISOString(),
          avatar: publicUrl + response.avatar,
          agent_name: response.agent_name,
          knowledge_base_used: "Knowledge Base",
          sender: 'bot',
        }

        if (response.user_name) setUserName(response.user_name)

        setMessages((prevMessages) => [...prevMessages, botMessage])
        setIsLoadingMessage(false)
      })
      .catch((error) => {
        console.error('failed:', error.message)
        setIsLoadingMessage(false)
        const botMessage: MessageModel = {
          text: error.message,
          time: new Date().toISOString(),
          sender: 'bot',
        }
        setMessages((prevMessages) => [...prevMessages, botMessage])
      })

    setMessage('')
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const fetchChatHistory = () => {
    let url = `${apiUrl}/get-chat-history?all=true&agent_id=${selectedAgent.id}&user_id=${userId}&page=1&items_per_page=100`;

    if (isCompanyPage) {
      url += `&company_id=${selectedCompanyId}`
    } else {
      url += `&knowledge_base_id=${selectedCollectionId}`
    }

    fetch(url)
      .then(response => response.json())
      .then(response => {
        if (response.status === "success" && response.data) {
          const latestChat = response.data[0]
          if (latestChat && latestChat.history) {
            const formattedMessages = latestChat.history.flatMap(conversation =>
              conversation.map(message => ({
                text: message.content,
                sender: message.user ? 'user' : 'bot',
                avatar: message.avatar,
                agent_name: message.agent,
                time: message.time ? message.time : latestChat.date_started
              }))
            )
            setMessages(formattedMessages)
          }
        } else {
          console.error("Failed to fetch chat history or invalid data structure:", response.message)
        }
      })
      .catch(error => {
        console.error("Error fetching chat history:", error)
      })
  }

  return (
    <div
      className='card-body card-chat'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y scroll-max me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer
            ? '#kt_drawer_chat_messenger_body'
            : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        style={{minHeight: `calc(100vh - 302px)`}}
        ref={scrollRef}
      >
        {messages.map((message, index) => {
          const state = message.sender === 'bot' ? 'info' : 'primary'
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            message.sender === 'bot' ? 'start' : 'end'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              id={`message-${index}`}
              className={clsx('d-flex', contentClass, 'mb-10')}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${message.sender === 'bot' ? 'start' : 'end'}`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {message.sender === 'bot' ? (
                    <>
                      <div
                        className='symbol symbol-35px symbol-circle'
                        style={{border: '4px solid var(--amaranth-purple)'}}
                      >
                        <img
                          alt='Persona'
                          src={selectedAgent && selectedAgent.avatar ? publicUrl + selectedAgent.avatar : '/media/avatars/blank.png'}
                        />
                      </div>
                      <div className='ms-3'>
                        <a href='#' className='fs-5 fw-bolder text-hover-primary me-1'>
                          {selectedAgent ? selectedAgent.name : 'Chatbot'}
                        </a>
                        <span className='text-muted fs-7 mb-1'>
                          {
                            new Date(message.time)
                              .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                              .replace(/,/g, '')
                          }
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>
                          {
                            new Date(message.time)
                              .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                              })
                              .replace(/,/g, '')
                          }
                        </span>
                        <a href='#' className='fs-5 fw-bolder text-hover-primary ms-1'>
                          {userName}
                        </a>
                      </div>
                      <div
                        className='symbol symbol-35px symbol-circle '
                        style={{border: '4px solid var(--amaranth-purple)'}}
                      >
                        <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} />
                      </div>
                    </>
                  )}
                </div>

                <div
                  className={clsx(
                    'p-4 pb-1 font-size-all rounded markdown',
                    `bg-light-${state}`,
                    'fw-bold mw-lg-600px',
                    `text-${message.sender === 'bot' ? 'start' : 'end'}`
                  )}
                  data-kt-element='message-text'
                >
                  <Markdown remarkPlugins={[remarkGfm]}>{message.text}</Markdown>
                </div>
              </div>
            </div>
          )
        })}
        {isLoadingMessage && (
          <div className='loader'></div>
        )}
      </div>

      <div
        className='card-footer p-0 pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <form onSubmit={handleFormSubmit}>
          <textarea
            disabled={isLoadingMessage || (!selectedJobId && !selectedCompanyId && !selectedCollectionId)}
            className='form-control form-control-flush mb-3 bg-white rounded w-100'
            rows={isDrawer ? 6 : 1}
            data-kt-element='input'
            placeholder={isLoadingMessage ? 'Please wait...' : 'Type a message'}
            value={message}
            onChange={handleInputChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleFormSubmit(e)
              }
            }}
          ></textarea>
        </form>
      </div>
    </div>
  )
}

export {ChatInner}