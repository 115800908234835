/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AgentAddForm} from "./components/AgentAddForm";

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Agents',
    path: '/agents',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AgentFormWrapper: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <AgentAddForm className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const AgentFormAdd: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Create Agent</PageTitle>
      <AgentFormWrapper />
    </>
  )
}

export {AgentFormAdd}
