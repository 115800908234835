import {KTIcon} from '../../../../../../_metronic/helpers'

const UserEditModalHeader = ({ onClose, setVisible, setItemIdForUpdate }) => {
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>Edit User</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          onClose();
          setVisible(false);
          document.body.classList.remove('modal-open')
          setItemIdForUpdate(undefined);
        }}
        style={{cursor: 'pointer'}}
      >
        <KTIcon iconName='cross' className='fs-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserEditModalHeader}
