export interface IAgentDetails {
  id: string
  name: string
  description: string
  avatar: string
  type: string
  template: string
  tools: Array<any>
}

export const agentDetailsInitValues: IAgentDetails = {
  id: '',
  name: '',
  description: '',
  avatar: '',
  type: '',
  template: '',
  tools: [],
}
