/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import IPConfig from '../../../store/IPConfig'
import clsx from 'clsx'
import MultiSelectDropdown from '../../../partials/widgets/MultiSelectDropdown'
import JSONEditorComponent from '../../../partials/JSONEditorComponent'
import {IPermissionDetails} from '../../../models/PermissionModel'

type Props = {
  className: string
}

interface Task {
  task_name: string
  type: string
  agent_name?: string
  instruction?: string
  next_task?: string
  condition?: {next_task: string}[]
}

interface Workspace {
  name: string
  permission: string
  tasks: Task[]
}

interface Workflow {
  workspace: Workspace
}

interface ValidationResult {
  isValid: boolean
  errors?: string[]
}

const WorkspaceEditForm: React.FC<Props> = ({className}) => {
  const [activeTab, setActiveTab] = useState('workspace')
  const [currentWorkspace, setCurrentWorkspace]: any = useState('')
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [status, setStatus] = useState('')
  const [description, setDescription] = useState('')
  const [timeout, setTimeout] = useState('')
  const [workflow, setWorkflow] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [failMessage, setFailMessage] = useState('')
  const [validationError, setValidationError] = useState('')
  const [permissionArray, setPermissionArray] = useState<IPermissionDetails[]>([])
  const [permissionPushArray, setPermissionPushArray] = useState<string[]>([]);

  const apiUrl = IPConfig()
  const navigate = useNavigate()

  useEffect(() => {
    setStatus('production')
    try {
      let parsedWorkspace = JSON.parse(localStorage.getItem('currentWorkspace') || '{}')
      setCurrentWorkspace(parsedWorkspace)
    } catch (e) {
      setValidationError('Invalid JSON in workflow.')
      console.error(e)
    }
  }, [])

  useEffect(() => {
    setId(currentWorkspace.id)
    setName(currentWorkspace.name)
    setDescription(currentWorkspace.description)
    if (currentWorkspace && currentWorkspace.permissions) {
      setPermissionPushArray(currentWorkspace.permissions);
    }
    setTimeout(currentWorkspace.timeout)
    setWorkflow(currentWorkspace.workflow)
    if (currentWorkspace && currentWorkspace.status && currentWorkspace.status.name) {
      setStatus(currentWorkspace.status.name)
    }
  }, [currentWorkspace])

  useEffect(() => {
    fetchPermissions()
  }, [])

  const fetchPermissions = () => {
    fetch(`${apiUrl}/get-permission?all=true&items_per_page=100`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          setPermissionArray(data.data)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const handleTabChange = (tab: string) => {
    setActiveTab(tab)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleTimeoutChange = (e) => {
    setTimeout(e.target.value)
  }

  const handleWorkflowChange = (workflow: string) => {
    setWorkflow(workflow)
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const validateInputs = () => {
    try {
      if (!name.trim()) {
        setValidationError('Name field is required.')
        return false
      }
      if (!description.trim()) {
        setValidationError('Description field is required.')
        return false
      }
      if (!timeout) {
        setValidationError('Timeout field is required.')
        return false
      }
    } catch (e) {
      setValidationError('Some fields are invalid.')
      return false
    }
    setValidationError('')
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFailMessage('')
    setSuccessMessage('')
    setValidationError('')

    let validationResult: ValidationResult | null = null

    try {
      JSON.parse(workflow)
      validationResult = validateWorkflow(JSON.parse(workflow))
    } catch (e) {
      setValidationError('Invalid JSON in workflow.')
      return
    }

    if (validationResult && typeof validationResult !== 'undefined' && !validationResult.isValid) {
      setValidationError(validationResult.errors!.join(' '))
      return
    }

    if (validateInputs()) {
      updateWorkspace(id, name, description, timeout, workflow)
    }
  }

  const updateWorkspace = (
    id: string,
    name: string,
    description: string,
    timeout: string,
    workflow: string
  ) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('name', name)
    formData.append('description', description)
    formData.append('timeout', timeout)
    formData.append('workflow', workflow)
    if (permissionPushArray.length > 0) {
      formData.append('permissions', JSON.stringify(permissionPushArray))
    } else {
      formData.append('permissions', '[]')
    }

    if (status === 'production') {
      formData.append('status', '10')
    } else if (status === 'draft') {
      formData.append('status', '8')
    } else if (status === 'archived') {
      formData.append('status', '6')
    }

    fetch(`${apiUrl}/update-workspace`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 'success') {
          setSuccessMessage('Workspace updated successfully!')
          navigate('/workspaces')
        } else {
          if (response.message) {
            setFailMessage('Failed to update workspace. ' + response.message)
          } else {
            setFailMessage('Failed to update workspace. Please try again.')
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setFailMessage('Failed to update workspace. ' + error)
      })
  }

  function validateWorkflow(workflow: Workflow): ValidationResult {
    const errors: string[] = []
    const taskNames: Set<string> = new Set()

    // Validate workspace
    if (!workflow.workspace) {
      errors.push('Missing workspace object.')
    } else {
      const ws: Workspace = workflow.workspace

      // Required fields in workspace
      if (!ws.name) errors.push('Workspace name is required.')
      if (typeof ws.permission === 'undefined')
        errors.push('Workspace permission level is required.')
      if (!Array.isArray(ws.tasks) || ws.tasks.length === 0) {
        errors.push('Workspace must have at least one task.')
      } else {
        // Validate each task and collect task names
        ws.tasks.forEach((task: Task, index: number) => {
          if (!task.task_name) {
            errors.push(`Task ${index + 1} is missing a name.`)
          } else {
            // Check for unique task_name
            if (taskNames.has(task.task_name)) {
              errors.push(`Task name ${task.task_name} is not unique.`)
            } else {
              taskNames.add(task.task_name)
            }
          }

          if (!task.type) errors.push(`Task ${task.task_name || index + 1} is missing a type.`)
          if (task.type === 'ai_agent_task') {
            if (!task.agent_name)
              errors.push(`AI Agent Task ${task.task_name} requires an agent name.`)
            if (!task.instruction)
              errors.push(`AI Agent Task ${task.task_name} requires instructions.`)
          }

          // Add more validations as per the detailed schema requirements
        })
      }
    }

    // Validate next_task references
    if (taskNames.size > 0) {
      workflow.workspace.tasks.forEach((task: Task, index: number) => {
        if (task.next_task && !taskNames.has(task.next_task) && task.next_task !== 'exit') {
          errors.push(
            `Task ${task.task_name || index + 1} has an invalid next_task reference: ${
              task.next_task
            }`
          )
        }

        // Validate conditions if present
        if (task.condition) {
          task.condition.forEach((cond, condIndex) => {
            if (cond.next_task && !taskNames.has(cond.next_task) && cond.next_task !== 'exit') {
              errors.push(
                `Condition ${condIndex + 1} in task ${
                  task.task_name || index + 1
                } has an invalid next_task reference: ${cond.next_task}`
              )
            }
          })
        }
      })
    }

    // Return errors
    if (errors.length > 0) {
      return {isValid: false, errors}
    } else {
      return {isValid: true}
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Edit Workspace</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{name}</span>
        </h3>

        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                data-bs-toggle='tab'
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {active: activeTab === 'workspace'}
                )}
                onClick={() => handleTabChange('workspace')}
              >
                Workspace Details
              </a>
            </li>

            <li className='nav-item'>
              <button
                data-bs-toggle='tab'
                className={clsx(
                  'nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1',
                  {active: activeTab === 'workflow'}
                )}
                onClick={() => handleTabChange('workflow')}
              >
                Workflow
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pb-3 pt-6'>
        <form onSubmit={handleSubmit}>
          {activeTab === 'workspace' && (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace name'
                  type='text'
                  value={name}
                  onChange={handleNameChange}
                  name='name'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Description</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace description'
                  type='text'
                  value={description}
                  onChange={handleDescriptionChange}
                  name='description'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <MultiSelectDropdown
                items={permissionArray.map(permission => ({
                  name: permission.permission
                }))}
                selectedItems={permissionPushArray}
                onItemToggle={(itemName) => {
                  if (permissionPushArray.includes(itemName)) {
                    setPermissionPushArray(permissionPushArray.filter(p => p !== itemName));
                  } else {
                    setPermissionPushArray([...permissionPushArray, itemName]);
                  }
                }}
                placeholder="Select permissions"
                label="Permissions"
                isRequired={true}
                marginBottom={false}
              />
              {/* end::Input group */}

              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2 mt-6'>Timeout</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder='Workspace timeout'
                  type='text'
                  value={timeout}
                  onChange={handleTimeoutChange}
                  name='timeout'
                  className={clsx('form-control form-control-solid mb-3 mb-lg-0')}
                  autoComplete='off'
                />
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {status && (
                <div className='fv-row mb-7'>
                  {/* begin::Label */}
                  <label className='required fw-bold fs-6 mb-2'>Status</label>
                  {/* end::Label */}

                  <select
                    className='form-control form-select form-select-solid form-select-lg w-100'
                    value={status || ''}
                    onChange={handleStatusChange}
                  >
                    <option value='' hidden>
                      Select a Status
                    </option>
                    <option value='production' hidden={status === 'production'}>
                      Production
                    </option>
                    <option value='draft' hidden={status === 'draft'}>
                      Draft
                    </option>
                    <option value='archived' hidden={status === 'archived'}>
                      Archived
                    </option>
                  </select>
                </div>
              )}
            </>
          )}

          {activeTab === 'workflow' && (
            <>
              {/* begin::Input group */}
              <div className='fv-row mb-7'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Workflow</label>
                {/* end::Label */}

                <JSONEditorComponent jsonData={workflow} onUpdateJson={handleWorkflowChange} />
              </div>
              {/* end::Input group */}
            </>
          )}

          {validationError && (
            <p className='form-field-feedback text-danger fail'>{validationError}</p>
          )}
          {successMessage && (
            <p className='form-field-feedback text-success success'>{successMessage}</p>
          )}
          {failMessage && <p className='form-field-feedback text-danger fail'>{failMessage}</p>}

          <input
            type={'submit'}
            className='btn btn-light-primary fw-bolder w-100 mb-8 mt-3'
            value={'Update Workspace'}
          />
        </form>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {WorkspaceEditForm}
