/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import IPConfig from "../../../store/IPConfig";
import { KTIcon } from '../../../../_metronic/helpers'
import {ModalDeleteConfirm} from "../../../partials/modals/ModalDeleteConfirm";
import {jobDetailsInitValues as initialValues, IJobDetails} from "../../../models/JobModel";
import {ModalAddJob} from "../../../partials/modals/jobs/ModalAddJob";

type Props = {
  className: string
}

const TableJobs: React.FC<Props> = ({className}) => {
  const [data, setData] = useState<IJobDetails>(initialValues)
  const [jobArray, setJobArray] = useState<IJobDetails[]>([]);
  const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
  const [showAddJobModal, setShowAddJobModal] = useState<boolean>(false)
  const [deleteItem, setDeleteItem] = useState([]);

  const navigate = useNavigate();
  const apiUrl = IPConfig();

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = () => {
    fetch(`${apiUrl}/get-job?all=true`)
        .then(response => response.json())
        .then(data => {
          if (data.data) {
            setJobArray(data.data);
          }
        })
        .catch(function (err) {
          console.log("Unable to fetch -", err);
        });
  };

  const deleteJob = jobId => {
    const formData = new FormData();
    formData.append("id", jobId);

    fetch(`${apiUrl}/delete-job`, {
      method: "DELETE",
      body: formData,
    })
        .then(response => response.json())
        .then(data => {
          fetchJobs();
        })
        .catch(error => {
          console.error(error);
        });
  };

  const saveJob = index => {
    localStorage.setItem("currentJob", JSON.stringify(jobArray[index]));

    const updatedData = Object.assign(data, jobArray[index])
    setData(updatedData)

    navigate("/jobs");
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Jobs</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>{jobArray.length} jobs listed</span>
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a job'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_add_job'
            onClick={() => setShowAddJobModal(true)}
          >
            <KTIcon iconName='plus' className='fs-3' />
            Add Job
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-140px'>Queue</th>
                <th className='min-w-120px'>User</th>
                <th className='min-w-120px'>Company</th>
                <th className='min-w-120px'>Documents</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody>

              {jobArray.map((job, index) => (
                <tr key={index}>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6 text'>
                      {job["name"]}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {job["description"]}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6 text'>
                      {job["queue_id"]}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {job["source_documents"]}
                    </a>
                  </td>
                  <td>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                          href='#'
                          onClick={() => saveJob(index)}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </a>
                      <a
                          href='#'
                          onClick={() => {
                            setConfirmDeleteModalVisible(true);
                            setDeleteItem([
                              // @ts-ignore
                              job["name"],
                              // @ts-ignore
                              job["id"]
                            ]);
                          }}
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                      >
                        <KTIcon iconName='trash' className='fs-3' />
                      </a>
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      <ModalAddJob show={showAddJobModal} handleClose={() => setShowAddJobModal(false)}/>

      {confirmDeleteModalVisible && (
          <ModalDeleteConfirm
              show={confirmDeleteModalVisible}
              onClose={() => setConfirmDeleteModalVisible(false)}
              onConfirm={() => {
                setConfirmDeleteModalVisible(false);
                deleteJob(deleteItem[1]);
              }}
              message={
                  "Are you sure you want to delete " + deleteItem[0] + "?"
              }
          />
      )}
    </div>
  )
}

export {TableJobs}
