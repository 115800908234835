import React, { useEffect, useRef } from "react";
import JSONEditor, { JSONEditorOptions } from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import { Card } from "react-bootstrap";

interface JSONEditorComponentProps {
    jsonData: any;
    onUpdateJson: (json: any) => void;
}

const JSONEditorComponent: React.FC<JSONEditorComponentProps> = ({ jsonData, onUpdateJson }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    let jsonEditor: JSONEditor | null = null;

    useEffect(() => {
        if (containerRef.current) {
            const options: JSONEditorOptions = {
                modes: ["code", "tree", "form", "preview"],
                onChangeText: (value: string) => {
                    onUpdateJson(value);
                },
            };

            jsonEditor = new JSONEditor(containerRef.current, options);
        }

        return () => {
            if (jsonEditor) {
                jsonEditor.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (jsonEditor && jsonData) {
            try {
                const parsedJson = JSON.parse(jsonData);
                jsonEditor.set(parsedJson);
            } catch (error) {
                console.error("Error parsing JSON", error);
            }
        }
    }, [jsonData]);

    return (
        <Card>
            <div ref={containerRef} className='min-height-container' style={{ width: '100%', height: '800px' }} />
        </Card>
    );
};

export default JSONEditorComponent;
