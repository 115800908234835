import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {IProfileDetails, profileDetailsInitValues as initialValues} from '../SettingsModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import IPConfig from '../../../../../store/IPConfig'
import AvatarUpload from '../../../../../partials/widgets/AvatarUpload'
import {useNavigate} from 'react-router-dom'
import {refreshUser} from '../../../../auth/core/_requests'

const profileDetailsSchema = Yup.object().shape({
  avatar: Yup.string().optional(),
  username: Yup.string().required('Username is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string().optional(),
  password_confirm: Yup.string().optional(),
});

const ProfileDetails: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const [user, setUser] = useState<any>(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [userRecord, setUserRecord]: any = useState<[]>([]);

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg');
  const userAvatarImg = toAbsoluteUrl('/media/avatars/blank.png');
  const [avatarImage, setAvatarImage] = useState<string | null>(userAvatarImg);
  const [avatarUpload, setAvatarUpload] = useState<string | null>(null);

  const navigate = useNavigate();
  const publicUrl = IPConfig('public');
  const apiUrl = IPConfig();

  useEffect(() => {
    // @ts-ignore
    let userUpdated = JSON.parse(window.localStorage.getItem('userUpdated'));
    setUser(userUpdated);
    setUsername(userUpdated.username);
    setEmail(userUpdated.email);
  }, []);

  const updateUser = () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("user_id", user.id);
    formData.append("email", email);
    formData.append("username", username);
    formData.append("role_id", user.role.id);

    if (avatarUpload) {
      formData.append("avatar", avatarUpload);
    }

    if (!avatarUpload && !user.avatar) {
      formData.append("avatar", "");
    }

    if (password) {
      formData.append("password", password);
    }

    fetch(`${apiUrl}/updateuser`, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        refreshUser();
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  }

  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData);
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true);
      setTimeout(() => {
        const updatedData = Object.assign(data, values);
        setData(updatedData);
        setLoading(false);
      }, 1000);
    },
  });

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      uploadAvatar(file);
    }
  };

  const removeAvatar = () => {
    setAvatarImage(userAvatarImg);
    setAvatarUpload("");
    formik.values.avatar = "";
    userRecord.avatar = "";
  };

  const uploadAvatar = (avatarFile) => {
    const formData = new FormData();
    formData.append("avatar", avatarFile);

    fetch(`${apiUrl}/upload-avatar`, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "success") {
          setAvatarUpload(response['paths']['original']);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className="col-lg-8">
                <div className="fv-row mb-7">
                  <AvatarUpload
                    blankImg={blankImg}
                    publicUrl={publicUrl}
                    avatarUpload={avatarUpload}
                    currentAvatar={user?.avatar}
                    avatarImage={avatarImage}
                    handleImageChange={handleImageChange}
                    removeAvatar={removeAvatar}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">Username</label>

              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Username"
                      onChange={handleUsernameChange}
                      value={username}
                    />
                    {formik.touched.username && formik.errors.username && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{formik.errors.username}</div>
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label required fw-bold fs-6">Email</label>

              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Email address"
                  onChange={handleEmailChange}
                  value={email}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                <span className="required">Password</span>
              </label>

              <div className="col-lg-8 fv-row">
                <input
                  type="password"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Password"
                  onChange={handlePasswordChange}
                  value={password}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.password}</div>
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-6">
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Password Confirmation</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password confirmation'
                  onChange={handlePasswordConfirmChange}
                  value={passwordConfirm}
                />
                {formik.touched.password_confirm && formik.errors.password_confirm && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.password_confirm}</div>
                  </div>
                )}
              </div>
            </div>

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' onClick={updateUser} disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {ProfileDetails}
