type Props = {
  className: string
  description: string
  color: string
  img: string
  stats: {
    tasksTotal?: number
    tasksPending?: number
    tasksProcessing?: number
    tasksCompleted?: number
    jobsTotal?: number
    jobsPending?: number
    jobsProcessing?: number
    jobsCompleted?: number
  }
  type: 'tasks' | 'jobs'
}

const CardStatus = ({className, description, color, img, stats, type}: Props) => {
  const calculatePercentage = () => {
    if (stats) {
      if (type === 'tasks') {
        // @ts-ignore
        return stats.tasksPending === 0 ? 100 : Math.round((stats.tasksCompleted / stats.tasksTotal) * 100)
      } else {
        // @ts-ignore
        return stats.jobsProcessing === 0 ? 100 : Math.round((stats.jobsCompleted / stats.jobsTotal) * 100)
      }
    }
  }

  const percentage = calculatePercentage()

  return (
    <a href={type === 'tasks' ? '/tasks' : '/queues'}>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
        style={{
          backgroundColor: color,
          backgroundImage: `url('${img}')`,
        }}
      >
        <div className='card-header pt-5'>
          <div className='card-title d-flex flex-column'>
            <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>
              {type === 'tasks' ? `${stats.tasksCompleted} / ${stats.tasksTotal}` : `${stats.jobsCompleted} / ${stats.jobsTotal}`}
            </span>
            <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
          </div>
        </div>
        <div className='card-body d-flex align-items-end pt-0'>
          <div className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
              <span>{type === 'tasks' ? stats.tasksPending + ' Pending' : stats.jobsPending + ' Processing'}</span>
              <span>{percentage}%</span>
            </div>
            <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
              <div
                className='bg-white rounded h-8px'
                role='progressbar'
                style={{width: `${percentage}%`}}
                aria-valuenow={percentage}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export {CardStatus}