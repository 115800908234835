/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import IPConfig from '../../store/IPConfig'
import {useNavigate} from 'react-router-dom'
import MultiSelectDropdown from '../../partials/widgets/MultiSelectDropdown'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Roles',
    path: '/roles',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RolesForm: FC<{currentRole: any}> = ({currentRole}) => {
  const [roleNameValue, setRoleNameValue] = useState(currentRole.name || '')
  const [permissionArray, setPermissionArray] = useState<{name: string; roles: string[]}[]>([])
  const [permissionPushArray, setPermissionPushArray]: any = useState<string[]>([])
  const [failMessage, setFailMessage] = useState('')

  const navigate = useNavigate()
  const apiUrl = IPConfig()

  useEffect(() => {
    fetchPermissions()
  }, [])

  useEffect(() => {
    if (permissionArray.length > 0 && currentRole) {
      const initialPermissions = permissionArray
        .filter((permission) => permission.roles.includes(currentRole.name))
        .map((permission) => permission.name)
      setPermissionPushArray(initialPermissions)
    }
  }, [permissionArray, currentRole])

  const fetchPermissions = () => {
    fetch(`${apiUrl}/get-permission?all=true&items_per_page=100`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data) {
          const formattedPermissions = data.data.map((permission) => ({
            name: permission.permission,
            roles: permission.roles
          }))
          setPermissionArray(formattedPermissions)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const updateRole = (roleId, roleName) => {
    if (roleName) {
      const formData = new FormData()
      formData.append('id', roleId)
      formData.append('name', roleName)
      formData.append('permissions', permissionPushArray)

      fetch(`${apiUrl}/update-role`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === 'error') {
            setFailMessage('Error saving role. You likely forgot to add permissions.')
          } else {
            console.log(response)
            navigate('/roles')
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } else {
      setFailMessage('Name is required.')
    }
  }

  return (
    <>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Roles</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Update current role</span>
              </h3>
            </div>

            <div className='card-body py-3'>
              <form className='row g-3'>
                <div className='col-md-6'>
                  <label className='required form-label' htmlFor='roleName'>
                    Role Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='roleName'
                    placeholder='Enter role name'
                    value={roleNameValue}
                    onChange={(e) => setRoleNameValue(e.target.value)}
                  />
                  {failMessage && <p className='text-danger form-field-feedback fail pt-4'>{failMessage}</p>}
                </div>

                <MultiSelectDropdown
                  items={permissionArray}
                  selectedItems={permissionPushArray}
                  onItemToggle={(permissionName) => {
                    if (permissionPushArray.includes(permissionName)) {
                      setPermissionPushArray(permissionPushArray.filter(name => name !== permissionName))
                    } else {
                      setPermissionPushArray([...permissionPushArray, permissionName])
                    }
                  }}
                  placeholder="Select permissions"
                  label="Role Permissions"
                  isRequired={true}
                />

                <div className='col-12'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      updateRole(currentRole.id, roleNameValue)
                    }}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const RoleEdit: FC = () => {
  const [currentRole, setCurrentRole] = useState<any>(null)

  useEffect(() => {
    const storedRole: any = localStorage.getItem('currentRole')
    if (storedRole) {
      setCurrentRole(JSON.parse(storedRole))
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Update Role</PageTitle>
      {currentRole && <RolesForm currentRole={currentRole} />}
    </>
  )
}

export {RoleEdit}
