/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Workflow} from "../../partials/widgets/Workflow";

const WorkflowPage: FC = () => (
  <>

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <Workflow />
      </div>
    </div>
    {/* end::Row */}

  </>
)

const WorkflowWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.WORKFLOW'})}</PageTitle>
      <WorkflowPage />
    </>
  )
}

export {WorkflowWrapper}
