/* eslint-disable jsx-a11y/anchor-is-valid */

const ToolbarClassic = () => {
  return (
    <div className='d-flex align-items-center gap-2 gap-lg-3'>
      <div className='m-0 h-35px'></div>
    </div>
  )
}

export {ToolbarClassic}
