/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import IPConfig from '../../../store/IPConfig'
import {useNavigate} from 'react-router-dom'
import {TablePagination} from '../../../partials/tables/TablePagination'
import {TableLoading} from '../../../partials/tables/TableLoading'
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { Dropdown } from 'bootstrap';

type Props = {
  className: string
}

const TableQueueView: React.FC<Props> = ({className}) => {
  const currentQueue = JSON.parse(localStorage.getItem('currentQueue') || '{}')
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [jobArray, setJobArray]: any = useState<[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    total: 0,
    itemsPerPage: 10,
  })
  const [searchTerm, setSearchTerm] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [status, setStatus] = useState<string | undefined>()
  const [requestStatus, setRequestStatus] = useState<string | undefined>()
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [cancelNotes, setCancelNotes] = useState('');
  const [jobToCancel, setJobToCancel]: any = useState(null);
  const [websocket, setWebsocket]: any = useState(null);

  const isInitialRender = useRef(true);
  const navigate = useNavigate()
  const apiUrl = IPConfig()
  const wsUrl = IPConfig('ws')
  const defaultStatus = '1,2,3,4,7,8,9,11'

  const queryParams = new URLSearchParams(window.location.search)

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      establishWebSocketConnection();
    } else {
      restartWebSocketConnection();
    }
  }, [pagination.page, searchTerm, sortBy, sortOrder, requestStatus])

  useEffect(() => {
    const jobId = queryParams.get('job_id')
    if (jobId) {
      for (let i = 0; i < jobArray.length; i++) {
        if (jobArray[i].id === jobId) {
          toggleRow(i)
          break
        }
      }
    }

    // Initialize dropdowns
    const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'))
    dropdownElementList.forEach((dropdownToggleEl) => {
      new Dropdown(dropdownToggleEl);
    });
  }, [jobArray])

  const rerunJob = (id) => {
    const formData = new FormData()
    formData.append('job_id', id)

    fetch(`${apiUrl}/resume-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const restartJob = (id) => {
    const formData = new FormData()
    formData.append('job_id', id)

    fetch(`${apiUrl}/restart-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const stopJob = (id) => {
    const formData = new FormData()
    formData.append('id', id)
    formData.append('cancelled', 'false')

    fetch(`${apiUrl}/stop-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const archiveJob = (id) => {
    const formData = new FormData()
    formData.append('id', id)

    fetch(`${apiUrl}/archive-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const unarchiveJob = (id) => {
    const formData = new FormData()
    formData.append('id', id)

    fetch(`${apiUrl}/unarchive-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const cancelJob = (id) => {
    setJobToCancel(id);
    setShowCancelPopup(true);
  }

  const confirmCancelJob = () => {
    const formData = new FormData()
    formData.append('id', jobToCancel)
    formData.append('cancelled', 'true')
    formData.append('log', cancelNotes)

    fetch(`${apiUrl}/stop-job`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log(response)
        setShowCancelPopup(false)
        setCancelNotes('')
        setJobToCancel(null)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const restartWebSocketConnection = () => {
    if (websocket) {
      websocket.close();
    }
    establishWebSocketConnection();
  }

  const establishWebSocketConnection = () => {
    try {
      // If no query params are set use the following url
      let url = `${wsUrl}/websocket-job?all=true&queue_name=${currentQueue['name']}&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`

      // else use the following url
      if (queryParams.get('queue_id')) {
        url = `${wsUrl}/websocket-job?all=true&queue_id=${queryParams.get('queue_id')}&page=${pagination.page}&items_per_page=${pagination.itemsPerPage}`
      }

      if (searchTerm) {
        url += `&search=${searchTerm}`
      }

      if (sortBy && sortOrder) {
        url += `&sort=${sortBy}&order=${sortOrder}`
      }

      if (requestStatus) {
        url += `&status=[${requestStatus}]`
      } else {
        url += `&status=[${defaultStatus}]`
      }

      const ws = new WebSocket(url)

      ws.onopen = () => console.log('WebSocket connection established.')
      ws.onmessage = (event: any) => {
        const response = JSON.parse(event.data)
        if (response.data) {
          setJobArray(response.data)
        }
        if (response.payload && response.payload.pagination) {
          setPagination((prev) => ({...prev, total: response.payload.pagination.total}))
        } else {
          setJobArray([])
        }
      }
      ws.onclose = () => console.log('WebSocket connection closed.')
      ws.onerror = (error) => console.error('WebSocket error:', error)

      setWebsocket(ws)
    } catch (e) {
      console.log(e)
    }
  }

  const toggleRow = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index))
    } else {
      setExpandedRows([...expandedRows, index])
    }
  }

  const openDocument = (id: number, name: string, company_id: number) => {
    localStorage.setItem('currentCompanyId', company_id.toString())
    localStorage.setItem('documentInvestorId', id.toString())
    localStorage.setItem('documentInvestorName', name)

    navigate('/document/overview')
  }

  const calculateDuration = (startDate: string, endDate: string): string => {
    const startDateTime = new Date(startDate).getTime()
    const endDateTime = new Date(endDate).getTime()

    const durationMs = Math.abs(endDateTime - startDateTime)

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(durationMs / 3600000)
    const minutes = Math.floor((durationMs % 3600000) / 60000)
    const seconds = Math.floor((durationMs % 60000) / 1000)

    // Format the duration
    return `${hours}h ${minutes}m ${seconds}s`
  }

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    setStatus(defaultStatus)
    setRequestStatus(defaultStatus)
  }

  const filterData = () => {
    setPagination({
      page: 1,
      total: 0,
      itemsPerPage: pagination.itemsPerPage,
    })
    setRequestStatus(status)
  }

  const handleActionClick = (e: React.MouseEvent, action: () => void) => {
    e.preventDefault()
    e.stopPropagation()
    action()
  }

  return (
    <div>
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Jobs Overview</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{jobArray.length} jobs listed</span>
          </h3>
          <div
            className="card-toolbar"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-trigger="hover"
            title="Click to add a job"
          >
            <div className="me-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div>
              {/* begin::Filter Button */}
              <button
                disabled={isLoading}
                type="button"
                className="btn btn-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <KTIcon iconName="filter" className="fs-2" />
                Filter
              </button>
              {/* end::Filter Button */}
              {/* begin::SubMenu */}
              <div className="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">
                {/* begin::Header */}
                <div className="px-7 py-5">
                  <div className="fs-5 text-dark fw-bolder">Filter Options</div>
                </div>
                {/* end::Header */}

                {/* begin::Separator */}
                <div className="separator border-gray-200"></div>
                {/* end::Separator */}

                {/* begin::Content */}
                <div className="px-7 py-5" data-kt-user-table-filter="form">
                  {/* begin::Input group */}
                  <div className="mb-10">
                    <label className="form-label fs-6 fw-bold">Status:</label>
                    <select
                      className="form-select form-select-solid fw-bolder text-capitalize"
                      data-kt-select2="true"
                      data-placeholder="Select option"
                      data-allow-clear="true"
                      data-kt-user-table-filter="role"
                      data-hide-search="true"
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                    >
                      <option value="1,2,3,4,7,8,9,11" selected>All</option>

                      <option value="1">Pending</option>
                      <option value="2">Processing</option>
                      <option value="3">Completed</option>
                      <option value="4">Failed</option>
                      <option value="6">Archived</option>
                      <option value="7">Waiting for User</option>
                      <option value="8">Draft</option>
                      <option value="9">Stopped by User</option>
                      <option value="11">Cancelled</option>
                    </select>
                  </div>
                  {/* end::Input group */}

                  {/* begin::Actions */}
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={resetData}
                      className="btn btn-primary fw-bold px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="filter"
                    >
                      Reset
                    </button>
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={filterData}
                      className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                      data-kt-menu-dismiss="true"
                      data-kt-user-table-filter="reset"
                    >
                      Apply
                    </button>
                  </div>
                  {/* end::Actions */}
                </div>
                {/* end::Content */}
              </div>
              {/* end::SubMenu */}
            </div>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body py-3">
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 dataTable">
              {/* begin::Table head */}
              <thead>
              <tr className="fw-bold text-muted">
                <th className="min-w-140px">Company</th>
                <th className="min-w-120px">User</th>
                <th className="min-w-120px">Date Created</th>
                <th className="min-w-120px">Date Started</th>
                <th className="min-w-120px">Duration</th>
                <th className="min-w-120px">Status</th>
                <th className="min-w-120px">Current Task</th>
                <th className="min-w-100px text-end">Actions</th>
              </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}
              <tbody>
              {jobArray.map((job: any, index) => (
                <React.Fragment key={index}>
                  <tr id={job['id']}>
                    <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className="cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6 text"
                        >
                          {job['company_name']}
                        </span>
                    </td>
                    <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className="cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6"
                        >
                          {job['user_name']}
                        </span>
                    </td>
                    <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className="cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6"
                        >
                          {new Date(job['date_created'])
                            .toLocaleDateString('en-US', {
                              day: '2-digit',
                              month: 'short',
                              year: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            })
                            .replace(/,/g, '')}
                        </span>
                    </td>
                    <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className="cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6"
                        >
                          {job['date_started']
                            ? new Date(job['date_started'])
                              .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                })
                                .replace(/,/g, '')
                            : 'N/A'}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className='cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          {job['date_completed']
                            ? calculateDuration(job['date_started'], job['date_completed'])
                            : 'N/A'}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className='cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          {job['status']['name'] === 'pending' && (
                            <span className='badge badge-light-primary fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'processing' && (
                            <span className='badge badge-light-info fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'completed' && (
                            <span className='badge badge-light-success fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'failed' && (
                            <span className='badge badge-light-danger fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'waiting for user' && (
                            <span className='badge badge-light-warning fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'archived' && (
                            <span className='badge badge-light-danger fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'cancelled' && (
                            <span className='badge badge-light-danger fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'stopped by user' && (
                            <span className='badge badge-light-danger fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                          {job['status']['name'] === 'draft' && (
                            <span className='badge badge-light-warning fs-7'>
                              {job['status']['name']}
                            </span>
                          )}
                        </span>
                      </td>
                      <td>
                        <span
                          onClick={() => toggleRow(index)}
                          className='cursor-pointer text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          <span className='badge badge-light-primary fs-7'>
                            {job['current_task'] ? job['current_task'] : 'N/A'}
                          </span>
                        </span>
                      </td>
                      <td>
                        <div className="d-flex justify-content-end flex-shrink-0">
                          <button
                            className="btn btn-light btn-active-light-primary btn-sm"
                            type="button"
                            id={`dropdownMenuButton-${job.id}`}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Actions
                            <KTIcon iconName="down" className="fs-5 m-0" />
                          </button>
                          <ul className="dropdown-menu me-1 mt-1" aria-labelledby={`dropdownMenuButton-${job.id}`}>
                            {/* begin::Menu item */}
                            <li className="menu-item px-3 cursor-pointer">
                              <a className="dropdown-item"
                                 onClick={() => toggleRow(index)}
                              >
                                View
                              </a>
                            </li>
                            {/* end::Menu item */}

                            {/* begin::Menu item */}
                            {(job.status.name === 'failed' ||
                              job.status.name === 'stopped by user') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     rerunJob(job.id)
                                   }}
                                >
                                  Rerun Last Task
                                </a>
                              </li>
                            )}
                            {(job.status.name === 'failed' ||
                              job.status.name === 'cancelled' ||
                              job.status.name === 'stopped by user' ||
                              job.status.name === 'completed') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     restartJob(job.id)
                                   }}
                                >
                                  Rerun Job
                                </a>
                              </li>
                            )}
                            {(job.status.name === 'waiting for user' ||
                              job.status.name === 'processing') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     stopJob(job.id)
                                   }}
                                >
                                  Pause Job
                                </a>
                              </li>
                            )}
                            {(job.status.name === 'pending' ||
                              job.status.name === 'failed' ||
                              job.status.name === 'draft' ||
                              job.status.name === 'cancelled' ||
                              job.status.name === 'stopped by user' ||
                              job.status.name === 'completed') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     archiveJob(job.id)
                                   }}
                                >
                                  Archive Job
                                </a>
                              </li>
                            )}
                            {(job.status.name === 'archived') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     unarchiveJob(job.id)
                                   }}
                                >
                                  Unarchive Job
                                </a>
                              </li>
                            )}
                            {(job.status.name === 'pending' ||
                              job.status.name === 'processing' ||
                              job.status.name === 'waiting for user' ||
                              job.status.name === 'draft') && (
                              <li className="menu-item px-3 cursor-pointer">
                                <a className="dropdown-item"
                                   data-kt-users-table-filter="delete_row"
                                   onClick={() => {
                                     cancelJob(job.id)
                                   }}
                                >
                                  Cancel Job
                                </a>
                              </li>
                            )}
                            {/* end::Menu item */}
                          </ul>
                        </div>
                      </td>
                  </tr>

                  {expandedRows.includes(index) && (
                    <tr>
                      <td colSpan={8}>
                        {job['cost_breakdown'] !== null && (
                          <div className="table-responsive">
                            {/* begin::Table */}
                            <table className="table align-middle gs-0 gy-4">
                              {/* begin::Table head */}
                              <thead>
                              <tr className="fw-bold text-muted bg-light">
                                <th className="ps-4 min-w-300px rounded-start">Total Cost</th>
                                <th className="ps-6 min-w-125px">Total Tokens</th>
                                <th className="min-w-400px">Log Result</th>
                                <th className="min-w-200px text-end rounded-end"></th>
                              </tr>
                              </thead>
                              {/* end::Table head */}
                              {/* begin::Table body */}
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className='d-flex align-items-center'>
                                        <div className='symbol symbol-50px me-5'>
                                          <span className='symbol-label bg-light-success'>
                                            <KTIcon
                                              iconName='dollar'
                                              className='fs-2x text-success'
                                            />
                                          </span>
                                        </div>
                                        <div className='d-flex justify-content-start flex-column'>
                                          <a
                                            href='#'
                                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                          >
                                            {job['cost_breakdown']['total_cost']
                                              ? '$' +
                                                (
                                                  Math.round(
                                                    Number(job['cost_breakdown']['total_cost']) * 100
                                                  ) / 100
                                                ).toFixed(2)
                                              : 'N/A'}
                                          </a>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            Cost Breakdown
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td className={'ps-6'}>
                                      <a
                                        href='#'
                                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                      >
                                        {job['cost_breakdown']['total_tokens'] !== 0
                                          ? job['cost_breakdown']['total_tokens']
                                          : '0'}
                                      </a>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        All Tokens Used
                                      </span>
                                    </td>
                                    <td className={'mw-400px'}>
                                      <a
                                        href='#'
                                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                      >
                                        {job['log'] ? job['log'] : 'N/A'}
                                      </a>
                                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                        Result Log
                                      </span>
                                    </td>
                                    <td className='text-end'></td>
                                  </tr>
                                </tbody>
                                {/* end::Table body */}
                              </table>
                              {/* end::Table */}
                            </div>
                          )}

                          {job['cost_breakdown'] &&
                            job['cost_breakdown']['tasks']  &&
                            job['cost_breakdown']['tasks'].length > 0 && (
                              <div className='table-responsive'>
                                {/* begin::Table */}
                                <table className='table align-middle gs-0 gy-4'>
                                  {/* begin::Table head */}
                                  <thead>
                                    <tr className='fw-bold text-muted bg-light'>
                                      <th className='ps-4 min-w-325px rounded-start'>Task</th>
                                      <th className='min-w-125px'>Tokens</th>
                                      <th className='min-w-125px'>Cost</th>
                                      <th className='min-w-200px'>Model</th>
                                      <th className='min-w-150px'>Duration</th>
                                      <th className='min-w-200px text-end rounded-end'></th>
                                    </tr>
                                  </thead>
                                  {/* end::Table head */}
                                  {/* begin::Table body */}
                                  <tbody>
                                    {job['cost_breakdown']['tasks'].map((task, index) => (
                                      <tr key={index}>
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            <div className='symbol symbol-50px me-5'>
                                              {task['status'] === 'pending' && (
                                                <span className='symbol-label bg-light-primary'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-primary'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] === 'processing' && (
                                                <span className='symbol-label bg-light-primary'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-primary'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] === 'completed' && (
                                                <span className='symbol-label bg-light-success'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-success'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] === 'failed' && (
                                                <span className='symbol-label bg-light-danger'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-danger'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] === 'archived' && (
                                                <span className='symbol-label bg-light-danger'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-danger'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] === 'cancelled' && (
                                                <span className='symbol-label bg-light-danger'>
                                                  <KTIcon
                                                    iconName='abstract-26'
                                                    className='fs-2x text-danger'
                                                  />
                                                </span>
                                              )}
                                              {task['status'] !== 'completed' &&
                                                task['status'] !== 'failed' &&
                                                task['status'] !== 'pending' &&
                                                task['status'] !== 'processing' && (
                                                  <span className='symbol-label bg-light-success'>
                                                    <KTIcon
                                                      iconName='abstract-26'
                                                      className='fs-2x text-success'
                                                    />
                                                  </span>
                                                )}
                                            </div>
                                            <div className='d-flex justify-content-start flex-column'>
                                              <a
                                                href='#'
                                                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                              >
                                                {task['name']}
                                              </a>
                                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                {task['status'] !== 'completed' &&
                                                  task['status'] !== 'failed' &&
                                                  task['status'] !== 'processing' &&
                                                  'completed'}
                                                {(task['status'] === 'completed' ||
                                                  task['status'] === 'failed' ||
                                                  task['status'] !== 'processing') &&
                                                  task['status']}
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <a
                                            href='#'
                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                          >
                                            {task['tokens'] ? task['tokens'] : 'N/A'}
                                          </a>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            Tokens Used
                                          </span>
                                        </td>
                                        <td>
                                          <a
                                            href='#'
                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                          >
                                            {task['cost']
                                              ? '$' +
                                                (
                                                  Math.round(Number(task['cost']) * 100) / 100
                                                ).toFixed(2)
                                              : 'N/A'}
                                          </a>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            Cost of Tokens
                                          </span>
                                        </td>
                                        <td>
                                          <a
                                            href='#'
                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                          >
                                            {task['model'] ? task['model'] : 'N/A'}
                                          </a>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                            AI Model Used
                                          </span>
                                        </td>
                                        <td>
                                          <a
                                            href='#'
                                            className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                                          >
                                            {task['duration']}s
                                          </a>
                                          <span className='text-muted fw-semibold text-muted d-block fs-7 mt-1'>
                                            Time Spent
                                          </span>
                                        </td>
                                        <td className='text-end'></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                  {/* end::Table body */}
                                </table>
                                {/* end::Table */}
                              </div>
                            )}

                          {job['saved_documents'] !== null && job['saved_documents'].length > 0 && (
                            <div className='table-responsive'>
                              {/* begin::Table */}
                              <table className='table align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                  <tr className='fw-bold text-muted bg-light'>
                                    <th className='ps-4 min-w-300px rounded-start'>
                                      Output Documents
                                    </th>
                                    <th className='ps-4 min-w-300px'>
                                      Metadata
                                    </th>
                                    <th className='min-w-200px text-end'></th>
                                    <th className='min-w-200px text-end rounded-end'></th>
                                  </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                  {job['saved_documents'].map((document, index) => (
                                    <tr key={index}>
                                      <td>
                                        <div
                                          className='d-flex align-items-center'
                                          onClick={() => {
                                            openDocument(
                                              document['id'],
                                              document['name'],
                                              job['company_id']
                                            )
                                          }}
                                        >
                                          <div className='symbol symbol-50px me-5'>
                                            <span className='symbol-label bg-light-primary'>
                                              <KTIcon
                                                iconName='file'
                                                className='fs-2x text-primary'
                                              />
                                            </span>
                                          </div>
                                          <div className='d-flex justify-content-start flex-column'>
                                            <a
                                              href='#'
                                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                            >
                                              {document['name']}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                              Saved Document
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className='d-flex align-items-center'
                                          onClick={() => {
                                            openDocument(
                                              document['id'],
                                              document['name'],
                                              job['company_id']
                                            )
                                          }}
                                        >
                                          <div className='d-flex justify-content-start flex-column ms-2'>
                                            <a
                                              href='#'
                                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                            >
                                              {(document['metadata'] && document['metadata']['company_name']) ? document['metadata']['company_name'] : "N/A"}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                              Company Name
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div
                                          className='d-flex align-items-center'
                                          onClick={() => {
                                            openDocument(
                                              document['id'],
                                              document['name'],
                                              job['company_id']
                                            )
                                          }}
                                        >
                                          <div className='d-flex justify-content-start flex-column ms-2'>
                                            <a
                                              href='#'
                                              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                                            >
                                              {(document['metadata'] && document['metadata']['agent_persona']) ? document['metadata']['agent_persona'] : "N/A"}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                              Agent Persona
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <a
                                          href='#'
                                          className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                          onClick={() => {
                                            openDocument(
                                              document['id'],
                                              document['name'],
                                              job['company_id']
                                            )
                                          }}
                                        >
                                          View
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                {/* end::Table body */}
                              </table>
                              {/* end::Table */}
                            </div>
                          )}

                          {job['cost_breakdown'] === null && job['saved_documents'] === null && (
                            <div className='table-responsive'>
                              {/* begin::Table */}
                              <table className='table align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                  <tr className='fw-bold text-muted bg-light'>
                                    {job['log'] === null && (
                                      <th className='ps-4 min-w-300px rounded-start'>
                                        No Data Available
                                      </th>
                                    )}
                                    {job['log'] !== null && (
                                      <th className='ps-4 min-w-300px rounded-start'>{job['log']}</th>
                                    )}
                                  </tr>
                                </thead>
                                {/* end::Table head */}
                              </table>
                              {/* end::Table */}
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}

                {!jobArray ||
                  (jobArray.length === 0 && (
                    <tr className='w-100 text-center text-muted text-dark fw-bold text-hover-primary d-block fs-6 pt-2'>
                      <td colSpan={6}>No matching records found</td>
                    </tr>
                  ))}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}

          {pagination && pagination.total > 0 && jobArray && jobArray.length > 0 && (
            <TablePagination pagination={pagination} setPagination={setPagination} />
          )}
          {isLoading && <TableLoading />}
        </div>
        {/* begin::Body */}
      </div>

      {showCancelPopup && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Cancel Job</h5>
                <button type="button" className="close" onClick={() => setShowCancelPopup(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="cancelNotes">Notes</label>
                  <textarea
                    className="form-control"
                    id="cancelNotes"
                    rows={3}
                    value={cancelNotes}
                    onChange={(e) => setCancelNotes(e.target.value)}
                    placeholder="Explain to the Client why their report is being cancelled."
                  ></textarea>
                  <small className="form-text text-muted italic">
                    *Explain to the Client why their report is being cancelled.
                  </small>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowCancelPopup(false)}>Close</button>
                <button type="button" className="btn btn-primary" onClick={confirmCancelJob}>Confirm Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {TableQueueView}
