/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import IPConfig from '../../store/IPConfig'
import {useNavigate} from 'react-router-dom'

const breadcrumbs: Array<PageLink> = [
  {
    title: 'Permissions',
    path: '/permissions',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PermissionsForm: FC<{currentPermission: any}> = ({currentPermission}) => {
  const [permissionNameValue, setPermissionNameValue] = useState(currentPermission.permission || '')
  const [failMessage, setFailMessage] = useState('')

  const navigate = useNavigate()
  const apiUrl = IPConfig()

  const updatePermission = (permissionId, permissionName) => {
    if (permissionName) {
      const formData = new FormData()
      formData.append('id', permissionId)
      formData.append('permission', permissionName)

      fetch(`${apiUrl}/update-permission`, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error(error)
        })

      navigate('/permissions')
    } else {
        setFailMessage('Name is required.')
    }
  }

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Permissions</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Update current permission</span>
              </h3>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body py-3'>
              <form className='row g-3'>
                <div className='col-md-6'>
                  <label className='required form-label' htmlFor='permissionName'>
                    Permission Name
                  </label>
                  <input
                    type='text'
                    name='name'
                    className='form-control'
                    id='permissionName'
                    placeholder='Enter permission name'
                    value={permissionNameValue}
                    onChange={(e) => setPermissionNameValue(e.target.value)}
                  />
                    {failMessage && <p className="form-field-feedback fail pt-4">{failMessage}</p>}
                </div>

                <div className='col-12'>
                  <button
                    type='submit'
                    className='btn btn-primary'
                    onClick={() => {
                      updatePermission(currentPermission.id, permissionNameValue)
                    }}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
            {/* end::Body */}
          </div>
        </div>
      </div>
      {/* end::Row */}
    </>
  )
}

const PermissionEdit: FC = () => {
  const intl = useIntl()
  const [currentPermission, setCurrentPermission] = useState<any>(null)

  useEffect(() => {
    const storedPermission: any = localStorage.getItem('currentPermission')
    if (storedPermission) {
      setCurrentPermission(JSON.parse(storedPermission))
    }
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Update Permission</PageTitle>
      {currentPermission && <PermissionsForm currentPermission={currentPermission} />}
    </>
  )
}

export {PermissionEdit}
