/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components';
import IPConfig from '../../../store/IPConfig';

const initialValues = {
  name: '',
  email: '',
  typeStartup: false,
  typeInvestor: false,
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  typeStartup: Yup.bool().required('You must select a type'),
  typeInvestor: Yup.bool().required('You must select a type'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function ComingSoon() {
  const apiUrl = IPConfig('metis')
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        addWaitlist(values);
        setStatus('You have successfully registered for early access');
        setSubmitting(false);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setStatus('The registration details are incorrect');
        setSubmitting(false);
        setLoading(false);
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const addWaitlist = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);

    if (values.typeStartup) formData.append("type", "startup");
    if (values.typeInvestor) formData.append("type", "investor");

    fetch(`${apiUrl}/add-to-waiting-list`, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(response => {
        if (response.status === "success") {
          console.log('Success:', response);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Join the list for early access</h1>
        {/* end::Title */}

        <div className='fw-semibold text-dark fs-6'>Fill in your details below to subscribe for access</div>
      </div>
      {/* end::Heading */}

      {formik.status && formik.status !== "You have successfully registered for early access" && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {formik.status && formik.status === "You have successfully registered for early access" && (
        <div className='mb-lg-15 alert alert-success'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Name</label>
        <input
          placeholder='John Doe'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-solid',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='johndoe@gmail.com'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className="form-check form-check-inline text-dark" htmlFor="kt_login_toc_agree_investor">
          <input
            className="form-check-input form-check-solid"
            type="radio"
            id="kt_login_toc_agree_investor"
            {...formik.getFieldProps('typeInvestor')}
            checked={formik.values.typeInvestor}
            onChange={() => {
              formik.setFieldValue('typeInvestor', true)
              formik.setFieldValue('typeStartup', false)
            }}
          />
          <span>
            Startup Investor <span className="ps-2">or</span>
          </span>
        </label>
        {formik.touched.typeInvestor && formik.errors.typeInvestor && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.typeInvestor}</span>
            </div>
          </div>
        )}

        <label className="form-check form-check-inline text-dark" htmlFor="kt_login_toc_agree_startup">
          <input
            className="form-check-input form-check-solid"
            type="radio"
            id="kt_login_toc_agree_startup"
            {...formik.getFieldProps('typeStartup')}
            checked={formik.values.typeStartup}
            onChange={() => {
              formik.setFieldValue('typeStartup', true)
              formik.setFieldValue('typeInvestor', false)
            }}
          />
          <span>
            Startup
          </span>
        </label>
        {formik.touched.typeStartup && formik.errors.typeStartup && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.typeStartup}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline text-dark' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input form-check-solid'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I agree to the{' '}
            <a
              href='/terms-and-conditions'
              target='_blank'
              className='ms-1 text-dark fw-bold'
            >
              terms and conditions
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary alt-bg-night w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
