import axios from 'axios'
import {AuthModel, UserModel} from './_models'
import {AUTH_LOCAL_STORAGE_KEY, getAuth} from "./AuthHelpers";
import IPConfig from '../../../store/IPConfig'

const API_URL = process.env.REACT_APP_API_URL_DMS

export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/send-reset-password`
export const RESET_PASSWORD_URL = `${API_URL}/reset-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    useremail: email,
    password: password,
  }, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  });
}

// Server should return AuthModel
export function register(
  email: string,
  username: string,
  password: string
) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email: email,
    username: username,
    password: password,
  }, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  }, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  })
}

// Server should return AuthModel
export function resetPassword(
  token: string,
  password: string,
) {
  return axios.post<any>(RESET_PASSWORD_URL, {
    token: token,
    new_password: password,
  }, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  })
}

export function getUserByToken(token: string) {
  if (token === getAuth()?.data.token) {
    return getUserModel(getAuth())
  }
}

export async function getUpdatedUser() {
  const apiUrl = IPConfig();
  const userRecord = getUserModel(getAuth());

  if (userRecord?.email) {
    try {
      const response = await fetch(`${apiUrl}/get-all-users?email=${userRecord.email}`);
      const data = await response.json();
      if (data.data && data.data.length > 0) {
        return data.data[0];
      }
    } catch (err) {
      console.log("Unable to fetch -", err);
      throw err;
    }
  }

  return null;
}

export function refreshUser() {
  const fetchUser = async () => {
    try {
      const userData = await getUpdatedUser();
      window.localStorage.setItem('userUpdated', JSON.stringify(userData));
    } catch (err) {
      console.error("Error fetching user data:", err);
    }
  };

  fetchUser();
}

export function getCurrentSite(): string {
  let currentSite = localStorage.getItem('currentSite');
  if (!currentSite) {
    localStorage.setItem('currentSite', 'io');
    currentSite = 'io';
  }
  return currentSite;
}

export function getUserModel(authModel: AuthModel | undefined) {
  if (!authModel) {
    return;
  }

  let userModel: UserModel = {
    id: authModel.data.id,
    username: authModel.data.username,
    password: authModel.data.password,
    email: authModel.data.email,
    first_name: authModel.data.username,
    last_name: "",
    fullname: authModel.data.username,
    language: "en",
    auth: authModel,
  }

  return userModel;
}

