export interface ICompanyDetails {
  id: string
  companyName: string
  location: string
  industrySector: string
  foundingDate: string
  registrationNumber: string
}

export const companyDetailsInitValues: ICompanyDetails = {
  id: '',
  companyName: '',
  location: '',
  industrySector: '',
  foundingDate: '',
  registrationNumber: ''
}
