/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import IPConfig from '../../../../../app/store/IPConfig'
import {
  getUpdatedUser,
  getUserByToken,
} from '../../../../../app/modules/auth/core/_requests'

const SidebarMenuMain = () => {
  const apiUrl = IPConfig()
  const intl = useIntl()
  const [taskCount, setTaskCount]: any = useState(0)
  const [permissionArray, setPermissionArray]: any = useState<[]>([])
  const [user, setUser] = useState<any>(null)

  useEffect(() => {
    const token: any = localStorage.getItem('usertoken')
    const userAuthed: any = getUserByToken(token)
    setUser(userAuthed.auth.data)

    const fetchUser = async () => {
      try {
        const userData = await getUpdatedUser()
        setUser(userData)
      } catch (err) {
        console.error('Error fetching user data:', err)
      }
    }

    fetchUser()
  }, [])

  useEffect(() => {
    try {
      if (user && user.role) {
        setPermissionArray([])
        fetchRole()
        fetchTasks()
      }

      // Fetch tasks every 10 seconds
      const intervalId = setInterval(fetchTasks, 10000)
      return () => clearInterval(intervalId)
    } catch (e) {
      console.error(e)
    }
  }, [user])

  const fetchRole = () => {
    fetch(`${apiUrl}/get-role?id=${user.role.id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.data.permission) {
          setPermissionArray(data.data.permission)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  const fetchTasks = () => {
    fetch(`${apiUrl}/get-user-task?all=true&user_role=${user.role.id}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.data) {
          let count = 0
          for (let i = 0; i < response.data.length; i++) {
            let task = response.data[i]
            let status = task.status.name
            if (task.user_role) {
              if (user.role.name === task.user_role.name) {
                if (status === 'pending') {
                  count += 1
                }
              }
            }
          }
          setTaskCount(count)
        }
      })
      .catch(function (err) {
        console.log('Unable to fetch -', err)
      })
  }

  return (
    <>
      {/*{permissionArray.includes('view-companies') && (*/}
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/companies'
        icon='office-bag'
        title={intl.formatMessage({id: 'MENU.COMPANIES'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/tasks'
        icon='archive-tick'
        title={intl.formatMessage({id: 'MENU.TASKS'})}
        fontIcon='bi-list-task'
        badge={{color: 'danger', value: taskCount}}
      />

      {permissionArray.includes('view-documents') && (
        <SidebarMenuItem
          to='/documents'
          icon='document'
          title={intl.formatMessage({id: 'MENU.DOCUMENTS'})}
          fontIcon='bi-layers'
        />
      )}
      {/*)}*/}

      {/* ACCOUNTS */}
      {(permissionArray.includes('view-users') ||
        permissionArray.includes('view-documents') ||
        permissionArray.includes('view-permissions') ||
        permissionArray.includes('view-roles')) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
              {intl.formatMessage({id: 'MENU.MANAGEMENT'})}
            </span>
          </div>
        </div>
      )}

      {permissionArray.includes('view-users') && (
        <SidebarMenuItem
          to='/apps/user-management/users'
          icon='security-user'
          title={intl.formatMessage({id: 'MENU.USERS'})}
          fontIcon='bi-layers'
        />
      )}

      {permissionArray.includes('view-permissions') && (
        <SidebarMenuItem
          to='/permissions'
          icon='shield-tick'
          title={intl.formatMessage({id: 'MENU.PERMISSIONS'})}
          fontIcon='bi-layers'
        />
      )}

      {permissionArray.includes('view-roles') && (
        <SidebarMenuItem
          to='/roles'
          icon='shield'
          title={intl.formatMessage({id: 'MENU.ROLES'})}
          fontIcon='bi-layers'
        />
      )}

      {/* SETTINGS */}
      {(permissionArray.includes('view-workflow') ||
        permissionArray.includes('view-workspaces') ||
        permissionArray.includes('view-queues') ||
        permissionArray.includes('view-agents') ||
        permissionArray.includes('view-jobs')) && (
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
              {intl.formatMessage({id: 'MENU.SETTINGS'})}
            </span>
          </div>
        </div>
      )}

      {permissionArray.includes('view-workflow') && (
        <SidebarMenuItem
          to='/workflow'
          icon='arrow-mix'
          title={intl.formatMessage({id: 'MENU.WORKFLOW'})}
          fontIcon='bi-layers'
        />
      )}

      {permissionArray.includes('view-workspaces') && (
        <SidebarMenuItem
          to='/workspaces'
          icon='bookmark'
          title={intl.formatMessage({id: 'MENU.WORKSPACES'})}
          fontIcon='bi-layers'
        />
      )}

      {permissionArray.includes('view-queues') && (
        <SidebarMenuItem
          to='/queues'
          icon='setting'
          title={intl.formatMessage({id: 'MENU.QUEUES'})}
          fontIcon='bi-layers'
        />
      )}

      {permissionArray.includes('view-agents') && (
        <SidebarMenuItem
          to='/agents'
          icon='user-tick'
          title={intl.formatMessage({id: 'MENU.AGENTS'})}
          fontIcon='bi-layers'
        />
      )}

      {/*{permissionArray.includes('view-jobs') && (
          <SidebarMenuItem
              to='/jobs'
              icon='note'
              title={intl.formatMessage({id: 'MENU.JOBS'})}
              fontIcon='bi-layers'
          />
      )}*/}
    </>
  )
}

export {SidebarMenuMain}
