import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { HeaderNotificationsMenu, HeaderUserMenu, ThemeModeSwitcher } from '../../../partials';
import { useLayout } from '../../core';
import IPConfig from '../../../../app/store/IPConfig';
import {getUpdatedUser, getUserByToken} from '../../../../app/modules/auth/core/_requests';

const itemClass = 'ms-1 ms-md-4';
const btnClass = 'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px';
const userAvatarClass = 'symbol-35px';
const btnIconClass = 'fs-2';

const Navbar = () => {
  const wsUrl = IPConfig('ws');
  const { config } = useLayout();
  const [jobArray, setJobArray]: any = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const token: any = localStorage.getItem('usertoken');
    const userAuthed: any = getUserByToken(token);
    setUser(userAuthed.auth.data);

    const fetchUser = async () => {
      try {
        const userData = await getUpdatedUser();
        setUser(userData);
        window.localStorage.setItem('userUpdated', JSON.stringify(userData));
      } catch (err) {
        console.error("Error fetching user data:", err);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    // Initialize WebSocket connection
    const socket = new WebSocket(`${wsUrl}/websocket-notifications`);

    socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.data) {
        setJobArray(response.data);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }, [wsUrl]);

  useEffect(() => {
    // Check if there are new jobs and update notification count and badge
    const storedJobs = JSON.parse(window.localStorage.getItem('jobArray') || '[]');
    const newJobsCount = jobArray.filter(job => !storedJobs.some(storedJob => storedJob.id === job.id)).length;
    setNotificationCount(newJobsCount);
  }, [jobArray]);

  const handleNotificationClick = () => {
    // Reset notification count and hide badge upon notification button click
    setNotificationCount(0);
    // Store the latest jobArray in local storage
    window.localStorage.setItem('jobArray', JSON.stringify(jobArray));
  };

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)} onClick={handleNotificationClick}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          className={btnClass}
        >
          <KTIcon iconName="element-plus" className={btnIconClass} />
        </div>
        {notificationCount > 0 &&
          <span className="badge bg-danger text-white fs-8 notification-count">{notificationCount}</span>}
        <HeaderNotificationsMenu alerts={jobArray} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol symbol-circle', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          {user?.avatar ? (
            <img src={toAbsoluteUrl(IPConfig('public') + user.avatar)} alt={user.username}
                 className={'w-45px h-45px'} />
          ) : (
            <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="" className={'w-45px h-45px'} />
          )}
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTIcon iconName='text-align-left' className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
}

export {Navbar};
